import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
// import EcomPreview from "./E-ComPreview/index";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import index from "../ChooseDesign";

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Add commision", link: "#" },
        { title: "Add & Manage commision", link: "#" },
      ],

      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      //   this page states
      category: [],
      categoryAll: [],
      subCategory: [],
      subCategoryAll: [],
      subServiceAll: [],

      cat_id: "",
      pruduct_type: "",
      sub_cat_id: "",
      sub_srvc_id: "",
      market_price: "",
      selling_price: "",

      // selectedFiles: [],
      images: "",
      uploaded_images: [],

      sell_price: "",
      purchase_price: "",
      discount: "",
      availableSize: [],
      availableColor: [],
      hashtag: "",
      personalisation: "",
      details: "",
      price: "",
      allService: [],
      shopService: [],
      allService_id: "",
      allShopService_id: "",
      to_time: "",
      from_time: "",
      available_duration: 0,
      slot_duration_id: "",

      optionGroupSize: [{ value: "Red", code: "" }],
      selectedMultiSize: [],

      showSelectColor: false,
      showSelect: false,
      colorRgb: "#4667CC",
      simple_color1: false,
      color: [{ code: "#4667CC", active: false }],
      description: "",
      selectcategory: "",
      selectshopserviceid: "",
      edit_id: "",
      weekDay: [
        { label: "Monday", value: "Monday" },
        { label: "Tuesday", value: "Tuesday" },
        { label: "Wednesday", value: "Wednesday" },
        { label: "Thursday", value: "Thursday" },
        { label: "Friday", value: "Friday" },
        { label: "Saturday", value: "Saturday" },
        { label: "Sunday", value: "Sunday" },
      ],
      slot_duration: [
        { label: "10", value: "10" },
        { label: "15", value: "15" },
        { label: "30", value: "30" },
      ],
      language: [{ label: "en", value: "en" }],
      lang: "",
      slot_time: "",
      selectedWeek: "",
      selectWeekName: "",
      commissionValue: "",
    };
    this.handleMulti = this.handleMulti.bind(this);
    this.onDragRgb = this.onDragRgb.bind(this);
  }
  onDragRgb(index, c1) {
    // alert(c1);
    this.state.color[index].code = c1;
    this.setState({ colorRgb: c1 });
  }

  handleMulti = (selectedMultiSize) => {
    this.setState({ selectedMultiSize });
    setTimeout(() => {
      console.log("selectedMulti", selectedMultiSize);
    });
  };

  componentDidMount = async () => {
    this.fetchService();
  };

  fetchService = async () => {
    let result = await HttpClient.requestData("service-category", "GET");
    if (result && result.status > 0) {
      this.setState({ allService: result.data });
    }
    console.log("allService", result);
  };

  saveCommision = async (serviceid, percent, servicePrice) => {
    console.log(serviceid, percent, servicePrice);
    if (servicePrice === percent) {
      this.setState({
        alert: true,
        message: "Commission must be less than service price",
        type: "warning",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
    } else {
      let data = {
        service_id: serviceid,
        category_id: this.state.selectcategory,
        percentage: parseInt(percent),
      };
      console.log("data", data);
      let result = await HttpClient.requestData(
        "service-commision",
        "POST",
        data
      );
      if (result && result.status) {
        this.setState({
          alert: true,
          message: " Commission Updated Successfully",

          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        // document.getElementById(`textfield1_${index}`).value = "";
        this.fetchData(this.state.selectcategory);
      }
      console.log("allService", result);
    }
  };

  fetchData = async (id) => {
    console.log("id", id);
    let userData = reactLocalStorage.getObject("userData");
    console.log(userData._id);
    let result = await HttpClient.requestData(
      "service-category/shop-services/" + id,
      "GET"
    );
    console.log("data service", result.data);
    if (result && result.status) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        //this.state.comission = element.admin_commission_data.percentage;
        //this.setState({});

        // let i = element.image.findIndex(
        //     (element) =>
        //         element.ismain == true
        // );

        console.log(i);
        let rows = {
          sl: i,
          cat_name: element.category_name,
          serv_name: element.name,

          price: element.price,
          img: element.image ? (
            <div>
              <img
                src={HttpClient.IMG_URL + element.image[0].preview}
                alt="images"
                className="rounded avatar-md card-img"
              />
            </div>
          ) : null,

          // img: element.image ? (
          //     <div>
          //         {i > -1 ?
          //             <img
          //                 src={HttpClient.IMG_URL + element.image[i].preview}
          //                 alt="images"
          //                 className="rounded avatar-md card-img"
          //             /> :
          //             <img
          //                 src={HttpClient.IMG_URL + element.image[0].preview}
          //                 alt="images"
          //                 className="rounded avatar-md card-img"
          //             />}

          //     </div>
          // ) : null,
          text: element.admin_commission_data.percentage,
          text1: (
            <input
              type="number"
              id={"textfield1_"+index}
              /* value={this.state.result[index].admin_commission_data.percentage} */
            //   value={this.state.commissionValue}
              onChange={(val) => {
                console.log("vc", val.target.value);
                this.state.result[index].admin_commission_data.percentage =
                  parseInt(val.target.value);
                this.setState({
                  commissionValue: val.target.value,
                });
              }}
            />
          ),

          status: (
            <button
              className={"btn btn-success mr-2"}
              onClick={() => {
                this.saveCommision(
                  element._id,
                  this.state.result[index].admin_commission_data.percentage,
                  element.price
                );
              }}
            >
              Save
            </button>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  edit = async (item, index) => {
    console.log("edititems", item);
    this.setState({
      slot_time: item.slot_duration,
      selectWeekName: item.day_name,
      from_time: item.from,
      to_time: item.to,
      available_duration: item.available_duration,
      allShopService_id: item.shop_service_id,
      allService_id: item.category_id,
      edit_enable: true,
      edit_id: item._id,
      lang: item.language,
      // slot_time:
    });
  };

  handleCat = async (event) => {
    this.state.selectcategory = event.target.value;
    this.setState({});
    this.fetchData(event.target.value);
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Category Name",
          field: "cat_name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Service Name",
          field: "serv_name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Price",
          field: "price",
          sort: "asc",
          width: 270,
        },
        {
          label: "Image",
          field: "img",
          sort: "asc",
          width: 270,
        },

        // {
        //   label: "Available Duration",
        //   field: "available_duration",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "Commission Amout(Rs.)",
          field: "text",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Category",
        //   field: "category_id",
        //   sort: "asc",
        //   width: 270,
        // },
        // {
        //   label: "Status",
        //   field: "status",
        //   sort: "asc",
        //   width: 100,
        // },
        {
          label: "Enter Commission",
          field: "text1",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "status",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {this.state.alert ? (
              <UncontrolledAlert
                color={this.state.type}
                className="alert-dismissible fade show"
                role="alert"
              >
                {this.state.type == "warning" ? (
                  <i className="mdi mdi-alert-outline mr-2"></i>
                ) : this.state.type == "success" ? (
                  <i className="mdi mdi-check-all mr-2"></i>
                ) : this.state.type == "danger" ? (
                  <i className="mdi mdi-block-helper mr-2"></i>
                ) : null}
                {this.state.message}
              </UncontrolledAlert>
            ) : null}

            <Row>
              {/* <BaseInfo /> */}
              <Col xs={12}></Col>
              <Col xl={4}>
                {/* <div>
                    <EcomPreview />
                  </div> */}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <FormGroup row>
                    <Label
                      htmlFor="example-text-input"
                      className="col-md-2 col-form-label"
                    >
                      Category
                    </Label>
                    <Col md={10}>
                      <select
                        className="form-control"
                        onChange={this.handleCat}
                        value={this.state.selectcategory}
                      >
                        <option value="">Select Category</option>
                        {this.state.allService.map((item, index) => {
                          return (
                            <option value={item._id} key={index}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </Col>
                  </FormGroup>

                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    disableRetreatAfterSorting={true}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
