import React, { Component } from "react";
import Design_1 from "./fourDesigns/design_1";
import Design_2 from "./fourDesigns/design_2";
import Design_3 from "./fourDesigns/design_3";
import Design_4 from "./fourDesigns/design_4";

export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loop: [1, 2, 3, 4,5],
    };
  }

  render() {
      return (
        <React.Fragment>
          <div className="page-content">
            <div className="showroom">
              <div className="container-fluid">
                <div className="w-100 text-center">
                  <h4 className="text-primary font-weight-bold">
                    App showroom
                  </h4>
                  <p className="text-gray">
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown.
                  </p>
                </div>
                <div className="list-group row flex-row">
                  {this.state.loop.map(() => {
                    return (
                      <div className="col-12 col-md-4 col-lg-4" key={index}>
                        <div className="list-group-item border-0 mb-4 shadow-br p-1">
                          <div className="w-100 media align-items-lg-center flex-column p-0">
                            <Design_1 />
                            <div className="media-body">
                              <h5 className="mt-3 font-weight-bold mb-1">
                                App name placeholder
                              </h5>

                              <div className="d-flex align-items-center justify-content-between mt-1 flx-drc">
                                <div className="list-inline small mt-2">
                                  <mark className="font-italic text-muted mb-0">
                                    Owner name
                                  </mark>
                                </div>
                                <a className="btn btn-primary view_btn" href>
                                  View App
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="d-flex">
                  <nav aria-label="Page navigation" className="mx-auto pt-5">
                    <ul className="pagination pagination-filled">
                      <li className="page-item">
                        <a className="page-link" href="#" aria-label="Previous">
                          <i
                            className="fa fa-angle-double-left"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Previous</span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          4
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          5
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#" aria-label="Next">
                          <i
                            className="fa fa-angle-double-right"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Next</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
  }
}
