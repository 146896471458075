import React, { useState } from "react";
import "./faq.css";

const Faq = () => {
  const [faqs, setfaqs] = useState([
    {
      question: "Lorem ipsum dolor sit amet?",
      answer:
        "TLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit amet. TLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit amet.",
      open: true,
    },
    {
      question: "How many programmers does it take to screw in a lightbulb?",
      answer: "None. We don't address hardware issues.",
      open: false,
    },
    {
      question: "Who is the most awesome person?",
      answer: "You. The Viewer.",
      open: false,
    },
    {
      question:
        "How many questions does it take to make a successful FAQ Page?",
      answer: "This many.",
      open: false,
    },
    {
      question: "Lorem ipsum dolor sit amet?",
      answer:
        "TLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit amet.TLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit amet.",
      open: false,
    },
    {
      question: "Lorem ipsum dolor sit amet?",
      answer:
        "TLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit amet.",
      open: false,
    },
  ]);
  const toggleFAQ = (index) => {
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Faq</h4>
                <div className="page-title-right">
                  <nav className aria-label="breadcrumb">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="/faq">One&amp;Done Plus</a>
                      </li>
                      <li
                        className="active breadcrumb-item"
                        aria-current="page"
                      >
                        Faq
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            {/* <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} /> */}
            <div className="faqs">
              {faqs.map((faq, index) => (
                <div
                  className={"faq " + (faq.open ? "open" : "")}
                  key={index}
                  onClick={() => toggleFAQ(index)}
                >
                  <div className="faq-question">{faq.question}</div>
                  <div className="faq-answer">{faq.answer}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Faq;
