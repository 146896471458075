import React, { Component } from "react";
import axios from "axios";

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
// import EcomPreview from "./E-ComPreview/index";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import index from "../ChooseDesign";
// import ElementMirror from "@fullcalendar/interaction/dnd/ElementMirror";
import { CSVLink } from "react-csv";

const header = [
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Phone", key: "phone" },

  {
    label: "Feedback Detail",
    key: "feedback_detail",
  },
];

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "All Suggested Category", link: "#" },
        { title: "Show All Suggested Category", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
    };
  }

  componentDidMount = async () => {
    // this.checkUser();
    // this.fetchService();
    this.fetchData();
  };

  fetchData = async () => {
    let token = "";
    let user = reactLocalStorage.getObject("userData");
    if (user && user != null && Object.keys(user).length > 0) {
      token = user.token;
      console.log("token", token);
    }
    let result = await axios
      .get("https://api.myspiritualconnect.com/v1/admin/suggested-category", {
        headers: {
          authorization: token,
          userType: "Admin",
        },
      })
      .then((response) => {
        console.log("response",response.data.data);
        if (response.data.data.length > 0) {
          let arr = [];
          let i = 1;
          response.data.data.forEach((element) => {
            let dom = {
              sl: i,
              name: element.name,
              email: element.description,
              img: element.image ? (
                <div>
                  <img
                    src={HttpClient.IMG_URL + element.image}
                    alt="images"
                    className="rounded avatar-md card-img"
                  />
                </div>
              ) : null,
              status: (
                  <button
                    title={
                      element.admin_approved
                        ? "Approved"
                        : "Approve"
                    }
                    className={
                      element.admin_approved
                        ? "btn btn-success mr-2"
                        : "btn btn-primary mr-2"
                    }
                    // className="btn btn-success mr-2"
                    onClick={() => {
                      this.status(element, index);
                    }}
                    disabled={element.admin_approved == true ? true : false}
                  >
                    {element.admin_approved ? "Approved" : "Approve"}
                    {/* Approve */}
                  </button>
                ),
                status1: (
                  <button
                    title="Reject"
                    className={
                      element.admin_approved == false
                        ? "btn btn-info mr-2"
                        : "btn btn-danger mr-2"
                    }
                    // className="btn btn-danger mr-2"
                    onClick={() => {
                      this.status(element, index);
                    }}
                    disabled={element.admin_approved == true ? false : true}
                  >
                    {element.admin_approved == false ? "Rejected" : "Reject"}
                    {/* Reject */}
                  </button>
                ),
            };
            i++;
            console.log(dom);
            arr.push(dom);
          });

          this.setState({
            result: arr,
          });
          console.log("result", this.state.result);
        }
      });
  };

  status = async (item, index) => {
    let sendData = {
      approve: !item.admin_approved,
    };
    let result = await HttpClient.requestData(
      "suggested-category/" + item._id,
      "PUT",
      sendData
    );
    if (result && result.status) {
      // this.state.result.splice(index, 1);
      // this.setState({
      //   alert: true,
      //   message: "Status Updated Successfully",
      //   type: "success",
      // });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Category",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Description",
          field: "email",
          sort: "asc",
          width: 270,
        },
        {
          label: "Image",
          field: "img",
          sort: "asc",
          width: 270,
        },

        {
          label: "Approve",
          field: "status",
          sort: "asc",
          width: 270,
        },
        {
          label: "Reject",
          field: "status1",
          sort: "asc",
          width: 270,
        },
      ],
      rows: this.state.result,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xl={12}>
                {/* {this.state.result.length > 0 ? (
                  <CSVLink
                    data={this.state.result}
                    headers={header}
                    filename={"FeedbackList.csv"}
                    className="btn btn-success"
                  >
                    Export Report in CSV
                  </CSVLink>
                ) : null} */}
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;