import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import ReactJsAlert from "reactjs-alert";
// import { Row, Col, Card, CardBody, Button } from "reactstrap";

import {
  Container,
  Row,
  Col,
  Tooltip,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Input,
  Form,
  Button,
  FormGroup,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import BaseInfo from "../ChooseDesign/baseInfo";

export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1,
      tooltipOpen: false,
      result: [],
      data: [],
      monthDate: new Date(),
      modal_static: false,
      cardLoop: [1, 2, 3, 4],

      quizName: "",
      startDate: new Date(),
      endDate: new Date(),
      userId: "",
      userDetails: {},
      giftCard: [],
      giftCardSelected: [],
      //   img: {},
      imgUrl: "",
      refreshStatus: false,
      // know your customer
      previewKnow: false,
      previewData: {},
      resultKnow: [],
      // dataKnow: [],
      breadcrumbItems: [
        { title: "Update Hobby ", link: "#" },
        { title: "Add & Manage Hobby", link: "#" },
      ],
      alert: false,
      message: "",
      type: "",
      status: false,
      title: "",
      QuestionOptions: [],
      selectedOptionValue: "",
      hobby_category: "",
      hobby_desc: "",
      categoryphoto: {},
      category: "",
      update_id: this.props.match.params.id,

      planprice: "",

      //   img:""
    };
    // this.toggleTab = this.toggleTab.bind(this);
    // this.handleMonthChange = this.handleMonthChange.bind(this);
    // this.tog_CardModal = this.tog_CardModal.bind(this);
  }

  componentDidMount = async () => {
    this.fetchUpdateData();
  };

  fetchUpdateData = async () => {
    let result = await HttpClient.requestData("viewhobby", "GET");
    // console.log("HOBBIES",this.state.update_id);
    if (result && result.status) {
      let filter = result.data.filter(
        (item) => item.id == this.state.update_id
      );

      this.setState({
        hobby_desc: filter[0].hobby_desc,
        hobby_category: filter[0].hobby_category,
      });
      //   console.log("filter", filter);
    } else {
    }
  };

  handleUpdate = async () => {
    let sendData = {
      hobby_desc: this.state.hobby_desc,
      hobby_category: this.state.hobby_category,
    };
    console.log("sendData", sendData);
    let result = await HttpClient.requestData("updatehobby", "POST", sendData);
    console.log("result", result);
    if (result && result.status) {
      this.setState({
        type: "success",
        status: true,
        title: "Updated Successfully",
      });
    } else {
      this.setState({
        type: "error",
        status: true,
        title: "Something Went Wrong",
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <ReactJsAlert
            type={this.state.type}
            title={this.state.title}
            status={this.state.status}
            Close={() => this.setState({ status: false })}
          />
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl={12}>
                <div>
                  <div id="basic-pills-wizard" className="twitter-bs-wizard">
                    <Form className="card p-3">
                      <h4></h4>
                      <Row>
                        <>
                          <Col lg="12">
                            <FormGroup row>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Hobby Category
                              </Label>
                              <Col md={10}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.hobby_category}
                                  id="example-text-input"
                                  readOnly
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Description
                              </Label>
                              <Col md={10}>
                                <textarea
                                  id="question-input1"
                                  className="form-control"
                                  rows="2"
                                  placeholder="Write small description..."
                                  value={this.state.hobby_desc}
                                  onChange={(val) => {
                                    // this.state.description = val.target.value;
                                    this.setState({
                                      hobby_desc: val.target.value,
                                    });
                                  }}
                                ></textarea>
                              </Col>
                            </FormGroup>
                          </Col>
                        </>
                        <Col lg="6">
                          <FormGroup className="mb-0">
                            <div className="button-items pt-4">
                              <Button
                                color="primary"
                                type="button"
                                className="waves-effect waves-light mr-1"
                                onClick={() => {
                                  this.handleUpdate();
                                }}
                              >
                                Update{" "}
                                <i className="ri-arrow-right-line align-middle ml-1"></i>
                              </Button>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                    <div className="card px-3 pt-0"></div>
                  </div>
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {this.state.activeTab == 1 ? (
                    <MDBDataTable responsive bordered data={data} />
                  ) : (
                    {
                       <MDBDataTable responsive bordered data={dataKnow} /> 
                    }
                  )}
                </div>
              </Col>
            </Row> */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
