import React, { useState, useEffect } from "react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";
import moment from "moment";
import Dumy from "./../../assets/images/dumy.jpg";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { async } from "@firebase/util";
import { useParams } from "react-router-dom";

//import ReactJsAlert from "reactjs-alert";

function SellerDetails(props) {


    console.log("id", props.data._id);
    const [sellerDetails, setSellerDetails] = useState([])


    useEffect(() => {
        console.log("props.data", props.data);
        fetchData();
    }, []);

    const fetchData = async () => {
        console.log(props.data.order_id);
        let result = await HttpClient.requestData("servicePerorder/" + props.data.order_id, "GET");
        console.log("resluttttt", result);
        if (result && result.status) {
            setSellerDetails(result.data);
        }
    }

    const onCloseMethode = async () => {
        props.onCloseBack(false);
    };

    console.log("sellerDetails.data.provider_data.firstName", sellerDetails);
    // const { selectedOption } = this.state;
    return (
        <div
            className={props.status ? "modal show fade" : "modal fade"}
            id="add-new-task-modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="add-new-task-modalLabel"
            aria-hidden="true"
            style={{ display: props.status ? "block" : "none" }}
        >
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="add-new-task-modalLabel">
                            Service Details
                        </h4>




                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                            onClick={() => {
                                onCloseMethode();
                            }}
                        >
                            ×
                        </button>
                    </div>
                    <div
                        className="modal-body"
                        style={{ height: "360px", overflowY: "auto" }}
                    >
                        {/* <h4>{sellerDetails.provider_data ? sellerDetails.provider_data.firstName : ""}</h4> */}
                        <div className="px-2"></div>

                        {/* <div className="col-md-6">
                            <label htmlFor="task-title">Seller Name</label>
                            {item.provider_data.firstName + " " + item.provider_data.lastName}
                        </div> */}


                        {sellerDetails.map((item, index) => {
                            return (
                                <>
                                    <div className="d-md-flex  my-2  ">
                                        <div className="order_pic ">
                                            <label htmlFor="task-title">Seller Name</label>
                                            <h4>{item.provider_data.firstName + " " + item.provider_data.lastName}</h4>
                                        </div>

                                    </div>
                                    <div className="d-md-flex  my-2">
                                        <div className="order_pic ">
                                            <label htmlFor="task-title">Email</label>
                                            <h4>{item.provider_data.email}</h4>
                                        </div>
                                    </div>
                                </>
                            );
                        })}



                        <div className="text-right">
                            <button
                                type="button"
                                className="btn btn-warning mr-3"
                                data-dismiss="modal"
                                onClick={() => {
                                    onCloseMethode();
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}
export default SellerDetails;
