import React, { Component } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Table } from "reactstrap";
import { Link } from "react-router-dom";



class TotalServiceOrderRevenew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        }
    }

    render() {
        return (
            <React.Fragment>
                <Col lg={4}>
                    <Card className="bg-danger">
                        <CardBody>
                            <div>
                                <div className="text-center">
                                    <p className="mb-2 text-white">Total Service Order Revenew</p>
                                    <h4 className='text-white'>{this.props.total_service_order_revenew}</h4>

                                </div>



                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default TotalServiceOrderRevenew;