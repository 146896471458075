import React, { useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
const CreateShop = () => {
  const [breadcrumbItems, setBreadItems] = useState([
    { title: "My Spiritual", link: "#" },
    { title: "Create Shop", link: "#" },
  ]);
  return (
    <React.Fragment>
      <div className="page-content  ">
        <div className="container-fluid">
          <Breadcrumbs title="Create Shop" breadcrumbItems={breadcrumbItems} />
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="Add_service  ">
                <div className="row mt-3">
                  <div className="col-md-12 text-center">
                    <h1>Create Shop</h1>
                  </div>
                  <div className="col-md-6 mx-auto">
                    <div className="form-group my-4">
                      <label htmlFor="">
                        Shop Name{" "}
                        <span class="required" style={{ color: "red" }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Shop Name"
                      />
                    </div>

                    <div className="form-group my-4">
                      <label htmlFor="">
                        Shop Price{" "}
                        <span class="required" style={{ color: "red" }}>
                          *
                        </span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Shop Price"
                      />
                    </div>
                    <div className="form-group my-4">
                      <label htmlFor="">
                        Shop Details{" "}
                        <span class="required" style={{ color: "red" }}>
                          *
                        </span>
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows={3}
                        placeholder="Please Add Your Shop Details"
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-6  ">
                        <div className="form-group">
                          <select
                            className="form-control"
                            id="exampleFormControlSelect1"
                          >
                            <option value="">Select Catagory</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <select
                            className="form-control"
                            id="exampleFormControlSelect1"
                          >
                            <option>Select Sub Catagory</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between w-100">
                      <div>
                        <h5>
                          Shop Image{" "}
                          <span class="required" style={{ color: "red" }}>
                            *
                          </span>
                        </h5>

                        {/* <div className="product-thumb">
                          <img
                            className="avatar ml-3 img-fluid img-thumbnail "
                            key={index}
                            style={{ width: "54px", height: "54px" }}
                            src={item.preview}
                            alt="..."
                          />
                          <button
                            type="button"
                            className="btn cross-btn"
                            
                          >
                            <i className="far fa-times-circle"></i>
                          </button>
                        </div> */}
                      </div>
                      <div className="choose_file my-4">
                        <input
                          type="file"
                          multiple
                          className="input_file"
                          //   onChange={onImageId}
                        />
                      </div>
                    </div>
                    <div className="text-center ">
                      <button
                        type="button"
                        className="btn btn-primary w-100 mb-3"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateShop;
