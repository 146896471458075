import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import uniqid from 'uniqid';
import Jitsi from 'react-jitsi'
// import JistsiMeeting from './JistsiMeeting';
import { JaaSMeeting, JitsiMeeting } from '@jitsi/react-sdk';

function Meeting() {
    const [meetingInfo, setMeetingInfo] = useState({
        displayName: "",
        roomName: "",
        password: "",
    })

    const [roomName, setRoomName] = useState("")
    const [breadcrumbItems] = useState([
        { title: "Meeting", link: "#" },
        { title: "Create Meeting", link: "#" },
    ])

    const generateduniqueid = () => {
        const id = uniqid()
        setMeetingInfo({
            ...meetingInfo,
            roomName: id
        })
        setRoomName(id)
    }

    const [meeting, setMeeting] = useState(false)

    useEffect(() => {
        console.log(meetingInfo)
    }, [meetingInfo])

    const createLink = () => {
        setMeeting(true)
        // if(roomName.trim() !=""){
        //     alert(`https://meet.jit.si/${roomName}`)

        // }
        // else{
        //     alert("Please enter a Room name")
        // }
    }
    if(meeting){
      return   <React.Fragment>
      <div className="page-content">
          <Container fluid>
              <Breadcrumbs
                  title={breadcrumbItems[0].title}
                  breadcrumbItems={breadcrumbItems} />
              <Card>
                  <CardBody>
                  <JitsiMeeting
        // domain={YOUR_DOMAIN}
        
        roomName={meetingInfo.roomName}
        configOverwrite={{
            startWithAudioMuted: true,
            disableModeratorIndicator: true,
            startScreenSharing: true,
            enableEmailInStats: false
        }}
        interfaceConfigOverwrite={{
            DISABLE_JOIN_LEAVE_NOTIFICATIONS: true
        }}
        userInfo={{
            displayName: "Admin"
        }}
        onApiReady={(externalApi) => {
            // here you can attach custom event listeners to the Jitsi Meet External API
            // you can also store it locally to execute commands
        }}
        getIFrameRef={(iframeRef) => { iframeRef.style.height = '400px'; }}
    />
                  </CardBody>
              </Card>

          </Container>
      </div>
  </React.Fragment>
      
    
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={breadcrumbItems[0].title}
                        breadcrumbItems={breadcrumbItems} />
                    <Card>
                        <CardBody>
                            <FormGroup row>
                                <Label
                                    htmlFor="example-text-input"
                                    className="col-md-2 col-form-label"
                                >
                                    Room name
                                </Label>
                                <Col md={10}>
                                    <InputGroup>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            value={meetingInfo.roomName}
                                            id="example-text-input"
                                            placeholder="Enter Room name"
                                            onChange={(e) => {
                                                setRoomName(e.target.value)
                                            }}
                                        />
                                        <InputGroupText className='generate' onClick={generateduniqueid}>
                                            Generate room id
                                        </InputGroupText>
                                    </InputGroup>


                                </Col>
                            </FormGroup>
                            {/* <FormGroup row>
                                <Label
                                    htmlFor="example-text-input"
                                    className="col-md-2 col-form-label"
                                >
                                    Display name
                                </Label>
                                <Col md={10}>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        value={meetingInfo.displayName}
                                        id="example-text-input"
                                        placeholder="Enter Name"
                                        onChange={(e) => {
                                            setMeetingInfo({ ...meetingInfo, displayName: e.target.value })
                                        }}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                    htmlFor="example-text-input"
                                    className="col-md-2 col-form-label"
                                >
                                    Room password
                                </Label>
                                <Col md={10}>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        value={meetingInfo.password}
                                        id="example-text-input"
                                        placeholder="Enter Room Password"
                                        onChange={(e) => {
                                            setMeetingInfo({ ...meetingInfo, password: e.target.value })
                                        }}
                                    />
                                </Col>
                            </FormGroup> */}
                            <Button color='success' onClick={createLink}>
                                Create Meeting link
                            </Button>
                            {/* <Button onClick={()=>setMeeting(true)}>
                                Create meeting
                            </Button> */}
                            {/* {
                                meeting
                                && (
                                   
                                )
                            } */}

                            {/* <JistsiMeeting/> */}
                        </CardBody>
                    </Card>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default Meeting