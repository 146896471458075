import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "PromoCode", link: "#" },
        { title: "Add & Manage PromoCode", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      amount: "",
      date: "",
      no_of_times: "",
      desc: "",
      duration: "",
      price: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      category_type: "",
      size: [{ name: "" }],
      flat_discount: "",
      per_discount: "",
      seller_discount: "",
    };
  }

  componentDidMount = async () => {
    this.fetchData();
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("coupons", "GET");
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          name: element.name,
          amount: element.minprice ? element.minprice : "",
          percent:
            element.discount_type === "% discount"
              ? element.discount_value + "%"
              : element.discount_value,
          date: element.expdate,
          no_of_times: element.times,
          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                  window.scrollTo(0,0);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let sendData = {};
    let result = await HttpClient.requestData(
      "coupons/" + item._id,
      "DELETE",
      sendData
    );
    if (result && result.status) {
      this.fetchData();
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      name: item.name,
      amount: item.minprice,
      percent: item.percent,
      date: item.expdate,
      no_of_times: item.times,
      edit_item_id: item._id,
      seller_discount: item.discount_type,
      flat_discount: item.discount_value,
      per_discount: item.discount_value,
    });
  };
  submit = async () => {
    if (this.state.name != "") {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.name,
          minprice: this.state.amount,
          expdate: this.state.date,
          percent: this.state.percent,
          times: this.state.no_of_times,
          discount_type: this.state.seller_discount,
          discount_value:
            this.state.seller_discount == "Flat discount"
              ? this.state.flat_discount
              : this.state.per_discount,
        };
        result = await HttpClient.requestData("coupons", "POST", data);
      } else {
        data = {
          name: this.state.name,
          minprice: this.state.amount,
          expdate: this.state.date,
          percent: this.state.percent,
          times: this.state.no_of_times,
          discount_type: this.state.seller_discount,
          discount_value:
            this.state.seller_discount == "Flat discount"
              ? this.state.flat_discount
              : this.state.per_discount,
        };
        console.log(data);
        result = await HttpClient.requestData(
          "coupons/" + this.state.edit_item_id,
          "PUT",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Promocode Updated Successfully"
            : "Promocode Added Successfully",
          type: "success",
          name: "",
          amount: "",
          date: "",
          no_of_times: "",
          flat_discount: "",
          per_discount: "",
          seller_discount: "",
          edit_enable: false,
          edit_item_id: "",
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message: "Something went wrong",
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({ category_type: event.target.value });
  };

  imageUpload = async (e) => {
    console.log("e", e.target.files);
    let file = e.target.files;
    //   if(file.lenght>0)
    //   {
    let dataSend = { buckate: true, buckate_name: "appIcon" };

    let result = await HttpClient.newFileUpload(
      "image-upload/category",
      e.target.files[0],
      dataSend
    );
    // console.log("result", result);
    if (result && result.status) {
      this.setState({ img_url: result.url, image_select: true });
    }

    //   }
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Min Amount",
          field: "amount",
          sort: "asc",
          width: 270,
        },
        {
          label: "Discounted Value",
          field: "percent",
          sort: "asc",
          width: 270,
        },
        {
          label: "Expiry Date",
          field: "date",
          sort: "asc",
          width: 270,
        },
        {
          label: "No. of Times",
          field: "no_of_times",
          sort: "asc",
          width: 270,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          placeholder="Enter PromoCode Name"
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Min Amount
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.amount}
                          placeholder="Enter Amount"
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ amount: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    {/* <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Percentage
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.percent}
                          id="example-text-input"
                          placeholder="Enter Percentage"
                          onChange={(val) => {
                            this.setState({ percent: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup> */}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Expired Date
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.date}
                          placeholder="Enter Amount"
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ date: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        No of Times Promocode should be applied
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.no_of_times}
                          placeholder="No of Times Promocode should be applied"
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ no_of_times: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Discount Type
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={(val) => {
                            this.setState({
                              seller_discount: val.target.value,
                            });
                          }}
                          value={this.state.seller_discount}
                        >
                          <option value="">Select Discount Type</option>
                          {/* <option value="Flat discount">Flat discount</option> */}
                          <option value="% discount">% discount</option>
                        </select>
                      </Col>
                    </FormGroup>
                    {this.state.seller_discount == "Flat discount" ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-search-input"
                          className="col-md-2 col-form-label"
                        >
                          Flat discount
                        </Label>
                        <Col md={10}>
                          <input
                            id="question-input1"
                            className="form-control"
                            rows="1"
                            type="number"
                            placeholder=""
                            onChange={(val) => {
                              this.setState({
                                flat_discount: val.target.value,
                              });
                            }}
                            value={this.state.flat_discount}
                          />
                        </Col>
                      </FormGroup>
                    ) : this.state.seller_discount == "% discount" ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-search-input"
                          className="col-md-2 col-form-label"
                        >
                          % discount
                        </Label>
                        <Col md={10}>
                          <input
                            id="question-input1"
                            className="form-control"
                            rows="1"
                            type="number"
                            placeholder=""
                            onChange={(val) => {
                              this.setState({
                                per_discount: val.target.value,
                              });
                            }}
                            value={this.state.per_discount}
                          />
                        </Col>
                      </FormGroup>
                    ) : (
                      ""
                    )}

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;