import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "../../utils/HttpClient";
// import EcomPreview from "./E-ComPreview/index";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import index from "../ChooseDesign";
import { success } from "toastr";

class FormElements extends Component {
  fileObj = [];
  fileArray = [];
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Refund Service Request", link: "#" },
        { title: "Manage Refund Service Request", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      //   this page states
      category: [],
      categoryAll: [],
      subCategory: [],
      subCategoryAll: [],

      cat_id: "",
      pruduct_type: "",
      sub_cat_id: "",

      // selectedFiles: [],
      images: "",
      uploaded_images: [],

      selling_price: "",
      mrp: "",
      purchase_price: "",
      discount: "",
      availableSize: [],
      availableColor: [],

      optionGroupSize: [{ value: "Red", code: "" }],
      selectedMultiSize: [],

      showSelectColor: false,
      showSelect: false,
      colorRgb: "#4667CC",
      simple_color1: false,
      color: [{ code: "#4667CC", active: false }],
      description: "",
      delivery_type: "",
      delivery_time: "",
      preview: [],
      multiImages: [],
      refresh: false,
      email: "",
      password: "",
      mobile: "",
    };

  }

  componentDidMount = async () => {
    // this.checkUser();
    this.fetchData();
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("service-refund-requests", "GET");
    console.log("service-refund-requests", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let fullname = element.firstname + " " + element.lastname
        let rows = {
          sl: i,
          name: fullname,
          country: element.country,
          email: element.user_details[0].email,
          refundamount: element.refund_amount,
          mobile: element.mobile,
          orderid: element.order_id,
          img: element.image ? (
            <div>
              <img
                src={"https://admin.blitzcode.online/" + element.image}
                alt="images"
                className="rounded avatar-md card-img"
              />
            </div>
          ) : null,
          status_approve: (
            <Button
              className="btn-icon"
              color="success"
              onClick={(e) => this.statusApprove(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-wink-outline mr-2"></i>
              </span>{" "}
              Approve
            </Button>
          ),
          status_reject: (
            <Button
              className="btn-icon"
              color="danger"
              onClick={(e) => this.statusReject(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-cry-outline mr-2"></i>
              </span>{" "}
              Reject
            </Button>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  deleteapi = async (item) => {
    let sendData = {};
    let result = await HttpClient.requestData(
      "product/Product/" + item._id,
      "DELETE",
      sendData
    );
    if (result && result.status) {
      this.fetchData();
    }
  };

  delete = async (item, index) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteapi(item),
        },
        {
          label: "No",
          // onClick: () => alert("Click No"),
        },
      ],
    });
  };

  statusApprove = async (index, id) => {
    console.log(id);
    let sendData = {};
    let result = await HttpClient.requestData(
      "approve-service-refund/" + id,
      "PUT",
      sendData
    );
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Refund request approved Successfully",
        type: "success"
      })
      // this.fetchData();
      window.location.href = "/chat-list";
    }
  };

  statusReject = async (index, id) => {
    let sendData = {};
    let result = await HttpClient.requestData(
      "reject-service-refund/" + id,
      "PUT",
      sendData
    );
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Refund request rejected",
        type: "success"
      })
      this.fetchData();
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Name",
          field: "name",

          width: 270,
        },
        {
          label: "Email",
          field: "email",

          width: 270,
        },
        {
          label: "Country",
          field: "country",

          width: 270,
        },
        {
          label: "Order Id",
          field: "orderid",

          width: 270,
        },
        {
          label: "Refund Amount",
          field: "refundamount",

          width: 270,
        },

        // {
        //   label: "Image",
        //   field: "img",

        //   width: 270,
        // },
        {
          label: "Approve",
          field: "status_approve",

          width: 100,
        },
        {
          label: "Reject",
          field: "status_reject",

          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl={12}>
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
                <div className="card p-3">
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
