import React, { Component } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledAlert
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Subscription", link: "#" },
        { title: "Subscription Plans", link: "#" },
      ],
      activeTab: "1",
      plans: [],
      activePlan:[],
      userId: "",
      userDetails: {},
      alert: false,
      message: "",
      type: "",
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount = () => {
    this.fetchPlans();
    this.checkUser();
  };

  fetchPlans = async () => {
    let result = await HttpClient.requestData("app-owner/subscription", "GET");
    console.log("result", result);
    if (result && result.status) {
      this.setState({ plans: result.data });
    } else {
    }
  };

  fetchActivePlans = async () => {
    let sendData = {"owner_id":this.state.userId};
    let result = await HttpClient.requestData("app-owner/subscription/active-paln", "POST",sendData);
    console.log("fetchActivePlans", result);
    if (result && result.status) {
      this.setState({ activePlan: result.data });
    } else {
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({ userId: user.id, userDetails: user });
      this.fetchActivePlans();
    } else {
    }
  };

  formValid = async (item) => {
    let sendData = {
        "owner_id":Number(this.state.userId),
        "sub_id":Number(item.id)
    }
    let result = await HttpClient.requestData("app-owner/subscription/buy-now", "POST",sendData);
    if (result && result.status > 0) {
        this.setState({ alert:true,message:'Plan Subscribed Successfully',type:'success' });
        this.fetchActivePlans();
        this.fetchPlans();
      } else {
        this.setState({ alert:true,message:result.error,type:'danger' });

      }
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="text-center mb-5">
                  <h4>Simple Pricing Plans</h4>
                  <p className="text-muted mb-4">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo veritatis
                  </p>

                  {/* <Nav pills className="pricing-nav-tabs">
                                        <NavItem>
                                            <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }}>Monthly</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: this.state.activeTab === '2' },"ml-1")} onClick={() => { this.toggleTab('2'); }}>Yearly</NavLink>
                                        </NavItem>
                                    </Nav> */}
                </div>
              </Col>
 
            </Row>
            <Row>
                <Col lg={12}>
                {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}</Col>
            </Row>
            <Row>
      
              {this.state.plans.map((item, index) => {
                let activePlan = false;
                if(this.state.activePlan.length>0)
                {
                    if(item.id == this.state.activePlan[0].sub_id)
                    {
                        activePlan = true;
                    }
                }
                return (
                  <Col xl={3} sm={6}>
                    <Card className="pricing-box" style={{borderColor:activePlan?'green':'#fff',borderWidth:1}}>
                      <CardBody className="p-4">
                        <div className="text-center">
                          <div className="mt-3">
                            <i className="ri-medal-line text-primary h1"></i>
                          </div>
                          <h5 className="mt-4">{item.name}</h5>

                          <div className="font-size-14 mt-4 pt-2">
                            <ul className="list-unstyled plan-features">
                              <li>{item.desc}</li>
                              {/* <li>Unlimited User</li>
                              <li>No Time Tracking</li> */}
                            </ul>
                          </div>

                          <div className="mt-5">
                            <h1 className="font-weight-bold mb-1">
                              <sup className="mr-1">
                                <small>$</small>
                              </sup>
                              {item.price}
                            </h1>
                            <p className="text-muted">
                              For {item.duration} Days
                            </p>
                          </div>

                          <div className="mt-5 mb-3">
                            {/* <Link to="#" className="btn btn-primary w-md">
                            Subscribe Now
                            </Link> */}
                            <Button
                              color="primary"
                              type="button"
                              className="waves-effect waves-light mr-1"
                              onClick={() => {
                                this.formValid(item);
                              }}
                              disabled ={activePlan?true:false}
                            >
                              {activePlan?"Activte Plan":"Subscribe Now"}
                              <i className="ri-arrow-right-line align-middle ml-1"></i>
                            </Button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Pricing;
