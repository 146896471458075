import React, { Component, useState, useEffect } from "react";
import "./ind432a.css";

function Design2(props) {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     // backgroundImage: this.props.backgroundImage,
  //     // item: this.props.item,
  //     // user: this.props.item.user,
  //     styleVal: this.props.valBR ? this.props.valBR : "",
  //   };
  // }
  // componentDidMount() {
  //   console.log("this.props.item.user", this.props.item);
  // }
  // render() {
  //   const { styleVal } = this.state;

  const [bgImage, setbgImage] = useState(props.bgImage);
  const [logo, setlogo] = useState(props.logo);
  const [title, settitle] = useState(props.title);
  const [state, setstate] = useState(props.state);
  const [styleVal, setstyleVal] = useState(props.valBR ? props.valBR : "");
  const [data, setdata] = useState(props.data);

  useEffect(() => {
    console.log("com bgImage", bgImage);
    console.log("com props.state", props.state);
  }, []);
  useEffect(() => {
    console.log("com bgImage", bgImage);
    console.log("com props.state", props.state);
    console.log("com props", props);
  }, [props]);
  return (
    <div
      className="designed_area w-100 h-100 bg-light relative"
      style={{
        borderRadius: styleVal,
        overflowY: "auto",
        backgroundImage: `url(${props.bgImage})`,
      }}
    >
      <div className="d-flex flex-column h-100">
        {/* item */}
        {props.data.user.map((item, index) => {
          return (
            <div
              className="item_panel d-flex w-100 py-3 my-3"
              style={{
                backgroundImage: `url(${item.bgImage})`,
              }}
            >
              <div className="my-auto ml-auto">
                <div className="d-flex design_356">
                  <img
                    src={item.avatar}
                    alt="avatar"
                    className="rounded-circle avatar-lg my-auto mr-2 shadow"
                  />
                  <div>
                    <div
                      className="text_block py-2 px-2"
                      style={{
                        backgroundColor: props.data.titlebg
                          ? props.data.titlebg
                          : "#343a40",
                      }}
                    >
                      <h5
                        className=""
                        style={{
                          color: props.data.titlecolor
                            ? props.data.titlecolor
                            : "#fff",
                          fontFamily: props.data.fontFamily
                            ? props.data.fontFamily
                            : "",
                          fontSize:
                            props.data.fontValue != ""
                              ? Number(props.data.fontValue)
                              : 16,
                        }}
                      >
                        {item.title}
                      </h5>
                    </div>
                    <div
                      className="text_block py-1 px-2"
                      style={{
                        backgroundColor: props.data.namebg
                          ? props.data.namebg
                          : "#fff",
                      }}
                    >
                      <h6
                        className=""
                        style={{
                          color: props.data.namecolor
                            ? props.data.namecolor
                            : "#000",
                          fontFamily: props.data.fontFamily
                            ? props.data.fontFamily
                            : "",
                          fontSize:
                            props.data.fontValue != "" &&
                            Number(props.data.fontValue) > 15
                              ? Number(props.data.fontValue) - 2
                              : 14,
                        }}
                      >
                        {item.name}
                      </h6>
                      <span
                        style={{
                          color: props.data.namecolor
                            ? props.data.namecolor
                            : "#000",
                          fontFamily: props.data.fontFamily
                            ? props.data.fontFamily
                            : "",
                          fontSize:
                            props.data.fontValue != "" &&
                            Number(props.data.fontValue) > 15
                              ? Number(props.data.fontValue) - 2
                              : 14,
                        }}
                      >
                        {item.position}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {/* bottom icon's area */}
        <div
          className="mb-0 mt-auto mx-auto w-100"
          style={{
            borderBottomLeftRadius: styleVal,
            borderBottomRightRadius: styleVal,
            background: props.data.iconbgcolor,
          }}
        >
          <div className="d-flex w-100 px-3">
            {props.data.icons.map((item, index) => {
              return (
                <div className="m-auto" key={index}>
                  <img src={item.iconUrl} style={{ height: 40, width: 40 }} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
// }
export default Design2;
