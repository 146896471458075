import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import ReactJsAlert from "reactjs-alert";
// import { Row, Col, Card, CardBody, Button } from "reactstrap";

import {
  Container,
  Row,
  Col,
  Tooltip,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Input,
  Form,
  Button,
  FormGroup,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import BaseInfo from "../ChooseDesign/baseInfo";

export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1,
      tooltipOpen: false,
      result: [],
      data: [],
      monthDate: new Date(),
      modal_static: false,
      cardLoop: [1, 2, 3, 4],
      question_id: this.props.match.params.id,

      question: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      answer: "",
      questionObj: {
        question: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        answerRadio: "",
      },
      quizName: "",
      startDate: new Date(),
      endDate: new Date(),
      userId: "",
      userDetails: {},
      giftCard: [],
      giftCardSelected: [],
      img: {},
      imgUrl: "",
      refreshStatus: false,
      // know your customer
      previewKnow: false,
      previewData: {},
      resultKnow: [],
      dataKnow: [],
      breadcrumbItems: [
        { title: "Question ", link: "#" },
        { title: "Add & Manage Question", link: "#" },
      ],
      alert: false,
      message: "",
      type: "",
      status: false,
      title: "",
      QuestionOptions: [],
      selectedOptionValue: "",
      answer: "",
      lastquestion: false,
      optionid: "",
      opentextbox: false,
      Remarks: "",
      lastQuestionView: false,
      apiremarkresponse: "",
    };
    // this.toggleTab = this.toggleTab.bind(this);
    // this.handleMonthChange = this.handleMonthChange.bind(this);
    // this.tog_CardModal = this.tog_CardModal.bind(this);
  }

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };

  componentDidMount() {
    this.checkUser();
    this.fetch();
  }

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({ userId: user.id, userDetails: user });
      this.fetchQuestionOptions();
    } else {
    }
  };

  fetchQuestionOptions = async () => {
    let sendData = {
      qstnid: parseInt(this.state.question_id),
    };

    console.log("SendDATA", sendData);

    let result = await HttpClient.requestData(
      "getoptionByqstn",
      "POST",
      sendData
    );
    console.log("QuestionOption", result);
    if (result && result.status > 0) {
      this.setState({
        QuestionOptions: result.data,
      });

      setTimeout(() => {
        console.log(this.state.QuestionOptions);
      }, 3000);
    }
  };

  fetchCard = async () => {
    let sendData = { owner_id: this.state.userId };
    let result = await HttpClient.requestData(
      "admin/gift-card/fetch-all",
      "POST",
      sendData
    );
    console.log("result", result);
    if (result && result.status > 0) {
      this.setState({ giftCard: result.data });
    } else {
    }
  };

  fetch = async () => {
    let sendData = {
      parentid: parseInt(this.state.question_id),
    };

    console.log("FetchSendData", sendData);
    let result = await HttpClient.requestData("getsubqstn", "POST", sendData);
    console.log("resultSubbQuestion2ndpage", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({
        result: result.data,
        refreshStatus: !this.state.refreshStatus,
      });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,

          question: element.question ? element.question : null,
          option1: element.option_data
            ? element.option_data.map((item, indexx) => {
                return indexx == element.option_data.length - 1
                  ? item.option + " " + " "
                  : item.option + " ," + " ";
              })
            : null,

          view: (
            <>
              <button
                title="products"
                className="btn btn-success"
                onClick={() => {
                  window.location.href = "/morequestion" + element.id;
                }}
              >
                View
              </button>
            </>
          ),
          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={(e) => this.delete(element)}
              >
                <i className="fa fa-trash" />
              </button>
              {element.status == true ? (
                <Button
                  className="btn-icon"
                  color="success"
                  onClick={(e) =>
                    this.status(index, element, element.status, e)
                  }
                >
                  {" "}
                  <span className="btn-icon-label">
                    <i className="mdi mdi-emoticon-wink-outline mr-2"></i>
                  </span>{" "}
                  Active
                </Button>
              ) : (
                <Button
                  className="btn-icon"
                  color="danger"
                  onClick={(e) =>
                    this.status(index, element, element.status, e)
                  }
                >
                  {" "}
                  <span className="btn-icon-label">
                    <i className="mdi mdi-emoticon-cry-outline mr-2"></i>
                  </span>{" "}
                  Deactive
                </Button>
              )}
              ,
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  async status(index, data, state, e) {
    console.log("result", index);
    let result = await HttpClient.requestData(
      "status/" + data._id,
      "GET",
      data
    );
    console.log("result", result);
    if (result && result.status === true) {
      // this.FetchAll();
      let data = result.data;
      this.state.result[index] = data;
      this.setState({});
      this.UpdateState();
      // console.log("mask profileeeeee", this.state.result);
      // this.setState({
      //   result: result
      // });
    }
  }

  async UpdateState() {
    let data = [];
    let i = 1;
    this.state.result.forEach((element, index) => {
      let rows = {
        sl: i,

        question: element.question ? element.question : null,
        option1: element.option1 ? element.option1 : null,
        option2: element.option2 ? element.option2 : null,
        option3: element.option3 ? element.option3 : null,
        option4: element.option4 ? element.option4 : null,
        action: (
          <>
            <button
              title="Delete"
              className="btn btn-danger mr-2"
              onClick={(e) => this.delete(element)}
            >
              <i className="fa fa-trash" />
            </button>
            {element.status == true ? (
              <Button
                className="btn-icon"
                color="success"
                onClick={(e) => this.status(index, element, element.status, e)}
              >
                {" "}
                <span className="btn-icon-label">
                  <i className="mdi mdi-emoticon-wink-outline mr-2"></i>
                </span>{" "}
                Active
              </Button>
            ) : (
              <Button
                className="btn-icon"
                color="danger"
                onClick={(e) => this.status(index, element, element.status, e)}
              >
                {" "}
                <span className="btn-icon-label">
                  <i className="mdi mdi-emoticon-cry-outline mr-2"></i>
                </span>{" "}
                Deactive
              </Button>
            )}
            ,
          </>
        ),
      };
      data.push(rows);
      this.setState({
        data: data,
      });
    });
  }

  delete = async (item, index) => {
    let sendData = {
      id: item.id,
    };
    let result = await HttpClient.requestData("delete", "POST", sendData);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        // this.setState({
        //   alert: true,
        //   message: "Deleted Succfully",
        //   type: "success",
        // });
        this.setState({
          type: "success",
          status: true,
          title: "Deleted Succfully",
        });
        // setTimeout(() => {
        //   this.setState({
        //     alert: false,
        //     message: "",
        //     type: "",
        //   });
        // }, 3000);
        this.fetch();
      }
    }
  };

  blankChk = (index) => {
    if (
      this.state.question[index].answer != "" &&
      this.state.question[index].question != "" &&
      this.state.question[index].option1 != "" &&
      this.state.question[index].option2 != "" &&
      this.state.question[index].option3 != "" &&
      this.state.question[index].option4 != ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  optionChk = (index) => {
    if (
      this.state.question[index].answer == this.state.question[index].option1 ||
      this.state.question[index].answer == this.state.question[index].option2 ||
      this.state.question[index].answer == this.state.question[index].option3 ||
      this.state.question[index].answer == this.state.question[index].option4
    ) {
      return true;
    } else {
      return false;
    }
  };

  formValid = async () => {
    if (
      this.state.quizName != "" &&
      this.state.startDate != "" &&
      this.state.endDate != ""
    ) {
      const startDate = moment(this.state.startDate);
      const timeEnd = moment(this.state.endDate);
      const diff = timeEnd.diff(startDate);
      const diffDuration = moment.duration(diff);
      if (diffDuration.days() > 0) {
        let blank = this.blankChk(0);
        console.log("blank", blank);
        if (blank) {
          let option = this.optionChk(0);
          console.log("option", option);

          if (option) {
            // this.tog_CardModal();
          } else {
            alert("Answer Must Be In Options");
          }
        } else {
          alert("Please Add Atleast One Question.");
        }
      } else {
        alert("Invalid End Date Details.");
      }
    } else {
      alert("Please Fillup Details. ll");
    }
  };

  handlesubmit = async () => {
    if (
      this.state.question == "" &&
      this.state.option1 == "" &&
      this.state.option2 == "" &&
      this.state.option3 == "" &&
      this.state.option4 == ""
    ) {
      // alert("Please Fillup Details");

      this.setState({
        type: "error",
        status: true,
        title: "Please Fillup Details",
      });
    } else {
      let data = {
        question: this.state.question,
        optionid: parseInt(this.state.selectedOptionValue),
        parentid: parseInt(this.state.question_id),
        last: this.state.lastquestion,
        option1: JSON.stringify(this.state.option1),
        option2: JSON.stringify(this.state.option2),
        option3: JSON.stringify(this.state.option3),
        option4: JSON.stringify(this.state.option4),
        remark: this.state.Remarks,
      };

      console.log("SubmitData2", data);
      let result = await HttpClient.requestData("addSubquestion", "POST", data);

      console.log("ResponseSubSubmit2", result);

      if (result && result.status) {
        this.setState({
          result: result.data,
          apiremarkresponse: result.data.remark,
          type: "success",
          status: true,

          title: "Successfully Submited.",
        });
        this.fetch();
        this.setState({
          question: "",
          option1: "",
          option2: "",
          option3: "",
          option4: "",
        });
      } else {
        this.setState({
          type: "error",
          status: true,
          title: result.error,
        });
      }
      // setTimeout(() => {
      //   this.setState({
      //     alert: false,
      //     message: "",
      //     type: "",
      //   });
      // }, 3000);
    }
  };

  changeAnswer = async (e) => {
    const value = e.target.value;

    console.log(value);
    if (value == "Yes") {
      console.log("In", value);
      this.setState({ lastquestion: true, opentextbox: true });
    } else {
      console.log("Infalse", value);
      this.setState({ lastquestion: false, opentextbox: false });
    }
    this.setState({ answerRadio: value });
  };

  preview = () => {};

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Question",
          field: "question",
          sort: "asc",
          width: 270,
        },
        {
          label: "Option 1",
          field: "option1",
          sort: "asc",
          width: 270,
        },

        {
          label: "View",
          field: "view",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };

    const dataKnow = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Question",
          field: "quizName",
          sort: "asc",
          width: 270,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.dataKnow,
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <ReactJsAlert
            type={this.state.type}
            title={this.state.title}
            status={this.state.status}
            Close={() => this.setState({ status: false })}
          />
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              {/* <Col xl={12}> */}
              {/* <BaseInfo /> */}
              {/* </Col> */}
              <Col xl={12}>
                <div>
                  {/* <div>
                    <h3 className="title-editor py-1 text-center">
                      Gamify{" "}
                      <i
                        className="fa fa-info-circle display_tooltip"
                        id="TooltipInfo"
                      />
                      <Tooltip
                        placement="top"
                        isOpen={this.state.tooltipOpen}
                        target="TooltipInfo"
                        toggle={this.toggle}
                        autohide={false}
                      >
                        Refresh your mind with our quizes
                      </Tooltip>
                    </h3>
                  </div> */}
                  <div
                    id="basic-pills-wizard"
                    className="twitter-bs-wizard bg-white"
                  >
                    {/* <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === 1,
                          })}
                          onClick={() => {
                            this.toggleTab(1);
                          }}
                        >
                          <span className="step-number">01</span>
                          <span className="step-title">Create a Quiz </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === 2,
                          })}
                          onClick={() => {
                            this.toggleTab(2);
                          }}
                        >
                          <span className="step-number">02</span>
                          <span className="step-title">Know Your Customer</span>
                        </NavLink>
                      </NavItem>
                    </ul> */}
                    <TabContent
                      activeTab={this.state.activeTab}
                      className="twitter-bs-wizard-tab-content pt-0"
                    >
                      {/* <TabPane tabId={1}> */}
                      <Form className="card p-3">
                        <h4>Add your question</h4>
                        <Row>
                          {/* <Col lg="6">
                            <FormGroup>
                              <Label for="quiz-input1">Quiz Name</Label>
                              <Input
                                className="form-control"
                                type="text"
                                defaultValue=""
                                id="quiz-input1"
                                placeholder="Enter Quiz Name..."
                                onChange={(val) => {
                                  this.setState({
                                    quizName: val.target.value,
                                  });
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <Label>Start Date</Label>
                              <InputGroup>
                                <DatePicker
                                  selected={this.state.startDate}
                                  className="form-control"
                                  onChange={(e) => {
                                    this.handleMonthChange(e, "startDate");
                                  }}
                                  showMonthDropdown
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <Label>Expire Date</Label>
                              <InputGroup>
                                <DatePicker
                                  selected={this.state.endDate}
                                  className="form-control"
                                  onChange={(e) => {
                                    this.handleMonthChange(e, "endDate");
                                  }}
                                  showMonthDropdown
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <Label for="question-input1">Image</Label>
                              <Input
                                className="form-control"
                                type="file"
                                value={""}
                                id="option-input1"
                                placeholder="Enter option 1..."
                                onChange={async (e) => {
                                  this.state.imgUrl = URL.createObjectURL(
                                    e.target.files[0]
                                  );
                                  let result = await this.imageUpload(
                                    e.target.files[0]
                                  );
                                  if (result && result.status) {
                                    this.setState({ imgUrl: result.url });
                                  }
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            {this.state.imgUrl ? (
                              <img
                                className="rounded mr-2"
                                alt="Quiz Image"
                                height={100}
                                src={this.state.imgUrl}
                              />
                            ) : null}
                          </Col> */}

                          {/* {this.state.question.map((item, index) => {
                            return ( */}
                          <>
                            <Col lg="12">
                              <FormGroup row>
                                <Col md={10}>
                                  <select
                                    onChange={(val) => {
                                      this.setState({
                                        selectedOptionValue: val.target.value,
                                      });

                                      setTimeout(() => {
                                        console.log(
                                          this.state.selectedOptionValue
                                        );
                                      }, 2000);
                                    }}
                                    className="form-control"
                                  >
                                    <option value="">Select Option</option>
                                    {this.state.QuestionOptions.map(
                                      (item, index) => {
                                        return (
                                          <option value={item.id}>
                                            {item.option}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                </Col>
                              </FormGroup>
                              <FormGroup>
                                <Label for="question-input1">
                                  Describe Question
                                </Label>
                                <textarea
                                  id="question-input1"
                                  className="form-control"
                                  rows="2"
                                  placeholder="Enter your question here..."
                                  onChange={(val) => {
                                    this.state.question = val.target.value;
                                    this.setState({});
                                  }}
                                  value={this.state.question}
                                ></textarea>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <Label for="option-input1">Option 1</Label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.option1}
                                  id="option-input1"
                                  placeholder="Enter option 1..."
                                  onChange={(val) => {
                                    this.state.option1 = val.target.value;
                                    this.setState({});
                                  }}
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label for="option-input2">Option 2</Label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.option2}
                                  id="option-input2"
                                  placeholder="Enter option 2..."
                                  onChange={(val) => {
                                    this.state.option2 = val.target.value;
                                    this.setState({});
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <Label for="option-input3">Option 3</Label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.option3}
                                  id="option-input3"
                                  placeholder="Enter option 3..."
                                  onChange={(val) => {
                                    this.state.option3 = val.target.value;
                                    this.setState({});
                                  }}
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label for="option-input4">Option 4</Label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.option4}
                                  id="option-input4"
                                  placeholder="Enter option 4..."
                                  onChange={(val) => {
                                    this.state.option4 = val.target.value;
                                    this.setState({});
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </>
                          <Col lg="8">
                            <Col lg="3">
                              <Label for="option-input4">Last Question</Label>
                            </Col>

                            <Col lg="5">
                              <FormGroup>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  value="Yes"
                                  onChange={(val) => this.changeAnswer(val)}
                                  checked={
                                    this.state.answerRadio === "Yes"
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Yes
                                </label>
                              </FormGroup>

                              <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="exampleRadios1"
                                value="No"
                                onChange={(val) => this.changeAnswer(val)}
                                checked={
                                  this.state.answerRadio === "No" ? true : false
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleRadios1"
                              >
                                No
                              </label>

                              {this.state.opentextbox ? (
                                <>
                                  <FormGroup>
                                    <Label for="option-input4">Remarks</Label>
                                    <Input
                                      className="form-control"
                                      type="text"
                                      value={this.state.Remarks}
                                      id="option-input4"
                                      placeholder="Enter Remarks"
                                      onChange={(val) => {
                                        this.state.Remarks = val.target.value;
                                        this.setState({});
                                      }}
                                    />
                                  </FormGroup>
                                </>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Col>

                          <Col lg="6">
                            <FormGroup className="mb-0">
                              <div className="button-items pt-4">
                                <Button
                                  color="primary"
                                  type="button"
                                  className="waves-effect waves-light mr-1"
                                  onClick={() => {
                                    this.handlesubmit();
                                  }}
                                >
                                  Submit{" "}
                                  <i className="ri-arrow-right-line align-middle ml-1"></i>
                                </Button>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                      <div className="card px-3 pt-0"></div>
                    </TabContent>
                  </div>
                  {/* <Modal
                    isOpen={this.state.modal_static}
                    toggle={this.tog_CardModal}
                    backdrop="static"
                  >
                    <ModalHeader
                      toggle={() => this.setState({ modal_static: false })}
                      className="bg-light"
                    >
                      Choose card
                    </ModalHeader>
                    <ModalBody>
                      <h5 className="mb-3">Select card for your quiz</h5>
                      {this.state.giftCard.map((item, index) => {
                        return (
                          <div
                            className="custom-control custom-checkbox mb-3"
                            key={index}
                          >
                            <Input
                              type="checkbox"
                              className="custom-control-input"
                              id={"CustomCheck1" + index}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  let check =
                                    this.state.giftCardSelected.filter(
                                      (it) => it.id == item.id
                                    );
                                  if (check.length == 0) {
                                    this.state.giftCardSelected.push(item);
                                    this.setState({});
                                  } else {
                                  }
                                } else {
                                  let ind =
                                    this.state.giftCardSelected.indexOf(item);
                                  if (ind > -1) {
                                    this.state.giftCardSelected.splice(ind, 1);
                                    this.setState({});
                                  }
                                }
                              }}
                            />
                            <Label
                              className="custom-control-label"
                              htmlFor={"CustomCheck1" + index}
                            >
                              Card : {item.loyaltyNumber + " - " + item.type}
                            </Label>
                          </div>
                        );
                      })}
                      <ModalFooter>
                        <Button
                          type="button"
                          color="light"
                          onClick={() => this.setState({ modal_static: false })}
                        >
                          Close
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          onClick={() => {
                            this.submitFrom();
                          }}
                        >
                          Save
                        </Button>
                      </ModalFooter>
                    </ModalBody>
                  </Modal> */}
                </div>
              </Col>
              {/* <Col xl={4}>
                <div>
                  <QuizPreview
                    quizData={this.state.result}
                    refreshStatus={this.state.refreshStatus}
                    userDetails={this.state.userDetails}
                  />
                </div>
              </Col> */}
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {this.state.activeTab == 1 ? (
                    <MDBDataTable responsive bordered data={data} />
                  ) : (
                    <MDBDataTable responsive bordered data={dataKnow} />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
