import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default class addTextBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTextBox: this.props.showTextBox ? this.props.showTextBox : false,
      design: this.props.design,
      design4title:
        this.props.design == 1 && this.props.activeTextMain
          ? this.props.activeTextMain
          : this.props.design == 4 && this.props.design4Data
            ? this.props.design4Data.user[0].title
            : this.props.design == 2 && this.props.design4Data
              ? this.props.design4Data.user[this.props.activeIndex].title
              : "Write Something...",
      design4designation:
        this.props.design == 4 && this.props.design4Data
          ? this.props.design4Data.user[0].title
          : this.props.design == 2 && this.props.design4Data
            ? this.props.design4Data.user[this.props.activeIndex].title
            : "",
      design4name:
        this.props.design == 4 && this.props.design4Data
          ? this.props.design4Data.user[0].position
          : this.props.design == 2 && this.props.design4Data
            ? this.props.design4Data.user[this.props.activeIndex].position
            : "",
      text: this.props.design == 4 && this.props.design4Data
      ? this.props.design4Data.user[0].text
      : this.props.design == 2 && this.props.design4Data
        ? this.props.design4Data.user[this.props.activeIndex].text:"",

      activeText: this.props.activeText ? this.props.activeText : false,
      activeTextMain: this.props.activeTextMain ? this.props.activeTextMain : "Something",
      ckediter: this.props.ckediter ? this.props.ckediter : false,
    };
  }

  componentDidMount() {
    console.log("this.props.design4Data", this.props.ckediter);
  }

  closeModal = () => {
    this.props.callBackText(false);
    this.setState({ showTextBox: false });
  };
  render() {
    return (
      <div
        className={this.state.showTextBox ? "modal fade show" : "modal fade"}
        id="addImageModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addImageModalLabel"
        style={{ display: this.state.showTextBox ? "block" : "none" }}
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ marginTop: "5%" }}
        >
          <div className="modal-content">
            <div className="modal-header bg-light">
              <h5 className="modal-title" id="addTextModalLabel">
                Add Texts
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.closeModal}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {this.state.design == 4 || this.state.design == 2 && this.props.ckediter == false ? (
                <div className="upload_text_area">
                  <form className="form">
                    <textarea
                      rows={4}
                      className="form-control mb-3"
                      placeholder="Enter your title here"
                      value={this.state.design4title}
                      onChange={(val) => {
                        this.setState({ design4title: val.target.value });
                      }}
                    />
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="Enter name..."
                      value={this.state.design4name}
                      onChange={(val) => {
                        this.setState({ design4name: val.target.value });
                      }}
                    />

                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="Enter designation..."
                      value={this.state.design4designation}
                      onChange={(val) => {
                        this.setState({ design4designation: val.target.value });
                      }}
                    />
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        this.props.callBackText(false);
                        this.props.submitChange(
                          this.state.design4title,
                          this.state.design4name,
                          this.state.design4designation,
                          this.state.text
                        );
                        this.setState({ showTextBox: false });
                      }}
                    >
                      Save Changes
                    </button>
                    {/* <button type="button" className="btn btn-danger ml-3">
                      Add More Text
                    </button> */}
                  </form>
                </div>
              ) : this.state.design == 1 ? (
                <div className="upload_text_area">
                  <form className="form">

                    <CKEditor
                      editor={ClassicEditor}
                      data={`<p>${this.state.design4title}</p>`}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        // this.setState({ design4title: data });
                      }}
                      onBlur={(event, editor) => {
                        const data = editor.getData();
                        this.setState({ design4title: data });
                      }}
                    />
                    <button
                      type="button"
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        this.props.callBackText(false);
                        this.props.submitChange(
                          this.state.design4title,
                          this.state.design4name,
                          this.state.design4designation,
                          this.state.text
                        );
                        this.setState({ showTextBox: false });
                      }}
                    >
                      Save Changes
                    </button>

                  </form>
                </div>
              ) : <div className="upload_text_area">
                <form className="form">

                  <CKEditor
                    editor={ClassicEditor}
                    data={`<p>${this.state.text}</p>`}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // this.setState({ design4title: data });
                    }}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      this.setState({ text: data });
                    }}
                  />
                  <button
                    type="button"
                    className="btn btn-primary mt-3"
                    onClick={() => {
                      this.props.callBackText(false);
                      this.props.submitChange(
                        this.state.design4title,
                        this.state.design4name,
                        this.state.design4designation,
                        this.state.text
                      );
                      this.setState({ showTextBox: false });
                    }}
                  >
                    Save Changes
                </button>

                </form>
              </div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
