import React, { Component } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Button,
    UncontrolledAlert,
    InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import Modal from "./cancelmodal";
import { CSVLink } from "react-csv";

//import ModalPendingBooking from "./ModalPendingBooking";

const header = [
    { label: "Order Id", key: "order_id" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Price", key: "price" },
    { label: "Day of Booking", key: "day" },
    { label: "Slot Time(From)", key: "fromtime" },
    { label: "Slot Time(to)", key: "totime" },
    { label: "Date of Booking", key: "bookdate" },
    { label: "Category Name", key: "cat_name" },
    { label: "Service Name", key: "service" },
]
class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Cancel Booking List", link: "#" },
                { title: "Manage Cancel Booking List", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            // page states
            name: "",
            email: "",
            mobile: "",
            password: "",
            data: [],
            alert: false,
            message: "",
            type: "",
            result: [],
            edit_enable: false,
            edit_item_id: "",
            image_select: false,
            img_url: "",
            userId: "",
            userDetails: {},

            image_select: false,
            img_url: "",
            isOpenLike: false,
            isOpenLike1: false,
            bookingid: "",
            modaluserid: "",
            cancelBooking: false,
            pincode: "",
        };
        // this.handleDefault = this.handleDefault.bind(this);
    }



    componentDidMount = async () => {
        this.checkUser();
    };

    callbackLike = async () => {
        this.state.isOpenLike = false;
        this.setState({});

        console.log(this.state.isOpenLike);
    };
    //   callbackLike1 = async () => {
    //     this.state.isOpenLike1 = false;
    //     this.setState({});

    //     console.log(this.state.isOpenLike1);
    //   };

    checkUser = async () => {
        let user = await reactLocalStorage.getObject("userData");
        console.log("user", user);
        if (user != null && Object.keys(user).length > 0) {
            this.setState({
                userId: user._id,
            });
            this.fetchData(user._id);
        }

    };

    fetchData = async (id) => {

        let result = await HttpClient.requestData("seller-service/rejected-bookings", "GET");
        console.log("resultttttttttttt", result);
        if (result && result.status) {
            this.setState({
                result: result.data,
            })
            let cur_data = [];
            let tr = result.data.filter((it) => it.booking_accept == false);
            if (tr.length > 0) {
                this.setState({ result: tr });
            }
            console.log("***", this.state.result);
            let data = [];
            let i = 1;
            let sl = 1;

            result.data.forEach((element, index) => {
                let i = element.shopservice_data[0].image.findIndex(
                    (element) =>
                        element.ismain == true
                );
                let image = element.shopservice_data.length > 0 ? element.shopservice_data[0].image : "";
                let fname = element.user_data.length > 0 ? element.user_data[0].firstName : "";
                let lname = element.user_data.length > 0 ? element.user_data[0].lastName : "";
                let rows = {
                    sl: sl,
                    name: fname + " " + lname,
                    email: element.user_data.length > 0 ? element.user_data[0].email : "",
                    day: element.day_name_of_booking ? element.day_name_of_booking : "No Day",

                    bookdate: moment(element.date_of_booking).format("DD-MM-YYYY"),

                    cat_name: element.shopservice_data.length > 0 ? element.shopservice_data[0].category_name : "",
                    order_id: element.order_id,
                    price: "$" + element.cart_data[0]?.price_cad,
                    discount: element?.cart_data[0]?.discount_cad?.toFixed(2) ?? "0",

                    fromtime: element.from,
                    totime: element.to,
                    service: element.shopservice_data.length > 0 ? element.shopservice_data[0].name : "",
                    img: (
                        <div>
                            {i > -1 ?
                                <img
                                    src={HttpClient.IMG_URL + element.shopservice_data[0].image[i].preview}
                                    alt="images"
                                    className="rounded avatar-md card-img"
                                /> :
                                <img
                                    src={HttpClient.IMG_URL + element.shopservice_data[0].image[0].preview}
                                    alt="images"
                                    className="rounded avatar-md card-img"
                                />}

                        </div>
                    ),




                    View: (
                        <>
                            <button
                                className="btn btn-success mr-2"
                                onClick={() => {
                                    this.setState({
                                        isOpenLike: true,
                                        modaluserid: element._id,
                                    });
                                }}
                            >
                                View
                            </button>
                        </>
                    ),

                    assign:

                        <button

                            className={"btn btn-danger mr-2"}

                        >
                            Booking Cancelled
                        </button>,


                };
                i++;
                data.push(rows);
            });
            this.setState({
                data: data,
            });
        } else {
            this.setState({
                data: [],
            });
        }
    };



    render() {
        const data = {
            columns: [
                {
                    label: "Sl.",
                    field: "sl",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Order Id.",
                    field: "order_id",
                    sort: "asc",
                    width: 150,
                },
                // {
                //   label: "Booked By",
                //   field: "booked_by",
                //   sort: "asc",
                //   width: 270,
                // },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Email",
                    field: "email",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Price",
                    field: "price",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Discount",
                    field: "discount",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Day of Booking",
                    field: "day",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Slot Time(From)",
                    field: "fromtime",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Slot Time(to)",
                    field: "totime",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Date of Booking",
                    field: "bookdate",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Category Name",
                    field: "cat_name",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Service Name",
                    field: "service",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Image",
                    field: "img",
                    sort: "asc",
                    width: 100,
                },



                {
                    label: "Booking Status",
                    field: "assign",
                    sort: "asc",
                    width: 100,
                },


                {
                    label: "View",
                    field: "View",
                    sort: "asc",
                    width: 100,
                },
                // {
                //   label: "Action",
                //   field: "action",
                //   sort: "asc",
                //   width: 100,
                // },
            ],
            rows: this.state.data,
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.state.breadcrumbItems[0].title}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col lg={12}>
                                {this.state.alert ? (
                                    <UncontrolledAlert
                                        color={this.state.type}
                                        className="alert-dismissible fade show"
                                        role="alert"
                                    >
                                        {this.state.type == "warning" ? (
                                            <i className="mdi mdi-alert-outline mr-2"></i>
                                        ) : this.state.type == "success" ? (
                                            <i className="mdi mdi-check-all mr-2"></i>
                                        ) : this.state.type == "danger" ? (
                                            <i className="mdi mdi-block-helper mr-2"></i>
                                        ) : null}
                                        {this.state.message}
                                    </UncontrolledAlert>
                                ) : null}
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12}>

                                {this.state.data.length > 0 ?
                                    <CSVLink
                                        data={this.state.data}
                                        headers={header}
                                        filename={"RejectBooking.csv"}
                                        className="btn btn-success mb-2">
                                        Export Report in CSV
                                    </CSVLink> : null}

                                <div className="card p-3 mb-2">
                                    <MDBDataTable responsive bordered data={data} disableRetreatAfterSorting={true} />
                                </div>
                            </Col>
                        </Row>
                    </Container>{" "}
                    {this.state.isOpenLike ? (
                        <Modal
                            isOpen={this.state.isOpenLike}
                            userid={this.state.userId}
                            modaluserid={this.state.modaluserid}
                            closeCallback={this.callbackLike}
                        />
                    ) : null}
                </div>

            </React.Fragment>
        );
    }
}

export default FormElements;