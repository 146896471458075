import React, { Component } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

//Simple bar
import SimpleBar from "simplebar-react";

class RecentlyActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        }
    }

    render() {
        return (
            <React.Fragment>
                <Col lg={4}>
                    <Card>
                        <Link to={'/pending-booking'}>
                            <CardBody className="bg-primary">
                                <div className="text-center">
                                    <p className="mb-2 text-white">Pending Booking</p>
                                    <h4 className='text-white'>{this.props.pending}</h4>
                                </div>
                            </CardBody>
                        </Link>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default RecentlyActivity;