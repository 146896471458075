import React, { useState } from "react";
import { hexToCSSFilter } from "hex-to-css-filter";

export default function Index(props) {
  const [hexColor, setHexColor] = useState("#000000");

  let cssFilterValue = "";
  const cssFilter = hexToCSSFilter(hexColor, {
    acceptanceLossPercentage: 1,
    maxChecks: 10,
  });
  // cheery knows, that semi-colon should be removed from the string
  cssFilterValue = cssFilter.filter.replace(";", "");

  const changeHexColor = (e) => {
    setHexColor(e.target.value);
    props.changeHandler(cssFilterValue);
  };

  return (
    <div>
      <div>
        <label for="color-picker">
          <p className="mb-0 mr-3 text-dark">
            Pick a color to change icon color
          </p>
        </label>
        <input
          name="color-picker"
          aria-label="icon-color-picker"
          type="color"
          value={hexColor}
          onChange={changeHexColor}
          // onChange={(e) => setHexColor(e.target.value)}
        />
      </div>
    </div>
  );
}
