import React, { Component } from 'react';
import { Row, Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";

//Import Vector Map Component
import Vector from "./Vectormap";

class AdminWallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false
        }
    }

    render() {
        return (
            <React.Fragment>
                <Col lg={4}>
                    <Card className="bg-warning card">
                        <CardBody >
                            <div>
                                <div className="text-center">
                                    <p className="mb-2 text-white">Admin Wallet</p>
                                    <h4 className='text-white'>{this.props.adminwallet}</h4>

                                </div>

                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default AdminWallet;