//Modal.js(For Like)

import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  Row,
  Col,
  UncontrolledAlert,
} from "reactstrap";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";

class ModalPendingBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {

      alert: false,
      message: "",
      type: "",
      id: this.props.bookingid,
      name: "",
      email: "",
      result: [],
      data: [],
      userdata: {},
      servicedata: {},
    };
  }

  componentDidMount() {

    this.fetchData();
  }

  fetchData = async () => {

    let result = await HttpClient.requestData("seller-service/accepted-bookings", "GET");
    console.log(result);
    if (result && result.status) {
      this.setState({ result: result.data });
      let rr = result.data.filter((item) => item._id == this.props.modaluserid);
      console.log("PendingBooking", rr[0]);
      this.setState({
        data: rr[0],
        userdata: rr[0].user_data.length > 0 ? rr[0].user_data[0] : "",
        servicedata: rr[0].shopservice_data.length > 0 ? rr[0].shopservice_data[0] : "",

      });
      console.log("Pending Booking", this.state.data);
      console.log("userdata", this.state.userdata);
      console.log("servicedata", this.state.servicedata);
    }
  };


  render() {
    return (
      <Modal isOpen={this.props.isOpen}>
        <ModalHeader tag="h5" style={{ color: "DodgerBlue" }}>
          View Accepted Booking Details
        </ModalHeader>
        {this.state.alert ? (
          <UncontrolledAlert
            color={this.state.type}
            className="alert-dismissible fade show"
            role="alert"
          >
            {this.state.type == "warning" ? (
              <i className="mdi mdi-alert-outline mr-2"></i>
            ) : this.state.type == "success" ? (
              <i className="mdi mdi-check-all mr-2"></i>
            ) : this.state.type == "danger" ? (
              <i className="mdi mdi-block-helper mr-2"></i>
            ) : null}
            {this.state.message}
          </UncontrolledAlert>
        ) : null}
        <ModalBody
          scrollable={true}
          style={{ maxHeight: "350px", overflowY: "auto" }}
        >
          <Container>
            <Row>
              <Col sm={{ size: "auto" }} tag="h4">
                {this.props.title}
              </Col>{" "}
              {/* <Col sm={{ size: "auto" }} tag="h4">
                (Total {this.state.userListLiked.length} Likes)
              </Col> */}
            </Row>

            <FormGroup row>
              <Label
                htmlFor="example-text-input"
                className="col-md-2 col-form-label"
              >
                FullName
              </Label>
              <Col md={10}>
                <Input
                  className="form-control"
                  type="text"
                  value={

                    this.state.userdata.firstName + " " + this.state.userdata.lastName

                  }
                  id="example-text-input"
                  readOnly
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label
                htmlFor="example-text-input"
                className="col-md-2 col-form-label"
              >
                Email
              </Label>
              <Col md={10}>
                <Input
                  className="form-control"
                  type="email"
                  value={
                    this.state.userdata.email
                  }
                  id="example-text-input"
                  readOnly
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label
                htmlFor="example-text-input"
                className="col-md-2 col-form-label"
              >
                Day of Booking
              </Label>
              <Col md={10}>
                <Input
                  className="form-control"
                  type="text"
                  value={
                    this.state.data ?
                      this.state.data.day_name_of_booking
                      : ""
                  }
                  id="example-text-input"
                  readOnly
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label
                htmlFor="example-text-input"
                className="col-md-2 col-form-label"
              >
                Date of Booking
              </Label>
              <Col md={10}>
                <Input
                  className="form-control"
                  type="text"
                  value={
                    moment(this.state.data ?
                      this.state.data.date_of_booking
                      : "").format("DD-MM-YYYY")

                  }
                  id="example-text-input"
                  readOnly
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label
                htmlFor="example-text-input"
                className="col-md-2 col-form-label"
              >
                Category Name
              </Label>
              <Col md={10}>
                <Input
                  className="form-control"
                  type="text"
                  value={
                    this.state.servicedata ? this.state.servicedata.category_name : ""
                  }
                  id="example-text-input"
                  readOnly
                />
              </Col>
            </FormGroup>


            <FormGroup row>
              <Label
                htmlFor="example-text-input"
                className="col-md-2 col-form-label"
              >
                Service Name
              </Label>
              <Col md={10}>
                <Input
                  className="form-control"
                  type="text"
                  value={
                    this.state.servicedata ? this.state.servicedata.name : ""
                  }
                  id="example-text-input"
                  readOnly
                />
              </Col>
            </FormGroup>





          </Container>
        </ModalBody>

        <ModalFooter>
          {/* <Button
            color="primary"
            onClick={() => {
              this.ChangePassword();
            }}
          >
            Change Password
          </Button> */}
          <Button color="secondary" onClick={() => this.props.closeCallback()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalPendingBooking;