import React, { useState, useEffect, useRef } from "react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";
//import ReactJsAlert from "reactjs-alert";
import "./chatlist.css";
//import Firebase from "../../firebase-messaging-sw";
import db from "../../Firebase/fbconfig";
import { getDatabase, ref, push, set, onValue, get, onChildAdded } from "firebase/database";
import { Row, Card, CardBody, Col, Input, Button } from "reactstrap";
import SimpleBar from "simplebar-react";
import img from "./pro.jpeg";
import img1 from "./mp4.jpeg";
import moment from "moment";


const Refund = (props) => {
    console.log("userid", props.orderdetails);
    const messagesEndRef = useRef(null)
    const [modalStatus, setmodalStatus] = useState(props.status ? props.status : false);
    const [msglist, setmsglist] = useState([]);
    const [selectedFiles, setselectedFiles] = useState([]);
    const [message, setmessage] = useState("");
    const [firstFile, setfirstFile] = useState("");
    const [filetype, setfiletype] = useState("");
    useEffect(() => {
        FetchMessageList();
        // getAccount();
        scrollToBottom();

        // Callrefund();

    }, []);


    // const getAccount = async () => {
    //     let getAccount = await reactLocalStorage.getObject("userData");
    //     console.log(getAccount);
    //     if (
    //         getAccount &&
    //         getAccount != null &&
    //         Object.keys(getAccount).length > 0
    //     ) {

    //         FetchMessageList(props.orderdetails._id);
    //     }
    // };




    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }





    const SendMsg = async (url) => {


        console.log("hiii");
        const dbRef = getDatabase();

        let date = Date.now();
        let date1 = moment(date).format("YYYY-MM-DD");
        let date2 = moment(date).format("HH:mm:ssZ");
        let msgTime = date1 + "T" + date2;
        const starCountRef = ref(dbRef, 'PersonelSellerchat/' + props.orderid + '/msglist');
        onValue(starCountRef, (snapshot) => {
            if (snapshot.exists()) {
                //notification for updating user count & also updating who is doing the last msg
                // dbRef
                //     .child("chats")
                //     .child(user_id)
                //     .update({ userCount: this.state.userCount + 1, lastMsgBy: "User" });
                //...........>>>>>>>>>>>>>>>>>>>

                let msgid = push(starCountRef).key;


                //Use the Push() method to append data to a list in multiuser applications.
                // The Push() method generates a unique key every time
                console.log("new key", msgid);
                set(ref(dbRef, 'PersonelSellerchat/' + props.orderid + '/msglist/' + msgid), {
                    msgid: msgid,
                    msgby: "user",
                    send_time: msgTime,
                    msgtext: message,
                    msgimg:
                        filetype == "image/jpeg" ||
                            filetype == "image/png"
                            ? url
                            : "",
                    msgvideo: filetype == "video/mp4" ? url : "",
                },

                    (error) => {
                        if (error) {
                            alert("something went wrong");
                        } else {
                            console.log("127");


                        }
                    }
                );
            } else {
                set(ref(dbRef, 'PersonelSellerchat/' + props.orderid), {
                    order_id: props.orderid,
                    // user_id: user_id,
                    // userCount: this.state.userCount + 1, //notification
                    // adminCount: 0, //notification
                    lastMsgBy: "admin",
                    msglist: "",
                    username: "",

                    useremail: "",
                });
                let msgid = push(starCountRef).key;
                console.log("msgid", msgid);
                set(ref(dbRef, 'PersonelSellerchat/' + props.orderid + '/msglist' + '/' + msgid), {
                    msgid: msgid,
                    msgby: "user",
                    send_time: msgTime,
                    msgtext: message,
                    msgimg:
                        filetype == "image/jpeg" ||
                            filetype == "image/png"
                            ? url
                            : "",
                    msgvideo: filetype == "video/mp4" ? url : "",
                },

                    (error) => {
                        if (error) {
                            alert("something went wrong");
                        } else {
                            console.log("object");


                        }
                    }
                );
            }

        },
            {
                onlyOnce: true
            }
        )
        setmessage("");
        FetchMessageList();


    };

    const FetchMessageList = async (id) => {

        const dbRef = getDatabase();

        let arr = [];
        const starCountRef = ref(dbRef, 'PersonelSellerchat/' + props.orderid + '/msglist');
        onValue(starCountRef, (snapshot) => {
            const data = snapshot;

            console.log("data", data);
            if (snapshot.exists()) {
                snapshot.forEach((ele) => {
                    console.log("ele", ele.val());
                    let dom = {
                        id: ele.val().msgid,
                        msg: ele.val().msgtext,
                        msgby: ele.val().msgby,
                        msgimg: ele.val().msgimg,
                        msgvideo: ele.val().msgvideo,
                        msgtime: ele.val().send_time,
                    };
                    console.log(dom);
                    arr.push(dom);
                    console.log(arr);
                });

                setmsglist(arr);

                console.log(msglist);
            }
            // console.log("arr", arr);
            // setmsglist(arr);
            // console.log("jkk", msglist);
        });

        // //For checking the notification last count for both the user & admin
        // dbRef
        //     .child("chats")
        //     .child(id)
        //     .get()
        //     .then((snapshot) => {
        //         if (snapshot.exists()) {
        //             console.log(snapshot.val().userCount);
        //             this.state.userCount = snapshot.val().userCount;
        //             this.state.adminCount = snapshot.val().adminCount;
        //             this.setState({});
        //             console.log("Count", this.state.userCount);
        //             dbRef.child("chats").child(id).update({ adminCount: 0 });
        //         }
        //     });
        // //....>>>>>>>>>>>>>>>>>>>

        // //Updating the admin count value to 0 when user see the msg in the modal

        scrollToBottom();
    };

    // const uploadFile = async () => {
    //     // this.setState({
    //     //     videoloading: true,
    //     // });
    //     let data = new FormData();
    //     data.append("image", this.state.firstFile);
    //     let result = await HttpClient.fileUplodeDynamicc(
    //         "image-upload/chat",
    //         "POST",
    //         data
    //     );
    //     console.log(result);
    //     if (result && result.status) {
    //         // this.setState({
    //         //     videoloading: false,
    //         // });
    //         // this.SendMsg(result.url);
    //     }
    // };

    // const handlefilePick = async (event) => {

    //     setfiletype(event.target.files[0].type);

    //     setfirstFile(event.target.files[0]);
    //     setselectedFiles([
    //         {
    //             preview: URL.createObjectURL(event.target.files[0]),
    //             type: event.target.files[0].type,
    //         },
    //     ]);


    // };


    return (
        <div
            className={modalStatus ? "modal show fade" : "modal fade"}
            id="add-new-task-modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="add-new-task-modalLabel"
            aria-hidden="true"
            style={{ display: modalStatus ? "block" : "none" }}
        >
            <div className="modal-dialog modal-dialog-centered replyBox modal-lg">
                <div className="modal-content">
                    <Card className="mb-0">
                        {/* chat head area */}
                        <CardBody className="border-bottom chat_head py-2">
                            <div className="user-chat-border">
                                <Row>
                                    <Col md={9} xs={9}>
                                        <div className="d-flex">
                                            <div className="my-auto mr-3">
                                                <img
                                                    src={img}
                                                    className="img-fluid rounded-circle"
                                                    style={{ height: "30px", width: "30px" }}
                                                />
                                            </div>
                                            <h5 className="font-size-15 my-auto mr-auto">{props.sellerdetails.name}</h5>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-hidden="true"
                                            onClick={() =>
                                                props.onCloseCallBack1(false)
                                            }
                                        >
                                            ×
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                        {/* chat list body */}
                        <CardBody>
                            <div className="chat-widget">
                                <div className="chat-conversation">
                                    <SimpleBar style={{ maxHeight: "250px" }}>
                                        <ul className="list-unstyled mb-0 pr-3">
                                            {msglist.length > 0 &&
                                                msglist.map((item, index) => {
                                                    console.log(item);
                                                    return (
                                                        <li
                                                            className={
                                                                item.msgby === "admin" ? "" : "right"
                                                            }
                                                            key={index}
                                                        >
                                                            {item.msg != "" && item.msgimg != "" ? (
                                                                <div className="conversation-list">
                                                                    <div className="ctext-wrap">
                                                                        <div className="ctext-wrap-content py-1 px-2">
                                                                            <p className="mb-2">{item.msg}</p>

                                                                            <img
                                                                                data-dz-thumbnail
                                                                                src={item.msgimg}
                                                                                className="avatar-md rounded bg-light"
                                                                                alt="preview"
                                                                            />
                                                                            <p className="mb-2">
                                                                                {moment(item.msgtime).fromNow()}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : item.msg != "" && item.msgvideo != "" ? (
                                                                <div className="conversation-list">
                                                                    <div className="ctext-wrap">
                                                                        <div className="ctext-wrap-content py-1 px-2">
                                                                            <p className="mb-2">{item.msg}</p>

                                                                            <video
                                                                                // width="50%"
                                                                                hight={300}
                                                                                width={300}
                                                                                controls
                                                                            // className="rounded-lg"
                                                                            >
                                                                                <source
                                                                                    src={item.msgvideo}
                                                                                    type="video/mp4"
                                                                                />
                                                                            </video>
                                                                            <p className="mb-2">
                                                                                {moment(item.msgtime).fromNow()}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : item.msg == "" && item.msgimg != "" ? (
                                                                <div className="conversation-list">
                                                                    <div className="ctext-wrap">
                                                                        <div className="ctext-wrap-content py-1 px-2">
                                                                            <img
                                                                                data-dz-thumbnail
                                                                                src={item.msgimg}
                                                                                className="avatar-md rounded bg-light"
                                                                                alt="preview"
                                                                            />
                                                                            <p className="mb-2">
                                                                                {moment(item.msgtime).fromNow()}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : item.msg == "" && item.msgvideo != "" ? (
                                                                <div className="conversation-list">
                                                                    <div className="ctext-wrap">
                                                                        <div className="ctext-wrap-content py-1 px-2">
                                                                            <video
                                                                                // width="50%"
                                                                                controls
                                                                                hight={300}
                                                                                width={300}
                                                                                className="rounded-lg"
                                                                            >
                                                                                <source
                                                                                    src={item.msgvideo}
                                                                                    type="video/mp4"
                                                                                />
                                                                            </video>
                                                                            <p className="mb-2">
                                                                                {moment(item.msgtime).fromNow()}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="conversation-list">
                                                                    <div className="ctext-wrap">
                                                                        <div className="ctext-wrap-content py-1 px-2">
                                                                            <p className="mb-2">{item.msg}</p>
                                                                            <p className="mb-2">
                                                                                {moment(item.msgtime).fromNow()}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </li>
                                                    );
                                                })}
                                            <li ref={messagesEndRef}></li>
                                        </ul>
                                    </SimpleBar>
                                </div>
                            </div>
                        </CardBody>
                        {/* chat write area */}
                        <div className="px-3 py-2 chat-input-section border-top">
                            <Row>
                                {/* upload image here */}
                                <Col md={12}>
                                    <div className="mb-2">
                                        {selectedFiles.length > 0 &&
                                            selectedFiles.map((item, index) => {
                                                return (
                                                    <div className="upld_img">
                                                        {item.type == "image/jpeg" ? (
                                                            <img
                                                                data-dz-thumbnail
                                                                src={item.preview}
                                                                className="avatar-sm rounded bg-light"
                                                                alt="preview"
                                                            />
                                                        ) : (
                                                            <img
                                                                data-dz-thumbnail
                                                                src={img1}
                                                                className="avatar-sm rounded bg-light"
                                                                alt="preview"
                                                            />
                                                        )}

                                                        <button
                                                            className="close border-0 bg-transparent p-0 img_remove"
                                                            onClick={() => {
                                                                this.setState({
                                                                    firstFile: "",
                                                                    selectedFiles: [],
                                                                });
                                                            }}
                                                        >
                                                            <i className="far fa-times-circle" />
                                                        </button>
                                                    </div>
                                                );
                                            })}
                                        {/* {this.state.videoloading?
                        <img
                        src="https://tenor.com/view/loading-waiting-please-wait-gif-14579561"
                        />:null} */}
                                    </div>
                                </Col>
                                {/* choose image */}
                                {/* <Col md={1}>
                                    <label htmlFor="fileUpload" className="my_fileUpload">
                                        <i className="far fa-file-image"></i>
                                        <input
                                            type="file"
                                            id="fileUpload"
                                            className="fileUpload"
                                            onChange={handlefilePick}
                                        />
                                    </label>
                                </Col> */}
                                {/* write message */}
                                <Col md={9} className="px-lg-0">
                                    <div>
                                        <Input
                                            type="text"
                                            className="form-control rounded chat-input pl-3"
                                            placeholder="Enter Message..."
                                            onChange={(val) =>

                                                setmessage(val.target.value)

                                            }
                                            value={message}
                                        />
                                    </div>
                                </Col>
                                {message != "" && firstFile != "" ? (
                                    <Col md={2} xs={{ size: "auto" }}>
                                        <Button
                                            color="primary"
                                            type="button"
                                            // onClick={uploadFile}
                                            className="chat-send waves-effect waves-light font-size-12 w-100 btn-sm px-0"
                                        >
                                            <span className="d-none d-sm-inline-block mr-1">
                                                Send
                                            </span>{" "}
                                            <i className="fas fa-paper-plane"></i>
                                        </Button>
                                    </Col>
                                ) : message == "" && firstFile != "" ? (
                                    <Col md={2} xs={{ size: "auto" }}>
                                        <Button
                                            color="primary"
                                            type="button"
                                            // onClick={uploadFile}
                                            className="chat-send waves-effect waves-light font-size-12 w-100 btn-sm px-0"
                                        >
                                            <span className="d-none d-sm-inline-block mr-1">
                                                Send
                                            </span>{" "}
                                            <i className="fas fa-paper-plane"></i>
                                        </Button>
                                    </Col>
                                ) : message != "" && firstFile == "" ? (
                                    <Col md={2} xs={{ size: "auto" }}>
                                        <Button
                                            color="primary"
                                            type="button"
                                            onClick={() =>
                                                SendMsg("")
                                            }
                                            className="chat-send waves-effect waves-light font-size-12 w-100 btn-sm px-0"
                                        >
                                            <span className="d-none d-sm-inline-block mr-1">
                                                Send
                                            </span>{" "}
                                            <i className="fas fa-paper-plane"></i>
                                        </Button>
                                    </Col>
                                ) : (
                                    <Col md={2} xs={{ size: "auto" }}>
                                        <Button
                                            color="primary"
                                            type="button"
                                            onClick={() => {
                                                alert("please enter message");
                                            }}
                                            className="chat-send waves-effect waves-light font-size-12 w-100 btn-sm px-0"
                                        >
                                            <span className="d-none d-sm-inline-block mr-1">
                                                Send
                                            </span>{" "}
                                            <i className="fas fa-paper-plane"></i>
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );

}
export default Refund;
