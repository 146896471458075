import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import SimpleBar from "simplebar-react";

// import { useAuthState } from 'react-firebase-hooks/auth';
// import { useCollectionData } from 'react-firebase-hooks/firestore';
import Firebase from "./Chat/firebase";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { CSVLink } from "react-csv";
import db from "./Chat/fbconfig";

import { v4 as uuidv4 } from "uuid";

import {
  ref,
  push,
  set,
  child,
  update,
  get,
  limitToLast,
  query,
  onChildAdded,
  onChildChanged,
  off,
} from "firebase/database";

import { Link } from "react-router-dom";
const userData = reactLocalStorage.getObject("userData");

const header = [
  { label: "Name", key: "username" },
  { label: "Email", key: "email" },
  { label: "Phone", key: "phone" },
  { label: "About", key: "about" },
  { label: "Subscription Status", key: "subscription_status" },
  { label: "Subscription Plan", key: "subscription_plan" },
];

const auth = firebase.auth();
const firestore = firebase.firestore();
const messagesRef = firestore.collection("messages");
const msgvalid = (txt) => txt && txt.replace(/\s/g, "").length;
const chatRef = React.createRef(null);
const chatUpdateRef = React.createRef(null);

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Seller List", link: "#" },
        { title: "View SellerList", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},

      image_select: false,
      img_url: "",
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      type: "",
      desc: "",

      params: {},

      showPopup: false,
      userDet: "",
      message: "",
      msglist: [],
      selectedFiles: [],
      filetype: "",
      firstFile: "",
      roomId: "",
      userData: "",
      sellerdetails: "",
      msgarr: [],
    };
    this.handleDefault = this.handleDefault.bind(this);
    this.messagesEndRef = React.createRef();
  }

  componentDidMount = async () => {
    this.checkUser();
    this.fetchData();
    off(
      ref(db, `/Chat/${this.state.roomId}/messages/`),
      undefined,
      chatRef.current
    );
    off(
      ref(db, `/Chat/${this.state.roomId}/messages/`),
      undefined,
      chatUpdateRef.current
    );
    this.scrollToBottom();
  };

  scrollToBottom = () => {
    //  const { messageList } = React.createRef()
    // this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    // const objDiv = document.getElementById("scrl");
    // console.log("ref",objDiv);
    // objDiv.scrollTop = objDiv?.offsetHeight;
    // this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    // messageList.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }

  fetchData = async () => {
    let result = await HttpClient.requestData("sellerlist", "GET");
    console.log("Seller", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      // console.log(this.state.pointarr);
      this.state.result.forEach((element, index) => {
        console.log("element==", element);
        let rows = {
          sl: i,
          username: element.firstName + " " + element.lastName,
          email: element.email,
          phone: element.mobile ? element.mobile : "",
          about: element.about ? element.about : "",
          //   seller_comission: element.seller_comission,
          // points: Array.prototype.map.call(element.points, (s) => s).toString(),
          provider: (
            <button
              title={element.top_seller ? "Provider" : "Set As Provider"}
              className={
                element.top_seller
                  ? "btn btn-danger mr-2"
                  : "btn btn-success mr-2"
              }
              onClick={() => {
                this.Provider(element, index);
              }}
            >
              {element.top_seller ? "Provider" : "Set As Provider"}
            </button>
          ),
          subscription_status: Object.keys(element.seller_subscription)
            .length ? (
            <Button className="btn-icon" color="success">
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-wink-outline mr-2"></i>
              </span>{" "}
              Active
            </Button>
          ) : (
            <Button className="btn-icon" color="danger">
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-wink-outline mr-2"></i>
              </span>{" "}
              Deactive
            </Button>
          ),
          subscription_plan: Object.keys(element.seller_subscription).length
            ? element.seller_subscription.subcription_data.name
            : "",
          seller_service: (
            <Link
              to={{
                pathname: "/seller-services/" + element._id,
                data: element, // your data array of objects
              }}
            >
              <Button
                className="btn-icon"
                color="primary"
                // onClick={(e) => this.status(index, element._id)}
              >
                {" "}
                <span className="btn-icon-label">
                  {/* <i className="mdi mdi-emoticon-wink-outline mr-2"></i> */}
                </span>{" "}
                Seller Services
              </Button>
            </Link>
          ),
          chat: (
            <button
              title="Chat"
              className="btn btn-danger mr-2"
              onClick={() => {
                this.chat(element, index);
              }}
            >
              <i className="fas fa-comment-dots" />
            </button>
          ),
          //   action: (
          //     <>
          //       {/* <button
          //         title="Delete"
          //         className="btn btn-danger mr-2"
          //         onClick={() => {
          //           this.delete(element, index);
          //         }}
          //       >
          //         <i className="fa fa-trash" />
          //       </button>{" "} */}
          //       <button
          //         title="Edit"
          //         className="btn btn-primary"
          //         onClick={() => {
          //           this.edit(element, index);
          //         }}
          //       >
          //         <i className="fa fa-edit" />
          //       </button>
          //     </>
          //   ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    console.log(sendData);
    let result = await HttpClient.requestData(
      "admin/ecom_banner/delete",
      "POST",
      sendData
    );
    console.log(result);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Succfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      title: item.name,
      price: item.price,
      edit_item_id: item._id,
      seller_comission: item.seller_comission,
      duration: item.duration,
      id: item.id,
      type: item.type,
    });
  };
  Provider = async (item, index) => {
    console.log("item._id", item);
    // return false
    let data = {
      top_seller : !item.top_seller
    }
    let result = await HttpClient.requestData("top-sellers/" + item._id, "PUT",data);
    if (result && result.status) {
      if(result.data.top_seller)
      {
        alert('Set As provider Successfully')
      }
      else
      {
        alert('Not a provider Successfully')
      }
      
      this.setState({
        alert: true,
        message: "Set As provider Successfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };
  submit = async () => {
    if (
      this.state.title != "" &&
      this.state.price != "" &&
      this.state.duration != "" &&
      this.state.seller_comission != "" &&
      this.state.type != ""
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData("subscription", "POST", data);
      } else {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData(
          "subscription/" + this.state.edit_item_id,
          "PUT",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Subscription Updated Successfully"
            : "Subscription Added Succfully",

          type: "success",

          image_select: false,
          img_url: "",
          title: "",
          type: "",
          seller_comission: "",
          duration: "",
          price: "",
          desc: "",
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (e) => {
    let file = e.target.files[0].type;
    let fileType = file.split("/")[0];
    // fileType = fileType[0];
    console.log("e", file.split("/")[0]);

    if (fileType == "image" || fileType == "video") {
      // return false;
      this.setState({ fileType: fileType });
      let dataSend = { buckate: true, buckate_name: "appIcon" };

      let result = await HttpClient.newFileUpload(
        "image-upload/movie",
        e.target.files[0],
        dataSend
      );
      console.log("result", result);
      if (result && result.status) {
        this.setState({ img_url: result.url, image_select: true });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };
  componentDidUpdate() {
    this.scrollToBottom();
  }

  getchat = async (roomId) => {
    const dbRef = ref(db);
    console.log("shiuvroomid", this.state.roomId);
    // console.log("/Chat/" + props.roomId + "/messages/");
    // child(dbRef, "/Chat/" + props.roomId + "/messages/", limitToLast(10))
    get(query(ref(db, `/Chat/${roomId}/messages/`)))
      .then((snapshot) => {
        if (snapshot.exists()) {
          // console.log(snapshot.val());
          // setisloading(false);
          // sethasMore(true);
          // setmsgarr(Object.values(snapshot.val()));
          this.setState({ msgarr: Object.values(snapshot.val()) });
          this.getFirebaseListener(Object.values(snapshot.val()));
          console.log(this.state.msgarr, "mmmmm");
          this.scrollToBottom();
        } else {
          // sethasMore(false);
          // setmsgarr([]);
          this.setState({ msgarr: [] });
          this.getFirebaseListener([]);
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getFirebaseListener = (oldVal) => {
    let mssg = oldVal;
    const dbRef = ref(db, "/Chat/" + this.state.roomId + "/messages/");

    const dbquery = query(dbRef, limitToLast(1));

    chatRef.current = onChildAdded(dbquery, (snapshot) => {
      if (snapshot.exists()) {
        let fbData = snapshot.val();
        let msgIndex = mssg.findIndex((it) => it.msgId == fbData.msgId);
        if (msgIndex == -1) {
          // setmsgarr((msgarr) => [...msgarr, fbData]);
          let msgarr = [];
          msgarr = [...msgarr, fbData];
          this.setState({ msgarr: msgarr });
          mssg = [...mssg, fbData];
          const updates = {};
          updates[
            `/chatList/${userData._id}/${this.state.sellerdetails._id}/unseen`
          ] = false;
          update(ref(db), updates);
        }
      }
    });

    chatUpdateRef.current = onChildChanged(
      ref(db, `/Chat/${this.state.roomId}/messages/`),
      (snapshot) => {
        if (snapshot.exists()) {
          let fbData = snapshot.val();
          if (!fbData.uploading) {
            let index = mssg.findIndex((it) => it.msgId == fbData.msgId);
            if (index >= 0) {
              mssg[index] = fbData;
              // setmsgarr(mssg)
              this.setState({ msgarr: mssg });
              //console.log("arrayyyyyyyyyyyyyyyyyyy", mssg)
            }
          }
        }
      }
    );
  };

  SendMsg = async () => {
    if (this.state.message == "" || msgvalid(this.state.message) === 0) {
      return false;
    }
    // let roomId = "123456789";
    console.log("this.state.roomId", this.state.roomId);
    const postListRef = ref(db, "/Chat/" + this.state.roomId + "/messages/");
    const newPostRef = push(postListRef);

    set(newPostRef, {
      roomId: this.state.roomId,
      msgId: uuidv4(),
      sender_id: userData._id,
      message: this.state.message,
      send_time: moment().format(),
      name: userData.admin_type + " " + userData.admin_type,
      nodeId: newPostRef.key,
    });
    this.getchat(this.state.roomId);
    let postData = {
      lastMsg: this.state.message,
      lastMsgTime: moment().format(),
      name:
        this.state.sellerdetails.firstName +
        " " +
        this.state.sellerdetails.lastName,
      displayName:
        this.state.sellerdetails.firstName +
        " " +
        this.state.sellerdetails.lastName,
      userId: this.state.sellerdetails._id,
      roomId: this.state.roomId,
    };

    let postDataa = {
      lastMsg: this.state.message,
      lastMsgTime: moment().format(),
      name: userData.admin_type + " " + userData.admin_type,
      displayName: userData.admin_type + " " + userData.admin_type,
      userId: userData._id,
      roomId: this.state.roomId,
      unseen: true,
    };

    const updates = {};
    console.log("senduser", this.state.sellerdetails);

    updates[`/chatList/${userData._id}/${this.state.sellerdetails._id}`] =
      postData;
    updates[`/chatList/${this.state.sellerdetails._id}/${userData._id}`] =
      postDataa;
    update(ref(db), updates);
    this.scrollToBottom();
    this.sendnotification(this.state.message);
    this.setState({ message: "" });
    // setmsg("");
    // setshowemoji(false);
    // props.refresh(true);
    setTimeout(() => {
      this.scrollIntoView();
    }, 1000);
  };

  sendnotification = async (msg) => {
    this.scrollToBottom();
    let token = "";
    const dbRef = ref(db);
    // console.log("/Chat/" + props.roomId + "/messages/");
    get(child(dbRef, "/user/" + userData._id))
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.sendtoken(snapshot.val().fcmToken);
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  scrollToBottom = () => {
    //  const { messageList } = React.createRef()
    // this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    // const objDiv = document.getElementById("scrl");
    // console.log("ref",objDiv);
    // objDiv.scrollTop = objDiv?.offsetHeight;
    // this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    // messageList.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
  };

  sendtoken = async (token) => {
    console.log("token", token);
    const FIREBASE_API_KEY =
      "AAAAkKuhlBw:APA91bHuUxvnxQR6VxdLpxkBsgyZjapd4ekX-eFV4EhKGjR0lFoALfCbGsg_U1IW1-jOuLd3vjYTWm2EaCUi64pQNrxirTCsV23IzgcXki3a3yROBY8tFPsWJ_w1sc_zmCNc8L8N5nO2";
    // data.notiType = 'Call';
    const message = {
      registration_ids: [token],
      priority: "high",
      // data: { ...data, notiType: 'Call', callDataType: 'start' },
      notification: {
        title: userData.firstName + " " + userData.lastName,
        body: "New message arrived",
        vibrate: 1,
        sound: 1,
        show_in_foreground: true,
        priority: "high",
        content_available: true,
      },
    };

    let headers = new Headers({
      "content-type": "application/json",
      Authorization: "key=" + FIREBASE_API_KEY,
    });
    let response = await fetch("https://fcm.googleapis.com/fcm/send", {
      method: "POST",
      headers,
      body: JSON.stringify(message),
    });
    response = await response.json();
    console.log("response55555", response, message);
  };
  uploadFile = async (event) => {
    let media = event.target.files[0];
    let data = new FormData();
    data.append("image", media);
    let result = await HttpClient.fileUplode(
      "associate/image-upload",
      "POST",
      data
    );
    // console.log("result", result);
    if (result && result.status) {
      // console.log("result", result);
      let imageurl = HttpClient.IMG_URL + result.data;
      console.log(imageurl, "imageurl");
      const postListRef = ref(db, "/Chat/" + this.state.roomId + "/messages/");
      const newPostRef = push(postListRef);
      set(newPostRef, {
        roomId: this.state.roomId,
        msgId: uuidv4(),
        sender_id: userData._id,
        message: imageurl,
        send_time: moment().format(),
        name: userData.admin_type + " " + userData.admin_type,
        image: true,
      });
      this.getchat(this.state.roomId);
      let postData = {
        lastMsg: imageurl,
        lastMsgTime: moment().format(),
        name:
          this.state.sellerdetails.firstName +
          " " +
          this.state.sellerdetails.lastName,
        displayName:
          this.state.sellerdetails.firstName +
          " " +
          this.state.sellerdetails.lastName,
        userId: this.state.sellerdetails._id,
        roomId: this.state.roomId,
        lastMsgtype: "image",
      };

      let postDataa = {
        lastMsg: imageurl,
        lastMsgTime: moment().format(),
        name: userData.admin_type,
        displayName: userData.admin_type + " " + userData.admin_type,
        userId: userData._id,
        roomId: this.state.roomId,
        lastMsgtype: "image",
      };
      const updates = {};
      console.log("senduser", this.state.sellerdetails);
      updates[`/chatList/${userData._id}/${this.state.sellerdetails._id}`] =
        postData;
      updates[`/chatList/${this.state.sellerdetails._id}/${userData._id}`] =
        postDataa;
      update(ref(db), updates);
      //   setmsg("");
      this.setState({ message: "" });
    } else {
    }
  };

  handlefilePick = async (event) => {
    let data = [];
    let file = {
      preview: URL.createObjectURL(event.target.files[0]),
    };
    this.setState({
      filetype: event.target.files[0].type,
      //firstFile: URL.createObjectURL(event.target.files[0]),
      firstFile: event.target.files[0],
      selectedFiles: [
        {
          preview: URL.createObjectURL(event.target.files[0]),
          type: event.target.files[0].type,
        },
      ],
    });
    console.log("selected files", this.state.selectedFiles);
    setTimeout(() => {
      console.log("selected files", this.state.firstFile);
    }, 3000);
  };

  chat = async (item, index) => {
    console.log("chat",item)
    this.setState({ msgarr: [] });
    console.log(item, "453");
    if (item._id != "") {
      this.setState({
        showPopup: true,
        sellerdetails: item,
        userDet: item.firstName + " " + item.lastName,
      });

      // console.log("this.state.msgarr", this.state.msgarr);
      // console.log("this.state.roomId", this.state.roomId);
      const dbRef = ref(db);
      get(
        child(
          dbRef,
          // `/chatList/${userData._id}/${data.shop_details.user_data._id}`
          `/chatList/${userData._id}/${item._id}`
        )
      )
        .then((snapshot) => {
          if (snapshot.exists()) {
            // setnewchat(item);
            // setnochatopen(true);
            console.log("snap", snapshot.val());
            // setroomId(snapshot.val().roomId);
            this.setState({ roomId: snapshot.val().roomId });
            this.getchat(snapshot.val().roomId);
            // setTimeout(() => {
            //     window.location.href = "/messages";
            // }, 100);
          } else {
            // setnewchat(item);
            // setnochatopen(true);
            let roomId = uuidv4();
            console.log("No data available");
            // console.log(`/chatList/${userData._id}/${item._id}`);
            set(ref(db, `/chatList/${userData._id}/${item._id}`), {
              name: item.firstName + " " + item.lastName,
              displayName: item.firstName,
              userId: item._id,
              roomId: roomId,
            });
            set(ref(db, `/chatList/${item._id}/${userData._id}`), {
              name: userData.admin_type + " " + userData.admin_type,
              displayName: userData.admin_type,
              userId: userData._id,
              roomId: roomId,
            });

            // setroomId(roomId);
            this.setState({ roomId: roomId });
            // setTimeout(() => {
            //     window.location.href = "/messages";
            // }, 100);
          }

          this.scrollToBottom();
          console.log("chat");
        })
        .catch((error) => {
          console.error(error);
        });

      console.log("ref", this.messagesEndRef.current);
    } else {
      this.setState({ showPopup: false });
    }

    setTimeout(() => {
      this.scrollIntoView();
    }, 1000);
  };

  scrollIntoView = () => {
    if (document.getElementById("myscroll")) {
      document.getElementById("myscroll").scrollIntoView();
    }
  };
  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        // {
        //   label: "Image",
        //   field: "img",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "User Name",
          field: "username",
          sort: "asc",
          width: 270,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 270,
        },
        {
          label: "Phone",
          field: "phone",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Type",
        //   field: "type",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "About",
          field: "about",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Seller Comission",
        //   field: "seller_comission",
        //   sort: "asc",
        //   width: 270,
        // },
        // {
        //   label: "Points",
        //   field: "points",
        //   sort: "asc",
        //   width: 270,
        // },

        {
          label: "Status",
          field: "provider",
          sort: "asc",
          width: 100,
        },
        {
          label: "Subscription Status",
          field: "subscription_status",
          sort: "asc",
          width: 170,
        },
        {
          label: "Subscription Plan",
          field: "subscription_plan",
          sort: "asc",
          width: 170,
        },
        {
          label: "Seller Services",
          field: "seller_service",
          sort: "asc",
          width: 170,
        },
        {
          label: "Chat With User",
          field: "chat",
          sort: "asc",
          width: 100,
        },

        // {
        //   label: "Action",
        //   field: "action",
        //   sort: "asc",
        //   width: 100,
        // },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {/* {this.state.alert ? (
              <UncontrolledAlert
                color={this.state.type}
                className="alert-dismissible fade show"
                role="alert"
              >
                {this.state.type == "warning" ? (
                  <i className="mdi mdi-alert-outline mr-2"></i>
                ) : this.state.type == "success" ? (
                  <i className="mdi mdi-check-all mr-2"></i>
                ) : this.state.type == "danger" ? (
                  <i className="mdi mdi-block-helper mr-2"></i>
                ) : null}
                {this.state.message}
              </UncontrolledAlert>
            ) : null} */}

            {this.state.showPopup ? (
              <div
                className="modal fade show popBox"
                id="popBox"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="popBoxLabel"
                aria-hidden="true"
                style={{ display: this.state.showPopup ? "block" : "none" }}
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      // onClick={() => setshowPopup(false)}
                      onClick={(val) => {
                        this.setState({
                          showPopup: false,
                          // image_select: false,
                          // img_url: "",
                          firstFile: "",
                          selectedFiles: [],
                        });
                      }}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "2px",
                        zIndex: "1",
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="modal-body mt-2">
                      <div>
                        <h5>
                          <i class="fa fa-user-circle" aria-hidden="true"></i>{" "}
                          {this.state.userDet}
                        </h5>
                      </div>
                      {/* chat list body */}
                      <hr />
                      <CardBody>
                        <div className="chat-widget">
                          <div ref="messageList" className="chat-conversation">
                            <SimpleBar
                              id="scrl"
                              ref="messageList"
                              style={{ maxHeight: "250px" }}
                            >
                              <ul
                                ref="messageList"
                                className="list-unstyled mb-0 px-3"
                              >
                                {this.state.msgarr.length > 0 &&
                                  this.state.msgarr.map((item, index) => {
                                    // console.log("item", item);
                                    // console.log("item1", this.state.userData._id);
                                    return (
                                      <>
                                        {item.type == "call" ? (
                                          <li className="log_time">
                                            {moment(item.callTime).format(
                                              "hh:mm A"
                                            )}
                                            <br />
                                            <span>
                                              {item.callStatus == "Recieved"
                                                ? `Call ${item.callDuration}`
                                                : item.sender_id ==
                                                  this.state.userData._id
                                                ? "Not Connected"
                                                : "Missed Call"}
                                            </span>
                                            <br />
                                            <span>
                                              {moment(item.callTime).format(
                                                "DD MMM, YYYY"
                                              )}
                                            </span>
                                          </li>
                                        ) : (
                                          <li
                                            className={
                                              item.sender_id ==
                                              this.state.userData._id
                                                ? "receiver"
                                                : "sender"
                                            }
                                            key={index}
                                          >
                                            {item.message != "" &&
                                            item.image == "" ? (
                                              <div className="conversation-list">
                                                <div className="ctext-wrap">
                                                  <div className="ctext-wrap-content py-1 px-2">
                                                    <p className="mb-0">
                                                      {item.message}
                                                    </p>
                                                    {/* <span className="mb-0 small text-muted"> */}
                                                    <span className="mb-0 small">
                                                      {moment(
                                                        item.send_time
                                                      ).fromNow()}
                                                    </span>
                                                    {/* if image exist */}
                                                  </div>
                                                </div>
                                              </div>
                                            ) : item.message != "" &&
                                              item.image == true ? (
                                              <div className="conversation-list">
                                                <div className="ctext-wrap">
                                                  <div className="ctext-wrap-content py-1 px-2">
                                                    {/* <p className="mb-0">{item.message}</p> */}
                                                    {/* <a
                                                                                                                        href={HttpClient.IMG_URL +
                                                                                                                            item.message}
                                                                                                                        target="_blank"
                                                                                                                    > */}
                                                    <img
                                                      data-dz-thumbnail
                                                      // src={
                                                      //     HttpClient.IMG_URL +
                                                      //     item.message.substring(9)
                                                      // }
                                                      src={item.message}
                                                      height="80px"
                                                      width="80px"
                                                      // className="img-fluid p-2 Imgsended"
                                                      alt="preview"
                                                    />

                                                    {/* </a> */}
                                                    <p className="mb-0 small">
                                                      {moment(
                                                        item.send_time
                                                      ).fromNow()}
                                                    </p>
                                                    {/* if image exist */}
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="conversation-list">
                                                <div className="ctext-wrap">
                                                  <div className="ctext-wrap-content py-1 px-2">
                                                    {/* <a
                                                                                                                        href={HttpClient.IMG_URL +
                                                                                                                            item.image}
                                                                                                                        target="_blank"
                                                                                                                    > */}
                                                    {/* <img
                                                                                                                            data-dz-thumbnail
                                                                                                                            src={
                                                                                                                                HttpClient.IMG_URL +
                                                                                                                                item.image
                                                                                                                            }
                                                                                                                            height="80px"
                                                                                                                            width="80px"
                                                                                                                            // className="img-fluid p-2 Imgsended"
                                                                                                                            alt="preview"
                                                                                                                        /> */}
                                                    <p className="mb-0">
                                                      {item.message}{" "}
                                                    </p>
                                                    {/* </a> */}
                                                    <p className="mb-0 small">
                                                      {moment(
                                                        item.send_time
                                                      ).fromNow()}
                                                    </p>
                                                    {/* if image exist */}
                                                    lll
                                                  </div>
                                                  {/* <div ref={this.messagesEndRef}></div> */}
                                                </div>
                                              </div>
                                            )}
                                          </li>
                                        )}
                                      </>
                                    );
                                  })}
                              </ul>
                              <div id="myscroll"></div>
                            </SimpleBar>
                          </div>
                        </div>
                      </CardBody>
                      {/* chat list body */}
                      <hr />
                      <Row>
                        <Col md={12}>
                          <div>
                            {this.state.image_select ? (
                              <>
                                <div>
                                  <img
                                    src={this.state.img_url}
                                    alt="images"
                                    className="rounded avatar-md card-img"
                                  />
                                </div>
                                <button
                                  onClick={() => {
                                    this.setState({
                                      firstFile: "",
                                      selectedFiles: [],
                                    });
                                  }}
                                >
                                  <i class="fas fa-times"></i>
                                </button>
                              </>
                            ) : null}
                          </div>
                        </Col>
                        {/* choose image */}
                        <Col md={1}>
                          <label htmlFor="fileUpload" className="my_fileUpload">
                            <i className="far fa-file-image"></i>
                            <input
                              type="file"
                              id="fileUpload"
                              className="fileUpload"
                              onChange={(val) => {
                                this.uploadFile(val);
                              }}
                              // onChange={this.handlefilePick}
                            />
                          </label>
                        </Col>
                        {/* write message */}
                        <Col md={9}>
                          <input
                            type="text"
                            className="form-control my-3"
                            placeholder="Enter Message"
                            value={this.state.message}
                            onChange={(e) => {
                              this.setState({ message: e.target.value });
                            }}
                          />
                        </Col>

                        {/* {this.state.message != "" && this.state.firstFile != "" ? (
                                                    <Col md={2}>
                                                        <button
                                                            type="button"
                                                            className="btn btn-success mt-3"
                                                            onClick={(val) => {
                                                                this.uploadFile(val)
                                                            }}
                                                        >
                                                            Send
                                                        </button>
                                                    </Col>
                                                ) : this.state.message == "" && this.state.firstFile != "" ? (
                                                    <Col md={2}>
                                                        <button
                                                            type="button"
                                                            className="btn btn-success mt-3"
                                                            onClick={(val) => {
                                                                this.uploadFile(val)
                                                            }}
                                                        >
                                                            Send
                                                        </button>
                                                    </Col>
                                                ) :  */}

                        {this.state.message != "" &&
                        this.state.firstFile == "" ? (
                          <Col md={2}>
                            <button
                              type="button"
                              className="btn btn-success mt-3"
                              onClick={() => {
                                // if (this.state.roomId != "") {
                                //     this.SendMsgSecondtime(" ");
                                // } else {
                                this.SendMsg(" ");
                                // }
                              }}
                            >
                              Send
                            </button>
                          </Col>
                        ) : (
                          <Col md={2}>
                            <button
                              type="button"
                              className="btn btn-success mt-3"
                              // onClick={() => {
                              //   toast.warning("Please enter a message", {
                              //     position: "bottom-right",
                              //     autoClose: 2000,
                              //     hideProgressBar: false,
                              //     closeOnClick: true,
                              //     pauseOnHover: true,
                              //     draggable: true,
                              //     progress: undefined,
                              //   });
                              // }}
                              disabled={!this.state.message}
                            >
                              Send
                            </button>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <Row>
              <Col xl={12}>
                {this.state.data.length > 0 ? (
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"SellerList.csv"}
                    className="btn btn-success"
                  >
                    Export Report in CSV
                  </CSVLink>
                ) : null}
                <div className="card p-3">
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    disableRetreatAfterSorting={true}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
