import React, { Component } from "react";
import axios from "axios";

import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Button,
    UncontrolledAlert,
    InputGroup,
    InputGroupAddon,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
// import EcomPreview from "./E-ComPreview/index";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import index from "../ChooseDesign";
// import ElementMirror from "@fullcalendar/interaction/dnd/ElementMirror";
import { CSVLink } from "react-csv";

const header = [


    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },

    {
        label: "Report Against", key: "report_against"
    },
    {
        label: "Report Detail", key: "report_detail"
    },
    {
        label: "Url", key: "new_url"
    },
]


class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "All Complaints", link: "#" },
                { title: "Show All Complaints", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            // page states
            name: "",
            email: "",
            mobile: "",
            password: "",
            data: [],
            alert: false,
            message: "",
            type: "",
            result: [],

        };

    }

    componentDidMount = async () => {
        // this.checkUser();
        // this.fetchService();
        this.fetchData();

    };




    fetchData = async () => {
        let token = "";
        let user = reactLocalStorage.getObject("userData");
        if (user && user != null && Object.keys(user).length > 0) {
            token = user.token;
            console.log("token", token);
        }
        let result = await axios.get('https://api.myspiritualconnect.com/v1/admin/complaint', {
            headers: {
                'authorization': token,
                "userType": "Admin",
            }
        }

        ).then((response) => {
            console.log(response.data);
            if (response.data.data.length > 0) {
                let arr = [];
                let i = 1;
                response.data.data.forEach(element => {

                    let dom = {
                        sl: i,
                        name: element.name,
                        email: element.email,
                        phone: element.phone,
                        report_against: element.report_against,
                        report_detail: element.report_detail,
                        new_url: element.url,
                        url:
                            <a
                                href={element.url}
                                target="_blank"
                            >
                                {element.url}
                            </a>,


                    }
                    i++;
                    console.log(dom);
                    arr.push(dom);

                });

                this.setState({
                    result: arr,
                })
                console.log("result", this.state.result);
            }

        })



    };





    render() {
        const data = {
            columns: [
                {
                    label: "Sl.",
                    field: "sl",
                    sort: "asc",
                    width: 150,
                },
                // {
                //     label: "Order Id",
                //     field: "oder_id",
                //     sort: "asc",
                //     width: 270,
                // },

                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Email",
                    field: "email",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Phone",
                    field: "phone",
                    sort: "asc",
                    width: 270,
                },

                {
                    label: "Report Against",
                    field: "report_against",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Report Detail",
                    field: "report_detail",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Url",
                    field: "url",
                    sort: "asc",
                    width: 270,
                },

            ],
            rows: this.state.result,
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.state.breadcrumbItems[0].title}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col xl={12}>
                                {this.state.result.length > 0 ?
                                    <CSVLink
                                        data={this.state.result}
                                        headers={header}
                                        filename={"ComplainList.csv"}
                                        className="btn btn-success">
                                        Export Report in CSV
                                    </CSVLink> : null}
                                <div className="card p-3">



                                    <MDBDataTable responsive bordered data={data} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
