import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

import { confirmAlert } from "react-confirm-alert"; // Import
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import { CSVLink } from "react-csv";
import Dropzone from "react-dropzone";
import HttpClient from "./../../utils/HttpClient";
import validator from 'validator'


class FormElements extends Component {
  fileObj = [];
  fileArray = [];
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Personnel Register", link: "#" },
        { title: "Add & Manage Personnel Register", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      //   this page states
      category: [],
      categoryAll: [],
      subCategory: [],
      subCategoryAll: [],

      cat_id: "",
      pruduct_type: "",
      sub_cat_id: "",

      // selectedFiles: [],
      images: "",
      uploaded_images: [],

      selling_price: "",
      mrp: "",
      purchase_price: "",
      discount: "",
      availableSize: [],
      availableColor: [],

      optionGroupSize: [{ value: "Red", code: "" }],
      selectedMultiSize: [],

      showSelectColor: false,
      showSelect: false,
      colorRgb: "#4667CC",
      simple_color1: false,
      color: [{ code: "#4667CC", active: false }],
      description: "",
      delivery_type: "",
      delivery_time: "",
      preview: [],
      multiImages: [],
      refresh: false,
      email: "",
      password: "",
      mobile: "",
    };
    this.handleMulti = this.handleMulti.bind(this);
    this.onDragRgb = this.onDragRgb.bind(this);
    // this.emailValid = this.emailValid.bind(this)
  }
  onDragRgb(index, c1) {
    // alert(c1);
    this.state.color[index].code = c1;
    this.setState({ colorRgb: c1 });
  }

  handleMulti = (selectedMultiSize) => {
    this.setState({ selectedMultiSize });
    setTimeout(() => {
      console.log("selectedMulti", selectedMultiSize);
    });
  };

  componentDidMount = async () => {
    this.checkUser();
    this.fetchCat();
  };

  fetchCat = async () => {
    let result = await HttpClient.requestData("category/Category", "GET");
    if (result && result.status) {
      this.setState({ categoryAll: result.data });
    }
    console.log("result cat", result);
    // let resultsub = await HttpClient.requestData("admin/sub-category", "GET");
    // console.log("resultsub", resultsub);
    // if (resultsub && resultsub.status > 0) {
    //   this.setState({ subCategoryAll: resultsub.data });
    // }
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("refund-personnel", "GET");
    console.log("admin/refund-personnel", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          name: element.fullname,
          email: element.email,
          // mobile: element.mobile,
          img: element.image ? (
            <div>
              <img
                src={"https://admin.blitzcode.online/" + element.image}
                alt="images"
                className="rounded avatar-md card-img"
              />
            </div>
          ) : null,
          status: element.status ? (
            <Button
              className="btn-icon"
              color="success"
              onClick={(e) => this.status(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-wink-outline mr-2"></i>
              </span>{" "}
              Active
            </Button>
          ) : (
            <Button
              className="btn-icon"
              color="danger"
              onClick={(e) => this.status(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-cry-outline mr-2"></i>
              </span>{" "}
              Deactive
            </Button>
          ),
          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              {/* <button
                                title="Edit"
                                className="btn btn-primary"
                                onClick={() => {
                                    this.edit(element, index);
                                }}
                            >
                                <i className="fa fa-edit" />
                            </button> */}
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  deleteapi = async (item) => {
    let sendData = {};
    let result = await HttpClient.requestData(
      "refund-personnel/" + item._id,
      "PUT",
      sendData
    );
    if (result && result.status) {
      this.fetchData();
    }
  };

  delete = async (item, index) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteapi(item),
        },
        {
          label: "No",
          // onClick: () => alert("Click No"),
        },
      ],
    });
  };
  edit = async (item, index) => {
    // let filterSub = this.state.subCategoryAll.filter(
    //   (it) => it.category_id == item.cat_id
    // );
    // let catSize = this.state.categoryAll.filter((ig) => ig.id == item.cat_id);
    // let optionAppowner = {};
    // optionAppowner.label = "Sizes";
    // optionAppowner.options = [];
    // catSize[0].size.map((item, index) => {
    //   optionAppowner.options.push({
    //     label: item.name,
    //     value: item.name,
    //   });
    // });

    // let newOptions = [];
    // newOptions.push(optionAppowner);

    // this.setState({ optionGroupSize: newOptions, showSelect: true });

    // this.setState({ subCategory: filterSub, showSelect: true });
    let arr = [];
    item.image.forEach((element) => {
      let obj = {
        preview: "http://128.199.25.86:5008/" + element,
      };
      arr.push(obj);
    });
    console.log("arr", arr);
    this.setState({
      edit_enable: true,
      name: item.name,
      // img_url: item.img,
      // image_select: true,
      edit_item_id: item._id,
      cat_id: item.catID,
      description: item.description,
      selling_price: item.selling_price,
      mrp: item.mrp,
      uploaded_images: arr,
      multiImages: item.image,
      delivery_type: item.delivery,
      delivery_time: item.delivery_time,
    });
  };
  status = async (index, id) => {
    let sendData = {};
    let result = await HttpClient.requestData(
      "refund-personnel/" + id,
      "PUT",
      sendData
    );
    if (result && result.status) {
      this.fetchData();
    }
  };

  submit = async () => {
    if (
      this.state.name != "" &&
      this.state.email != "" &&
      // this.state.mobile != "" &&
      this.state.password != ""
    ) {
      if(validator.isEmail(this.state.email)){
        this.setState({ loading: true });

        let data = new FormData();
        let result = null;
  
        if (this.state.edit_enable) {
          data.append("fullname", this.state.name);
          data.append("email", this.state.email);
          data.append("password", this.state.password);
          // data.append("mobile", this.state.mobile);
          data.append("image", this.state.images);
          data.append("admin_type", "Refund personnel");
          //   let data = {
          //     fullname: this.state.name,
          //     email: this.state.email,
          //     password: this.state.password,
          //     mobile: this.state.mobile,
          //     image: this.state.multiImages,
          //     admin_type: "Refund personnel"
          //   };
  
          result = await HttpClient.fileUplode(
            "product/Product/" + this.state.edit_item_id,
            "PUT",
            data
          );
        } else {
          // data.append("fullname", this.state.name);
          // data.append("email", this.state.email);
          // data.append("password", this.state.password);
          // data.append("mobile", this.state.mobile);
          // data.append("image", this.state.images);
          // data.append("admin_type", "Refund personnel");
          let data = {
            fullname: this.state.name,
            email: this.state.email,
            password: this.state.password,
            // mobile: this.state.mobile,
            // image: this.state.images,
            admin_type: "Refund_personnel",
          };
  
          //   console.log("asd", data);
  
          result = await HttpClient.requestData(
            "personnel-register",
            "POST",
            data
          );
        }
        console.log("resultt", result);
  
        if (result && result.status) {
          this.setState({
            alert: true,
            message: this.state.edit_enable
              ? <p style={{marginTop:0}}>Refund Personnel Updated Successfully</p>
              : <p style={{marginTop:0}}>Refund Personnel Added Successfully</p>,
            type: "success",
            name: "",
            email: "",
            password: "",
            // mobile: "",
            uploaded_images: [],
            // images: {},
            edit_enable: false,
            edit_item_id: "",
          });
  
          this.fetchData();
        } else {
          this.setState({
            alert: true,
            message: "User already registered",
          //   message:
          //     typeof result.error == "string"
          //       ? result.error
          //       : "User already registered",
            type: "danger",
          });
        }
      }
      else{
        this.setState({
          alert: true,
          message: "Please enter valid email.",
          type: "warning",
        });
      }
      // if (this.state.uploaded_images.length > 0) {
      // alert("hji");
     
      // } else {
      //     this.setState({
      //         alert: true,
      //         message: "Please Select Atleast One Image.",
      //         type: "warning",
      //     });
      // }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  handleAcceptedFiles = (files) => {
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
    };
    console.log("image", files[0]);
    let arr = [];
    arr.push(file);
    this.setState({
      images: files[0],
      uploaded_images: arr,
    });
    console.log("uploaded_images", this.state.uploaded_images);
  };

  async remove_image(item, index) {
    this.setState({
      images: {},
    });
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  // deleteFile = (e) => {
  //   const s = preview.filter((item, index) => index !== e);
  //   setpreview(s);
  //   console.log(s);
  // };

  ImagesUrl = (url) => {
    let tempUrlArr = this.state.multiImages;
    tempUrlArr.push(url);

    this.setState({
      multiImages: tempUrlArr,
    });
    console.log("multiImages", this.state.multiImages);
  };

  imageUploadApi = async (files) => {
    console.log("files", files);
    for (const file of files) {
      let data = new FormData();
      data.append("image", file);
      let result = await HttpClient.fileUplode(
        "product-uploadurl",
        "POST",
        data
      );
      console.log("reuslkyet", result);

      if (result && result.status) {
        this.ImagesUrl(result.data);
      }
    }
  };

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };


  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Name",
          field: "name",

          width: 270,
        },
        {
          label: "Email",
          field: "email",

          width: 270,
        },
        // {
        //     label: "Mobile",
        //     field: "mobile",

        //     width: 270,
        // },

        // {
        //     label: "Image",
        //     field: "img",

        //     width: 270,
        // },
        // {
        //     label: "Status",
        //     field: "status",

        //     width: 100,
        // },
        {
          label: "Action",
          field: "action",

          width: 100,
        },
      ],
      rows: this.state.data,
    };
    const header = [
      { label: "Sl.", key: "sl" },
      { label: "Name", key: "name" },
      { label: "Email", key: "email" },
      // { label: "Mobile", key: "mobile" },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              {/* <BaseInfo /> */}
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2" ></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          id="example-text-input"
                          placeholder="Enter Full Name"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Email
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="email"
                          value={this.state.email}
                          id="example-text-input"
                          placeholder="Enter Email"
                          onChange={(val) => {
                            this.setState({ email: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Password
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="password"
                          value={this.state.password}
                          id="example-text-input"
                          placeholder="Enter Password"
                          onChange={(val) => {
                            this.setState({ password: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    {/* <FormGroup row>
                                            <Label
                                                htmlFor="example-search-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Mobile
                                            </Label>
                                            <Col md={10}>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    value={this.state.mobile}
                                                    id="example-text-input"
                                                    placeholder="Enter Mobile Number"
                                                    onChange={(val) => {
                                                        this.setState({ mobile: val.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup> */}

                    {/* <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Image
                                            </Label>
                                            <Col md={10}>
                                                <Dropzone
                                                    onDrop={(acceptedFiles) =>
                                                        this.handleAcceptedFiles(acceptedFiles)
                                                    }
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div className="dropzone">
                                                            <div
                                                                className="dz-message needsclick mt-2"
                                                                {...getRootProps()}
                                                            >
                                                                <input {...getInputProps()} />
                                                                <div className="mb-3">
                                                                    <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                                </div>
                                                                <h4>Drop files here or click to upload.</h4>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                <div
                                                    className="dropzone-previews mt-3"
                                                    id="file-previews"
                                                >
                                                    {this.state.uploaded_images.map((f, i) => {
                                                        return (
                                                            <Card
                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                key={i + "-file"}
                                                            >
                                                                <div className="p-2">
                                                                    <Row className="align-items-center">
                                                                        <Col className="col-auto">
                                                                            <img
                                                                                data-dz-thumbnail=""
                                                                                height="80"
                                                                                className="avatar-sm rounded bg-light"
                                                                                // alt={f.name}
                                                                                src={f.preview}
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <Button
                                                                                className="btn-icon"
                                                                                color="danger"
                                                                                style={{ marginRight: 25 }}
                                                                                onClick={(e) => this.remove_image(f, i)}
                                                                            >
                                                                                {" "}
                                                                                <span className="btn-icon-label">
                                                                                    <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                                                                </span>{" "}
                                                                                Remove
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Card>
                                                        );
                                                    })}
                                                </div>
                                            </Col>
                                        </FormGroup> */}

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                {/* <div>
                    <EcomPreview />
                  </div> */}
              </Col>
            </Row>
            <Row>
              <Col xl={3}>
                {this.state.data.length > 0 ? (
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"RefundPersonal.csv"}
                    className="btn btn-success"
                  >
                    Export Report in CSV
                  </CSVLink>
                ) : null}
              </Col>
            </Row>
            &nbsp;
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                    disableRetreatAfterSorting={true}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
