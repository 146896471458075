import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Contact US", link: "#" },
        { title: "Add Contact us", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",

      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],

      edit_id: "",
      email: "",
      phone: "",
    };

  }

  componentDidMount = async () => {

    this.fetchData()

  };

  fetchData = async () => {
    let result = await HttpClient.requestData("contact-us-info", "GET");
    if (result && result.status) {
      this.setState({

        email: result.data[0].email,
        phone: result.data[0].phone,
        edit_id: result.data[0]._id,
      })
    }
    else {

    }
  }

  submit = async () => {


    if (
      this.state.about != ""

    ) {
      let data = null;
      let result = null;


      data = {
        info_id: this.state.edit_id,
        email: this.state.email,
        phone: this.state.phone

      };
      console.log("data", data);
      result = await HttpClient.requestData(
        "contact-us-info",
        "POST",
        data
      );

      console.log("result1", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_id == ""
            ? "Contact us Added Successfully"
            : "Contact us Updated Successfully",

          type: "success",

        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message: result.message,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up the field.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };


  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}


                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Email
                      </Label>
                      <Col md={10}>
                        <input

                          className="form-control"
                          type="email"
                          placeholder="Write Here..."
                          onChange={(val) => {
                            this.setState({ email: val.target.value });
                          }}
                          value={this.state.email}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Phone
                      </Label>
                      <Col md={10}>
                        <input

                          className="form-control"

                          type="number"
                          placeholder="Write Here..."
                          onChange={(val) => {
                            this.setState({ phone: val.target.value });
                          }}
                          value={this.state.phone}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_id != "" ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
