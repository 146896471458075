import React, { Component } from "react";
import "./ind432a.css";

export default class design_4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // backgroundImage: this.props.backgroundImage,
      // item: this.props.item,
      // user:this.props.item.user,
      loop: [1,2,3]
    };
  }



  render() {
    return (
      <div
        className="designed_area w-100 h-100 relative"
        style={{
          backgroundImage: `url(https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg)`,
        }}
      >
        <div className="d-flex flex-column w-100 h-100">
          <div className="my-auto ml-auto">
            {this.state.loop.map((item, index) => {
              return (
                <div className="d-flex design_356">
                  <img
                    src="https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png"
                    alt="avatar"
                    className="rounded-circle avatar-lg my-auto mr-2 shadow"
                  />
                  <div>
                    <div className="text_block bg-dark py-2 px-2">
                      <h5 className="text-white">App title</h5>
                    </div>
                    <div className="text_block bg-light py-1 px-2">
                      <h6 className="text-dark">App name</h6>
                      <span>CEO position</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="mb-0 mx-auto w-100 bg-dark"
            style={{
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <div className="d-flex w-100 px-3">
              <div className="m-auto">
                <a href="#" className="text-white">
                  <i className="ri-twitter-line font-size-24"></i>
                </a>
              </div>
              <div className="m-auto">
                <a href="#" className="text-white">
                  <i className="ri-facebook-line font-size-24"></i>
                </a>
              </div>
              <div className="m-auto">
                <a href="#" className="text-white">
                  <i className="ri-linkedin-line font-size-24"></i>
                </a>
              </div>
              <div className="m-auto">
                <a href="#" className="text-white">
                  <i className="ri-instagram-line font-size-24"></i>
                </a>
              </div>
              <div className="m-auto">
                <a href="#" className="text-white">
                  <i className="ri-google-line font-size-24"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
