import React, { Component } from "react";
// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
//i18n
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";

import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // this.scrollToTop = this.scrollToTop.bind(this)
  }

  scrollToTop = (e) => {
    console.log("asda", e);
    window.scrollTo(0, e);
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          {reactLocalStorage.getObject("userData").admin_type == "Refund_personnel" ?
            <>
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">{this.props.t("Menu")}</li>

                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ri-dashboard-line"></i>

                    <span className="ml-1">{this.props.t("Dashboard")}</span>
                  </Link>
                </li>
                <li>
                  <Link activeClassName="active" to="/refund-service-request">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Refund Service Request")}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link activeClassName="active" to="/chat-list">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Approved service by personnel")}</span>
                  </Link>
                </li>
              </ul>
            </> :
            <>
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">{this.props.t("Menu")}</li>

                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ri-dashboard-line"></i>

                    <span className="ml-1">{this.props.t("Dashboard")}</span>
                  </Link>
                </li>


                <li>
                  <Link to="/service-category">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Service Category")}</span>
                  </Link>
                </li>

                {/* <li>
                  <Link to="/all-service">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Commission")}</span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/register-personel">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Register Personel")}</span>
                  </Link>
                </li>

                <li>
                  <Link activeClassName="active" to="/chat-list1">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Approved service by personnel")}</span>
                  </Link>
                </li>

                {/* <li>
                  <Link to="/approval-request">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Approval Request")}</span>
                  </Link>
                </li> */}

                <li>
                  <Link to="/subscription-plan">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Manage Subscription")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/subscription-history">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("All Subscription History")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/associate">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("The Team")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/complain">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("All Complaints")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/applicants">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("All Applicants")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/feedback">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("All Feedback")}</span>
                  </Link>
                </li>


                <li>
                  <Link to="/order-history">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Order History")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/approve-seller">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Approve Seller")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/sellerlist">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("SellerList")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/userlist">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("UserList")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/enquiry">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("EnquiryList")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/promo-code">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("PromoCode")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/category-suggestion">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Category Suggestion")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/claim-request">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Claim Request")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Seller Booking")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/pending-booking">

                        {this.props.t("Pending Booking Report")}

                      </Link>
                    </li>

                    <li>
                      <Link to="/accept-booking">

                        {this.props.t("Accepted Booking Report")}

                      </Link>
                    </li>

                    <li>
                      <Link to="/cancel-booking">
                        {this.props.t("Cancelled Booking Report")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/home-banner">
                    {/* <i className="ri-pencil-ruler-2-line"></i> */}
                    <i className="ri-time-line"></i>
                    <span className="ml-1">{this.props.t("Home Banner")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/about-banner">
                    {/* <i className="ri-pencil-ruler-2-line"></i> */}
                    <i className="ri-time-line"></i>
                    <span className="ml-1">{this.props.t("About Banner")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/blog-type">
                    {/* <i className="ri-pencil-ruler-2-line"></i> */}
                    <i className="ri-time-line"></i>
                    <span className="ml-1">{this.props.t("Add Blog Type")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/add-blog">
                    {/* <i className="ri-pencil-ruler-2-line"></i> */}
                    <i className="ri-time-line"></i>
                    <span className="ml-1">{this.props.t("Add Blog")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/add-career">
                    {/* <i className="ri-pencil-ruler-2-line"></i> */}
                    <i className="ri-time-line"></i>
                    <span className="ml-1">{this.props.t("Career")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/about-us">
                    {/* <i className="ri-pencil-ruler-2-line"></i> */}
                    <i className="ri-time-line"></i>
                    <span className="ml-1">{this.props.t("About Us")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/safety-guide">
                    {/* <i className="ri-pencil-ruler-2-line"></i> */}
                    <i className="ri-time-line"></i>
                    <span className="ml-1">{this.props.t("Safety Guide")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/Legal-Notice">
                    {/* <i className="ri-pencil-ruler-2-line"></i> */}
                    <i className="ri-time-line"></i>
                    <span className="ml-1">{this.props.t("Legal Notice")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us">

                    <i className="ri-time-line"></i>
                    <span className="ml-1">{this.props.t("Contact Us")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/terms-condition">
                    {/* <i className="ri-pencil-ruler-2-line"></i> */}
                    <i className="ri-time-line"></i>
                    <span className="ml-1">
                      {this.props.t("Terms & Condition")}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy">
                    {/* <i className="ri-pencil-ruler-2-line"></i> */}
                    <i className="ri-time-line"></i>
                    <span className="ml-1">{this.props.t("Privacy Policy")}</span>
                  </Link>
                </li>
                {/* <li>
              <Link to="/create-shop">
                
                <i className="ri-time-line"></i>
                <span className="ml-1">{this.props.t("Create Shop")}</span>
              </Link>
            </li> */}
                {/* <li onClick={()=> this.scrollToTop(200)}>
                  <Link to="/testimonial" >
                    <i className="ri-time-line"></i>
                    <span className="ml-1">{this.props.t("Testimonial")}</span>
                  </Link>
                </li> */}

                <li onClick={()=> this.scrollToTop(500)}>
                  <Link to="/social-media" >
                    {/* <i className="ri-pencil-ruler-2-line"></i> */}
                    <i className="ri-time-line"></i>
                    <span className="ml-1">{this.props.t("Social Media")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/review">
                    {/* <i className="ri-pencil-ruler-2-line"></i> */}
                    <i className="ri-time-line"></i>
                    <span className="ml-1">{this.props.t("Review")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/add-faq">
                    {/* <i className="ri-pencil-ruler-2-line"></i> */}
                    <i className="ri-time-line"></i>
                    <span className="ml-1">{this.props.t("Add FAQ")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/add-article">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Add Article")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/testimonial">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Add Testimonial")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Contact")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/meeting">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Create Meeting")}</span>
                  </Link>
                </li>


              </ul>
            </>}
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withNamespaces()(SidebarContent))
);
