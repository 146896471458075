import Firebase1 from 'firebase/compat/app';
import firestore from 'firebase/compat/firestore'
import database from 'firebase/compat/database';
const settings = { timestampsInSnapshots: true };

const firebaseConfig = {
  apiKey: "AIzaSyCaLPxEsafQP5bZGfuCmBJigZXPkcMI14w",
  authDomain: "myspiritual-2f910.firebaseapp.com",
  databaseURL: "https://myspiritual-2f910-default-rtdb.firebaseio.com",
  projectId: "myspiritual-2f910",
  storageBucket: "myspiritual-2f910.appspot.com",
  messagingSenderId: "928631521452",
  appId: "1:928631521452:web:c77b620fbfc46450311f09",
  measurementId: "G-ZEYPZ55LLL"
};
Firebase1.initializeApp(firebaseConfig);


Firebase1.database()

Firebase1.firestore().settings(settings);

export default Firebase1;
