import React, { Component } from "react";
import { CSVLink } from "react-csv";
// import Details from "./details"
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Button,
    UncontrolledAlert,
    InputGroup,
    InputGroupAddon,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
// import EcomPreview from "./E-ComPreview/index";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import index from "../ChooseDesign";

const header = [

    { label: "Order Id", key: "oder_id" },
    { label: "Name", key: "name" },
    { label: "Order Total", key: "total" },
    { label: "Mode of Payment", key: "payment_type" },

    {
        label: "Booking Date", key: "bookingdate"
    },
    {
        label: "Address", key: "address"
    },
]


class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Approval Request", link: "#" },
                { title: "Show Approval Request", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            // page states
            name: "",
            email: "",
            mobile: "",
            password: "",
            data: [],
            alert: false,
            message: "",
            type: "",
            result: [],
            edit_enable: false,
            edit_item_id: "",
            image_select: false,
            img_url: "",
            userId: "",
            userDetails: {},
            //   this page states
            category: [],
            categoryAll: [],
            subCategory: [],
            subCategoryAll: [],
            subServiceAll: [],

            cat_id: "",
            pruduct_type: "",
            sub_cat_id: "",
            sub_srvc_id: "",
            market_price: "",
            selling_price: "",

            // selectedFiles: [],
            images: "",
            uploaded_images: [],

            sell_price: "",
            purchase_price: "",
            discount: "",
            availableSize: [],
            availableColor: [],
            hashtag: "",
            personalisation: "",
            details: "",
            price: "",
            allService: [],
            shopService: [],
            allService_id: "",
            allShopService_id: "",
            to_time: "",
            from_time: "",
            available_duration: 0,
            slot_duration_id: "",

            optionGroupSize: [{ value: "Red", code: "" }],
            selectedMultiSize: [],

            showSelectColor: false,
            showSelect: false,
            colorRgb: "#4667CC",
            simple_color1: false,
            color: [{ code: "#4667CC", active: false }],
            description: "",
            selectcategory: "",
            selectshopserviceid: "",
            edit_id: "",
            weekDay: [
                { label: "Monday", value: "Monday" },
                { label: "Tuesday", value: "Tuesday" },
                { label: "Wednesday", value: "Wednesday" },
                { label: "Thursday", value: "Thursday" },
                { label: "Friday", value: "Friday" },
                { label: "Saturday", value: "Saturday" },
                { label: "Sunday", value: "Sunday" },
            ],
            slot_duration: [
                { label: "10", value: "10" },
                { label: "15", value: "15" },
                { label: "30", value: "30" },
            ],
            language: [
                { label: "en", value: "en" }
            ],
            lang: "",
            slot_time: "",
            selectedWeek: "",
            selectWeekName: "",
            oderId: [],
            ismodalopen: false,
        };

    }


    componentDidMount = async () => {
        // this.checkUser();
        // this.fetchService();
        this.fetchData();
        // this.fetchServicecategory();
        //   let sellerData = reactLocalStorage.getObject("userData");
        // console.log(sellerData);
        // this.fetchCat();
    };


    fetchData = async (id) => {
        console.log("hii");
        console.log(this.state.selectshopserviceid);
        let userData = reactLocalStorage.getObject("userData");
        let result = await HttpClient.requestData(
            "seller-payment-request",
            "GET"
        );
        console.log("getOrderHistory", result);
        if (result && result.status) {
            let data = [];
            let i = 1;
            this.setState({ result: result.data });
            result.data.forEach((element, index) => {
                let i = element.images.findIndex(
                    (element) =>
                        element.ismain == true
                );
                let image = element.service_data.length > 0 ? element.service_data[0].image : "";
                let fname = element.seller_data.firstName;
                let lname = element.seller_data.lastName;
                //let comission = element.admin_commission.length > 0 ? element.admin_commission[0].percentage : 0;
                let rows = {
                    sl: i,
                    name: fname + " " + lname,
                    email: element.seller_data.email,
                    orderid: element.order_id ? element.order_id : "",
                    admin_com: element.commission,
                    total_price: element.total_earning,
                    seller_earning: element.seller_earning,
                    bookdate: moment(element.date_of_booking).format("DD-MM-YYYY"),

                    cat_name: element.service_data ? element.service_data.category_name : "",
                    service: element.service_data ? element.service_data.name : "",
                    img: (
                        <div>
                            {i > -1 ?
                                <img
                                    src={HttpClient.IMG_URL + element.images[i].preview}
                                    alt="images"
                                    className="rounded avatar-md card-img"
                                /> :
                                <img
                                    src={HttpClient.IMG_URL + element.images[0].preview}
                                    alt="images"
                                    className="rounded avatar-md card-img"
                                />}

                        </div>
                    ),
                    // details: (
                    //     <button
                    //         title="Details"
                    //         className="btn btn-primary"
                    //         onClick={() => {


                    //             this.setState({
                    //                 oderId: element,
                    //                 ismodalopen: true,
                    //             });


                    //         }}
                    //     >
                    //         View Details
                    //     </button>
                    // ),
                    withdraw: (
                        <button
                            title="Details"
                            className={element.receipt_status == "Approved" ? "btn btn-danger" : "btn btn-success"}
                            onClick={() => {


                                this.Approve(element)


                            }}
                        >

                            {element.receipt_status == "Approved" ? "Approved" : "Approve"}
                        </button>
                    )
                };
                i++;
                data.push(rows);
            });
            this.setState({
                data: data,
            });
        } else {
            this.setState({
                data: [],
            });
        }
    };


    Approve = async (item) => {
        console.log("item", item);


        let result = await HttpClient.requestData(
            "seller-payment-request/" + item._id,
            "PUT",

        );
        if (result && result.status) {

            this.setState({
                alert: true,
                message: result.message,
                type: "success",
            });
            setTimeout(() => {
                this.setState({
                    alert: false,
                    message: "",
                    type: "",
                });
            }, 3000);

            this.fetchData();
        }
    };







    render() {
        const data = {
            columns: [
                {
                    label: "Sl.",
                    field: "sl",
                    sort: "asc",
                    width: 150,
                },
                // {
                //   label: "Booked By",
                //   field: "booked_by",
                //   sort: "asc",
                //   width: 270,
                // },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Email",
                    field: "email",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Order Id",
                    field: "orderid",
                    sort: "asc",
                    width: 270,
                },

                {
                    label: "Book Date",
                    field: "bookdate",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Category Name",
                    field: "cat_name",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Service Name",
                    field: "service",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Total Price",
                    field: "total_price",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Seller Earning",
                    field: "seller_earning",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Admin comission",
                    field: "admin_com",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Image",
                    field: "img",
                    sort: "asc",
                    width: 100,
                },



                // {
                //   label: "Booking Status",
                //   field: "assign",
                //   sort: "asc",
                //   width: 100,
                // },


                // {
                //     label: "View Details",
                //     field: "details",
                //     sort: "asc",
                //     width: 270,
                // },
                {
                    label: "Action",
                    field: "withdraw",
                    sort: "asc",
                    width: 100,
                },
            ],
            rows: this.state.data,
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.state.breadcrumbItems[0].title}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />
                        {this.state.alert ? (
                            <UncontrolledAlert
                                color={this.state.type}
                                className="alert-dismissible fade show"
                                role="alert"
                            >
                                {this.state.type == "warning" ? (
                                    <i className="mdi mdi-alert-outline mr-2"></i>
                                ) : this.state.type == "success" ? (
                                    <i className="mdi mdi-check-all mr-2"></i>
                                ) : this.state.type == "danger" ? (
                                    <i className="mdi mdi-block-helper mr-2"></i>
                                ) : null}
                                {this.state.message}
                            </UncontrolledAlert>
                        ) : null}

                        <Row>
                            <Col xl={12}>
                                {/* {this.state.data.length > 0 ?
                                    <CSVLink
                                        data={this.state.data}
                                        headers={header}
                                        filename={"OrderHistoryList.csv"}
                                        className="btn btn-success">
                                        Export Report in CSV
                                    </CSVLink> : null} */}
                                <div className="card p-3">

                                    <MDBDataTable responsive bordered data={data} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
