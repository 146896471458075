import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
// import Payment from "./payment";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useState, useEffect } from "react";



const ShowMore = ({ data }) => {
  const [show, setShow] = useState(true)
  // let data = "fglk;hjfdgl;khjfdglikjhcccccccccccccccccccccccc"
  useEffect(() => {
    console.log(show);
  }, [show])
  return (
    <>
      {data.length > 20 ?
        <div>
          {show == true ?
            <>

              {data.slice(0, 20)} <button onClick={() => setShow(false)} className="btn btn-success btn-sm">Read More</button>
            </> : <>
              {data}
            </>}
        </div> :
        <div>
          {data}
        </div>
      }</>
  )
}

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Seller Services", link: "#" },
        { title: "View Seller Services", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      dataa: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      result1: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},

      image_select: false,
      img_url: "",
      booking_total: "",
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      type: "",
      desc: "",
      activeid: {},
      ismodalopen: false,
      isCustomService: false,
    };
    this.handleDefault = this.handleDefault.bind(this);
  }

  componentDidMount = async () => {
    this.checkUser();
    this.fetchData();
    // this.fetchDataCustom();
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }

  onCloseCallBack = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen: val,
    });
  };

  sucess = (val) => {
    if (val) {
      this.fetchData();
    }
  };

  fetchData = async () => {
    let data = {
      device_type: "",
    };
    let id = this.props.match.params.id;
    let result = await HttpClient.requestData("sellerlist", "GET", data);
    console.log("seller_single_list", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      if (result.data.length > 0) {
        let arr = result.data.filter(
          (it) => it._id == this.props.match.params.id
        );
        console.log("arr", arr[0].seller_service);
        this.setState({ result: arr[0].seller_service });
      }

      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,

          service_name: element.name,
          //   personalization: element.personalization.replace(/<[^>]+>/g, ""),
          details: <ShowMore data={element.details.replace(/<[^>]+>/g, "")} />,
          price: (element.currency == "INR"
            ? "₹"
            : element.currency == "CAD"
              ? "$"
              : element.currency == "USD"
                ? "$"
                : element.currency == "GBP"
                  ? "£"
                  : element.currency == "EUR"
                    ? "€"
                    : "₹") + element.price,
          currency: element.currency,
          image: (
            <img
              src={HttpClient.IMG_URL + element.image[0].preview}
              alt=""
              height="50px"
              width="50px"
            />
          ),
          custom_service: element.chataddstatus ? "YES" : "NO",
          delete: (
            <button
              title="Delete"
              className="btn btn-danger mr-2"
              onClick={() => {
                this.delete(element, index);
              }}
            >
              <i className="fa fa-trash" />
            </button>
          )
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  delete = async (item, index) => {
    let result = await HttpClient.requestData(
      "delete-shop-seller/" + item._id,
      "DELETE",
    );
    console.log(result);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };

  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      title: item.name,
      price: item.price,
      edit_item_id: item._id,
      seller_comission: item.seller_comission,
      duration: item.duration,
      id: item.id,
      type: item.type,
    });
  };

  status = async (item, id) => {
    let sendData = {};
    let result = await HttpClient.requestData(
      "user/set-status/" + id,
      "PUT",
      sendData
    );
    console.log("result", result);
    if (result && result.status) {
      this.fetchData();
    }
  };

  sellerStatus = async (item, id) => {
    let sendData = {
      paystatus: true,
    };
    let result = await HttpClient.requestData(
      "adminAccept/" + id,
      "PUT",
      sendData
    );
    console.log("seller Status", result);
    if (result && result.status) {
      this.fetchData();
    }
  };
  submit = async () => {
    if (
      this.state.title != "" &&
      this.state.price != "" &&
      this.state.duration != "" &&
      this.state.seller_comission != "" &&
      this.state.type != ""
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData("subscription", "POST", data);
      } else {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData(
          "subscription/" + this.state.edit_item_id,
          "PUT",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Subscription Updated Successfully"
            : "Subscription Added Succfully",

          type: "success",

          image_select: false,
          img_url: "",
          title: "",
          type: "",
          seller_comission: "",
          duration: "",
          price: "",
          desc: "",
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (e) => {
    let file = e.target.files[0].type;
    let fileType = file.split("/")[0];
    // fileType = fileType[0];
    console.log("e", file.split("/")[0]);

    if (fileType == "image" || fileType == "video") {
      // return false;
      this.setState({ fileType: fileType });
      let dataSend = { buckate: true, buckate_name: "appIcon" };

      let result = await HttpClient.newFileUpload(
        "image-upload/movie",
        e.target.files[0],
        dataSend
      );
      console.log("result", result);
      if (result && result.status) {
        this.setState({ img_url: result.url, image_select: true });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",

          width: 150,
        },

        {
          label: "Services Name",
          field: "service_name",

          width: 270,
        },

        {
          label: "Price",
          field: "price",

          width: 270,
        },

        {
          label: "Currency",
          field: "currency",

          width: 270,
        },
        {
          label: "Details",
          field: "details",

          width: 270,
        },
        {
          label: "Image",
          field: "image",

          width: 270,
        },
        {
          label: "Delete",
          field: "delete",

          width: 270,
        },
      ],
      rows: this.state.data,
    };


    const header = [
      { label: "Sl.", key: "sl" },
      { label: "Services Name", key: "service_name" },
      { label: "Price", key: "price" },
      { label: "Currency", key: "currency" },
      { label: "Details", key: "details" },
      { label: "Personalization", key: "personalization" },
      { label: "Image", key: "image" },
      { label: "Custom Service", key: "custom_service" },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {/* <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Total
                      </Label>
                      <Col md={10}>
                        <input
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder=""
                          value={this.state.booking_total}
                          readOnly
                          style={{border: "none"}}
                        />
                      </Col>
                    </FormGroup>

                   
                  </CardBody>
                </Card>
              </Col>
            </Row> */}
            {/* {this.state.ismodalopen ? (
              <Payment
                status={true}
                onCloseCallBack={this.onCloseCallBack}
                sucess={this.sucess}
                data={this.state.activeid}
              />
            ) : null} */}
            <Col xl={3}>
              {this.state.data.length > 0 ? (
                <CSVLink
                  data={this.state.data}
                  headers={header}
                  filename={"SellerList.csv"}
                  className="btn btn-success"
                >
                  Export Report in CSV
                </CSVLink>
              ) : null}
            </Col>
            &nbsp;
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <MDBDataTableV5
                    responsive
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
