import React, { Component } from "react";

export default class addImageBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showImageBox: this.props.showImageBox ? this.props.showImageBox : false,
      appBgImg: "",
      selectAppBgImg: {},
      appBgImgSelect: false,
    };
  }
  closeModal = () => {
    this.props.callBackImage(
      false,
      this.state.appBgImg,
      this.state.selectAppBgImg,
      this.state.appBgImgSelect
    );
    this.setState({ showImageBox: false });
  };
  render() {
    return (
      <div
        className={this.state.showImageBox ? "modal fade show" : "modal fade"}
        id="addImageModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addImageModalLabel"
        style={{ display: this.state.showImageBox ? "block" : "none" }}
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ marginTop: "5%" }}
        >
          <div className="modal-content">
            <div className="modal-header bg-light">
              <h5 className="modal-title" id="addImageModalLabel">
                {this.props.title}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.closeModal}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="upload_image_area">
                <form
                  className="load_form"
                  style={{ backgroundImage: `url('${this.state.appBgImg}')` }}
                >
                  <input
                    type="file"
                    multiple
                    onChange={(e) => {
                      this.setState({
                        appBgImg: URL.createObjectURL(e.target.files[0]),
                        selectAppBgImg: e.target.files[0],
                        appBgImgSelect: true,
                      });
                      // this.closeModal();
                    }}
                  />
                  <p>Drag your files here or click in this area.</p>
                  <button
                    type="button"
                    onClick={() => {
                      if (this.state.appBgImgSelect) {
                        this.closeModal();
                      } else {
                        alert("Please Select Image");
                      }
                    }}
                  >
                    Upload
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
