import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
// import EcomPreview from "./E-ComPreview/index";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import index from "../ChooseDesign";

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Sub Category", link: "#" },
        { title: "Add & Manage Sub Category", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      //   this page states
      category: [],
      categoryAll: [],
      subCategory: [],
      subCategoryAll: [],
      subServiceAll: [],

      cat_id: "",
      pruduct_type: "",
      sub_cat_id: "",
      sub_srvc_id:"",

      // selectedFiles: [],
      images: "",
      uploaded_images: [],

      sell_price: "",
      purchase_price: "",
      discount: "",
      availableSize: [],
      availableColor: [],

      optionGroupSize: [{ value: "Red", code: "" }],
      selectedMultiSize: [],

      showSelectColor: false,
      showSelect: false,
      colorRgb: "#4667CC",
      simple_color1: false,
      color: [{ code: "#4667CC", active: false }],
      description: "",
    };
    this.handleMulti = this.handleMulti.bind(this);
    this.onDragRgb = this.onDragRgb.bind(this);
  }
  onDragRgb(index, c1) {
    // alert(c1);
    this.state.color[index].code = c1;
    this.setState({ colorRgb: c1 });
  }

  handleMulti = (selectedMultiSize) => {
    this.setState({ selectedMultiSize });
    setTimeout(() => {
      console.log("selectedMulti", selectedMultiSize);
    });
  };

  componentDidMount = async () => {
    this.checkUser();
    this.fetchService();
    this.fetchCat();
  };

  fetchService = async () => {
    let result = await HttpClient.requestData("service", "GET");
    if (result && result.status > 0) {
      this.setState({ subServiceAll: result.data });
    }
    console.log("result service", result);
    // let resultsub = await HttpClient.requestData("admin/sub-category", "GET");
    // console.log("resultsub", resultsub);
    // if (resultsub && resultsub.status > 0) {
    //   this.setState({ subCategoryAll: resultsub.data });
    // }
  };

  fetchCat = async () => {
    let result = await HttpClient.requestData("category/Category", "GET");
    if (result && result.status > 0) {
      this.setState({ categoryAll: result.data });
    }
    console.log("result cat", result);
    // let resultsub = await HttpClient.requestData("admin/sub-category", "GET");
    // console.log("resultsub", resultsub);
    // if (resultsub && resultsub.status > 0) {
    //   this.setState({ subCategoryAll: resultsub.data });
    // }
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("service/subcategory", "GET");
    console.log("subCat", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          name: element.name,
          description:element.description,
        //   img: element.image ? (
        //     <div>
        //       <img
        //         src={"http://128.199.25.86:5008/" + element.image}
        //         alt="images"
        //         className="rounded avatar-md card-img"
        //       />
        //     </div>
        //   ) : null,
        category: element.category_data ? element.category_data[0].name : "",
        //   sell_price: element.price ? element.price : "",
        //   status: (
        //     <button
        //       title="Delete"
        //       className={
        //         element.adminStatus
        //           ? "btn btn-success mr-2"
        //           : "btn btn-danger mr-2"
        //       }
        //       onClick={() => {
        //         this.status(element, index);
        //       }}
        //     >
        //       {element.adminStatus ? "Active" : "Not Active"}
        //     </button>
        //   ),
          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let sendData = {};
    let result = await HttpClient.requestData(
      "service/subcategory/" + item._id,
      "DELETE",
      sendData
    );
    if (result && result.status) {
      // let index = this.state.result.indexOf(item);

      this.fetchData();
    }
  };
  edit = async (item, index) => {
    // let filterSub = this.state.subCategoryAll.filter(
    //   (it) => it.category_id == item.cat_id
    // );
    // let catSize = this.state.categoryAll.filter((ig) => ig.id == item.cat_id);
    // let optionAppowner = {};
    // optionAppowner.label = "Sizes";
    // optionAppowner.options = [];
    // catSize[0].size.map((item, index) => {
    //   optionAppowner.options.push({
    //     label: item.name,
    //     value: item.name,
    //   });
    // });

    // let newOptions = [];
    // newOptions.push(optionAppowner);

    // this.setState({ optionGroupSize: newOptions, showSelect: true });

    // this.setState({ subCategory: filterSub, showSelect: true });
    this.setState({
      edit_enable: true,
      name: item.name,
      // img_url: item.img,
      // image_select: true,
      edit_item_id: item._id,
      cat_id: item.catID,
      description: item.description,
      sell_price: item.price,
      // images: item.image,
      uploaded_images: item.image
        ? [
            {
              preview: "http://128.199.25.86:5008/" + item.image,
            },
          ]
        : [],
    });
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/product/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Succfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  submit = async () => {
    if (
        this.state.name != "" &&
        this.state.description != "" 
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.name,
          description: this.state.description,
          serviceid: this.state.sub_srvc_id,
          categoryid: this.state.cat_id
         
        };
        console.log(data);
        result = await HttpClient.requestData("service/subcategory", "POST", data);
      } else {
        data = {
            name: this.state.name,
            description: this.state.description,
            serviceid: this.state.sub_srvc_id,
            categoryid: this.state.cat_id

        };
        console.log(data);
        result = await HttpClient.requestData(
          "service/subcategory/" + this.state.edit_item_id,
          "PUT",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Subscription Updated Successfully"
            : "Subscription Added Succfully",

          type: "success",

          image_select: false,
          name:"",
          description:"",
          sub_srvc_id:"",
          cat_id:"",
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  handleAcceptedFiles = (files) => {
    // console.log("object", event.target.files[0]);
    // setprofile(event.target.files[0]);
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
    };
    console.log("image", files[0]);
    let arr = [];
    arr.push(file);
    this.setState({
      images: files[0],
      uploaded_images: arr,
    });
    console.log("uploaded_images", this.state.uploaded_images);
  };

  async remove_image(item, index) {
    // this.state.selectedFiles.pop(index);
    // this.state.images.pop(index);
    this.setState({
      images: {},
    });
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  handleChange = async (event) => {
    // let filterSub = this.state.categoryAll.filter(
    //   (item) => item._id == event.target.value
    // );
    this.setState({ cat_id: event.target.value });

    // let catSize = this.state.categoryAll.filter(
    //   (item) => item.id == event.target.value
    // );
    // let optionAppowner = {};
    // optionAppowner.label = "Sizes";
    // optionAppowner.options = [];
    // catSize[0].size.map((item, index) => {
    //   optionAppowner.options.push({
    //     label: item.name,
    //     value: item.name,
    //   });
    // });

    // let newOptions = [];
    // newOptions.push(optionAppowner);

    // this.setState({ optionGroupSize: newOptions, showSelect: true });

    // setTimeout(() => {
    //   console.log("optionGroupSize", this.state.optionGroupSize);
    // }, 200);
  };
  handleServiceChange =  async (event) =>{
      this.setState({ sub_srvc_id: event.target.value })
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Image",
        //   field: "img",
        //   sort: "asc",
        //   width: 270,
        // },
        // {
        //   label: "Type",
        //   field: "product_type",
        //   sort: "asc",
        //   width: 270,
        // },

        {
            label: "Description",
            field: "description",
            sort: "asc",
            width: 270,
          },
        //   {
        //     label: "Service",
        //     field: "service",
        //     sort: "asc",
        //     width: 270,
        //   },
        {
          label: "Category",
          field: "category",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Sub Category",
        //   field: "subcategory",
        //   sort: "asc",
        //   width: 270,
        // },
        // {
        //   label: "Sell Price",
        //   field: "sell_price",
        //   sort: "asc",
        //   width: 270,
        // },
        // {
        //   label: "Purchase Price",
        //   field: "purchase_price",
        //   sort: "asc",
        //   width: 270,
        // },
        // {
        //   label: "Discount",
        //   field: "discount",
        //   sort: "asc",
        //   width: 270,
        // },
        // {
        //   label: "Status",
        //   field: "status",
        //   sort: "asc",
        //   width: 100,
        // },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              {/* <BaseInfo /> */}
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Category Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          id="example-text-input"
                          placeholder="Enter Category Name"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Description
                      </Label>
                      <Col md={10}>
                        <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder="Enter description here..."
                          onChange={(val) => {
                            this.setState({ description: val.target.value });
                          }}
                          value={this.state.description}
                        ></textarea>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Service
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={this.handleServiceChange}
                          value={this.state.sub_srvc_id}
                        >
                          <option value="">Select Service</option>
                          {this.state.subServiceAll.map((item, index) => {
                            return (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Category
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.cat_id}
                        >
                          <option value="">Select Category</option>
                          {this.state.categoryAll.map((item, index) => {
                            return (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </FormGroup>

                    {/* <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Price
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="number"
                          value={this.state.sell_price}
                          placeholder="Enter Product Price"
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ sell_price: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup> */}

                    {/* <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Image
                      </Label>
                      <Col md={10}>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {this.state.uploaded_images.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        // alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Button
                                        className="btn-icon"
                                        color="danger"
                                        style={{ marginRight: 25 }}
                                        onClick={(e) => this.remove_image(f, i)}
                                      >
                                        {" "}
                                        <span className="btn-icon-label">
                                          <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                        </span>{" "}
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Col>
                    </FormGroup> */}

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                {/* <div>
                    <EcomPreview />
                  </div> */}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
