import React, { Component, useEffect, useState } from "react";
import "./preview.css";
import Design_1 from "./PvDesigne/design_1";
import Design_2 from "./PvDesigne/design_2";
import Design_3 from "./PvDesigne/design_3";
import Design_4 from "./PvDesigne/design_4";
import DesignSplash from "./PvDesigne/design_splash";
import { Spinner } from "reactstrap";

function Preview(props) {
  const [chooseDesign, setchooseDesign] = useState(
    props.chooseDesign ? props.chooseDesign : false
  );
  const [active_design, setactive_design] = useState(props.design);
  const [showDesigns, setshowDesigns] = useState(props.showDesigns);
  const [logo, setlogo] = useState(props.logo);
  const [bgImage, setbgImage] = useState(props.bgImage);
  const [title, settitle] = useState(props.title);
  const [refresh, setrefresh] = useState(false);
  const [design, setdesign] = useState(props.design);
  const [data, setdata] = useState(props.data);
  const [viewOnPhone, setviewOnPhone] = useState(false);

  const [d1, setd1] = useState(
    "https://oneanddone.fra1.digitaloceanspaces.com/design/d-1.jpg"
  );
  const [d2, setd2] = useState(
    "https://oneanddone.fra1.digitaloceanspaces.com/design/d-2.jpg"
  );
  const [d3, setd3] = useState(
    "https://oneanddone.fra1.digitaloceanspaces.com/design/d-3.jpg"
  );
  const [d4, setd4] = useState(
    "https://oneanddone.fra1.digitaloceanspaces.com/design/d-4.jpg"
  );

  // useEffect(() => {
  //   console.log("props.bgImage", props.bgImage);
  // }, [props.refresh]);
  // useEffect(() => {
  //   console.log("props.bgImage", props.bgImage);
  // }, []);

  return (
    <div>
      <div id="chr_phone" className>
        <div className="text-center mb-3" role="group">
          <button
            id="previewer"
            className="btn btn-sm btn-primary mr-3"
            onClick={() => {
              setrefresh(true);
              setdata(props.data);
              setbgImage(props.bgImage);
              setTimeout(() => {
                setrefresh(false);
              }, 1000);
              setviewOnPhone(true)
            }}
            // onClick={() => setviewOnPhone(true)}
          >
            <i className="fa fa-mobile-phone mr-1" />
            Preview on phone
          </button>
          <button
            id="reload-overview"
            className="btn btn-sm btn-danger"
            onClick={() => {
              props.save();
              // alert()
            }}
          >
            <i className="fa fa-refresh mr-1" />
            <span>Save changes</span>
          </button>
        </div>
        {refresh ? (
          <Spinner
            className="m-auto"
            color="primary"
            style={{ position: "absolute", top: "50%", left: "50%" }}
          />
        ) : (
          <div className="chr_phone_content">
            <div id="mobile_overview">
              {props.showDesigns ? (
                <DesignSplash
                  logo={props.logo}
                  bgImage={props.bgImage}
                  title={props.title}
                  refresh={props.refreshStatus}
                  state={props.refresh}
                  valBR="20px"
                />
              ) : props.design == 1 ? (
                <Design_1 bgImage={bgImage} data={data} valBR="20px" />
              ) : props.design == 2 ? (
                <Design_2 bgImage={bgImage} data={data} valBR="20px" />
              ) : props.design == 3 ? (
                <Design_3 bgImage={bgImage} data={data} valBR="20px" />
              ) : props.design == 4 ? (
                <Design_4 bgImage={bgImage} data={data} valBR="20px" />
              ) : (
                <Design_3 bgImage={bgImage} data={data} valBR="20px" />
              )}
            </div>
            <div id="phone-bar" className="pt-1">
              {/* <span className="pull-left" style={{ fontWeight: 600 }}>
              05:37
            </span> */}
              <span className="pull-right">
                <i className="fa fa-wifi" />
                <i className="fa fa-signal ml-1" />
                <i className="fa fa-battery-full ml-1" />
              </span>
            </div>
          </div>
        )}
      </div>

      {/* preview on phone box */}
      <div
        className={viewOnPhone ? "modal fade show" : "modal fade"}
        style={{ display: viewOnPhone ? "block" : "none" }}
      >
        <div className="modal-dialog mt-5" style={{ maxWidth: "600px" }}>
          <div className="modal-content">
            <div className="modal-header bg-light">
              <h6 className="modal-title">PREVIEW ON PHONE</h6>
              <button
                type="button"
                className="close"
                onClick={() => setviewOnPhone(false)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body bg-soft-light">
              <p>
                <b>Preview and test your apps directly on your mobile.</b>
                <br />
                <br />
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Laborum nihil sequi et iste ipsum reprehenderit omnis aspernatur
                in? Tenetur inventore, quidem vitae accusamus molestiae
                excepturi voluptates? Natus nesciunt velit aliquid tenetur
                mollitia alias magnam reiciendis fugit quae vero fuga error
                corrupti enim explicabo unde ad voluptatibus debitis soluta.
              </p>
              <div>
                <p className="m-0">Scan this QR Code:</p>
                <img
                  src="https://p.kindpng.com/picc/s/120-1202107_qr-code-png-qr-code-png-transparent-png.png"
                  alt="qr_code"
                  className="img-fluid pull-right"
                  width="200"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Preview;
