import React, { Component } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Table } from "reactstrap";

class TotalServiceCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        }
    }

    render() {
        return (
            <React.Fragment>
                <Col lg={4}>
                    <Card className="bg-dark">
                        <Link to="/service-category">
                            <CardBody>
                                <div>
                                    <div className="text-center">
                                        <p className="mb-2 text-white">Total Service Category</p>
                                        <h4 className='text-white'>{this.props.total_service_catagory}</h4>
                                    </div>
                                </div>
                            </CardBody>
                        </Link>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default TotalServiceCategory;