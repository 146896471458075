import React, { Component } from "react";
import "./ind432a.css";
import ImgCrop from "../../components/ImageCrop/imgCrop";

export default class design_4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backgroundImage: this.props.backgroundImage,
      item: this.props.item,
      user: this.props.item.user,
      cropImg: false,
      cropresult: "",
      seletedFile: {},
    };
  }

  cropHandle = (id) => {
    this.setState((prevState) => ({
      cropImg: {
        ...prevState.cropImg,
        [id]: !prevState.cropImg[id],
      },
    }));
  };
  callBackCrop = (val, id, cropresult, file) => {
    console.log(file);
    this.setState((prevState) => ({
      cropImg: {
        ...prevState.cropImg,
        [id]: val,
      },
      cropresult: cropresult,
      seletedFile: file,
    }));

    setTimeout(() => {
      this.props.cropCallBack(id, cropresult, file);
    }, 200);
  };
  render() {
    return (
      <div
        className="designed_area w-100 h-100 relative"
        style={{
          backgroundImage: `url(${this.state.item.backgroundImage})`,
        }}
      >
        <div className="d-flex flex-column w-100 h-100">
          <div className="my-auto ml-auto relative">
            {this.props.item.user.map((item, index) => {
              return (
                <div className="d-flex design_356" key={index}>
                  <div
                    className="relative"
                    title="Upload logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.cropHandle(index)}
                  >
                    <img
                      src={item.avatar}
                      alt="avatar"
                      className="rounded-circle avatar-lg my-auto mr-2 shadow"
                    />
                  </div>

                  <div>
                      <div
                        className="text_block py-2 px-2"
                        style={{
                          backgroundColor: this.props.item.titlebg
                            ? this.props.item.titlebg
                            : "#343a40",
                        }}
                      >
                        <h5
                          className=""
                          style={{
                            color: this.props.item.titlecolor
                              ? this.props.item.titlecolor
                              : "#fff",
                            fontFamily: this.props.item.fontFamily
                              ? this.props.item.fontFamily
                              : "",
                            fontSize:
                              this.props.item.fontValue != ""
                                ? Number(this.props.item.fontValue)
                                : 16,
                          }}
                        >
                          {item.title}
                        </h5>
                      </div>
                      <div
                        className="text_block py-1 px-2"
                        style={{
                          backgroundColor: this.props.item.namebg
                            ? this.props.item.namebg
                            : "#fff",
                        }}
                      >
                        <h6
                          style={{
                            color: this.props.item.namecolor
                              ? this.props.item.namecolor
                              : "#000",
                            fontFamily: this.props.item.fontFamily
                              ? this.props.item.fontFamily
                              : "",
                            fontSize:
                              this.props.item.fontValue != "" &&
                              Number(this.props.item.fontValue) > 15
                                ? Number(this.props.item.fontValue) - 2
                                : 14,
                          }}
                        >
                          {item.name}
                        </h6>
                        <span
                          style={{
                            color: this.props.item.namecolor
                              ? this.props.item.namecolor
                              : "#000",
                            fontFamily: this.props.item.fontFamily
                              ? this.props.item.fontFamily
                              : "",
                            fontSize:
                              this.props.item.fontValue != "" &&
                              Number(this.props.item.fontValue) > 15
                                ? Number(this.props.item.fontValue) - 2
                                : 14,
                          }}
                        >
                          {item.position}
                        </span>
                      </div>
                    </div>
                  {/* image crop modal */}
                  <div
                    className={
                      this.state.cropImg[index]
                        ? "modal fade show"
                        : "modal fade"
                    }
                    id={"imgCropBox" + index}
                    tabindex="-1"
                    style={{
                      display: this.state.cropImg[index] ? "block" : "none",
                    }}
                  >
                    <div className="modal-dialog mt-5 pt-5" role="document">
                      <ImgCrop callBackCrop={this.callBackCrop} id={index} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="mb-0 mx-auto w-100 relative"
            style={{
              background: this.props.item.iconbgcolor,
            }}
          >
            <div className="d-flex w-100 px-3">
              {this.props.item.icons.map((item, index) => {
                return (
                  <div className="m-auto" key={index}>
                    <img src={item.iconUrl} style={{ height: 40, width: 40 }} />
                  </div>
                );
              })}
            </div>
          </div>
          <input
            type="file"
            className="page_input_file"
            title="Choose Background Image"
            onChange={(e)=>{
              this.props.changeBackground(e.target.files[0])
            }}
          />
        </div>
      </div>
    );
  }
}
