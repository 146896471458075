import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Dropzone from "react-dropzone";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { Editor } from "@tinymce/tinymce-react";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "About US", link: "#" },
        { title: "Add About us", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",

      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      about: "",
      edit_id: "",
      images: "",
      uploaded_images: [],
    };
  }

  componentDidMount = async () => {
    this.fetchData();
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("about-us", "GET");
    if (result && result.status) {
      this.setState({
        about: result.data[0].description,
        edit_id: result.data[0]._id,
        uploaded_images: [
          {
            preview: result.data[0].image,
          },
        ],
      });
    } else {
    }
  };

  handleAcceptedFiles = async (files) => {
    let data = new FormData();
    data.append("image", files[0]);

    let result = await HttpClient.fileUplode(
      "about_us/uploadImage",
      "POST",
      data
    );
    console.log("result", result);
    this.setState({
      images: result.image,
      uploaded_images: [
        {
          preview: result.image,
        },
      ],
    });
  };

  async remove_image(item, index) {
    // this.state.selectedFiles.pop(index);
    // this.state.images.pop(index);
    this.setState({
      images: {},
    });
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  submit = async () => {
    if (this.state.about != "") {
      let data = null;
      let result = null;

      data = {
        info_id: this.state.edit_id,
        heading: "ABout",
        description: this.state.about,
        image: this.state.images,
      };
      console.log("data", data);
      result = await HttpClient.requestData("about-us", "POST", data);

      console.log("result1", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message:
            this.state.edit_id == ""
              ? "About us Added Successfully"
              : "About us Updated Successfully",

          type: "success",
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message: result.message,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up the field.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        About Us
                      </Label>
                      <Col md={10}>
                        {/* <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder="Write Here..."
                          onChange={(val) => {
                            this.setState({ about: val.target.value });
                          }}
                          value={this.state.about}
                        ></textarea> */}
                        <Editor
                          apiKey="15r14a210jticff5jycrmnqijiset548njfyasvp7vzdk3hg"
                          value={this.state.about}
                          onEditorChange={(newValue, editor) => {
                            this.setState({ about: newValue });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Image
                      </Label>
                      <Col md={10}>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {this.state.uploaded_images.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        // alt={f.name}
                                        src={HttpClient.IMG_URL + f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Button
                                        className="btn-icon"
                                        color="danger"
                                        style={{ marginRight: 25 }}
                                        onClick={(e) => this.remove_image(f, i)}
                                      >
                                        {" "}
                                        <span className="btn-icon-label">
                                          <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                        </span>{" "}
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_id != "" ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
