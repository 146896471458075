import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Tooltip,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CustomInput,
  UncontrolledAlert,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "./../../utils/HttpClient";
// import DMSteps from "../Component/dMSteps";
import { Link } from "react-router-dom";
import MyLoader from "../../components/Common/MyLoader";
import BaseInfo from "./baseInfo";
import ImgCrop from "../../components/ImageCrop/imgCrop";

export default class startApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false,
      showImageBox: false,
      showTextBox: false,
      tooltipOpen: false,
      chooseDesign: false,
      backgroundImage:
        "https://png.pngtree.com/thumb_back/fw800/back_our/20190619/ourmid/pngtree-blue-stage-lighting-material-background-image_137923.jpg",
      logo:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/368px-Google_2015_logo.svg.png",
      title: "Add Your Title",
      showDesigns: false,
      appLogo:
        "https://www.logodesign.net/logo/letter-d-inside-polygon-4839ld.png",
      appBgImg: "/static/media/img-4.b2f77390.jpg",
      appLogoSelect: false,
      appBgImgSelect: false,
      selectedAppLogo: {},
      selectAppBgImg: {},
      userId: "",
      userDetails: {},
      result: {},
      appName: "",
      alert: false,
      message: "",
      type: "",
      edit_enable: false,
      edit_id: "",
      loading: false,
      d1: "https://oneanddone.fra1.digitaloceanspaces.com/design/d-1.jpg",
      d2: "https://oneanddone.fra1.digitaloceanspaces.com/design/d-2.jpg",
      d3: "https://oneanddone.fra1.digitaloceanspaces.com/design/d-3.jpg",
      d4: "https://oneanddone.fra1.digitaloceanspaces.com/design/d-4.jpg",
      active_design:
        "https://oneanddone.fra1.digitaloceanspaces.com/design/d-3.jpg",
      design_num: 3,
      refreshStatus: false,
      facebook: "",
      google: "",
      pinterest: "",
      twitter: "",
      linkedin: "",
      webUrl: "",
      cropImg: false,
      cropresult: "",
    };
  }
  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };

  componentDidMount = async () => {
    await this.checkUser();
    // await this.checkExesisting();
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
      this.checkExesisting(user.id);
    } else {
    }
  };

  checkExesisting = async (id) => {
    let sendData = { owner_id: id };
    let result = await HttpClient.requestData(
      "app-owner/application/check-pending",
      "POST",
      sendData
    );
    console.log("result checkExesisting", result);
    if (result && result.status > 0) {
      this.setState({
        result: result.data[0],
        appBgImg: result.data[0].bgImage,
        appLogo: result.data[0].logo,
        appName: result.data[0].name,
        title: result.data[0].title ? result.data[0].title : "Add Your Title",
        edit_enable: true,
        edit_id: result.data[0]._id,
        refreshStatus: true,
        facebook: result.data[0].facebook,
        google: result.data[0].google,
        pinterest: result.data[0].pinterest,
        twitter: result.data[0].twitter,
        linkedin:result.data[0].linkedin,
        webUrl:result.data[0].webUrl
        // active_design:
        //   result.data[0].design_num && result.data[0].design_num == 1
        //     ? this.state.d1
        //     : result.data[0].design_num && result.data[0].design_num == 2
        //     ? this.state.d2
        //     : result.data[0].design_num && result.data[0].design_num == 3
        //     ? this.state.d3
        //     : result.data[0].design_num && result.data[0].design_num == 4
        //     ? this.state.d4
        //     : this.state.d1,
        // design_num: result.data[0].design_num ? result.data[0].design_num : 1,
      });
      // if (result.data[0].step == "1") {
      //   this.setState({ showDesigns: true });
      // }
    }
  };

  imageUpload = async (file) => {
    // console.log("e", file);
    // let file = e.target.files;
    //   if(file.lenght>0)
    //   {
    let dataSend = { buckate: true, buckate_name: "appIcon" };

    let result = await HttpClient.newFileUpload(
      "image-upload/appIcon",
      file,
      dataSend
    );
    // console.log("result", result);
    return result;
  };

  firstFrom = async () => {
    if (
      this.state.appName != "" &&
      this.state.appLogoSelect == true &&
      this.state.appBgImgSelect == true
    ) {
      this.setState({ loading: true });
      let sendData = {
        name: this.state.appName,
        owner_id: this.state.userId,
        facebook: this.state.facebook,
        google: this.state.google,
        pinterest: this.state.pinterest,
        twitter: this.state.twitter,
      };
      let result = null;
      let logoUpload = await this.imageUpload(this.state.selectedAppLogo);
      if (logoUpload && logoUpload.status) {
        sendData.logo = logoUpload.url;
        let bgUpload = await this.imageUpload(this.state.selectAppBgImg);
        if (bgUpload && bgUpload.status) {
          sendData.bgImage = bgUpload.url;
          console.log("sendData", sendData);
          result = await HttpClient.requestData(
            "app-owner/application",
            "POST",
            sendData
          );
          if (result && result.status) {
            this.setState({
              appBgImg: result.data.bgImage,
              appLogo: result.data.logo,
              alert: true,
              message: this.state.edit_enable
                ? "Application Updated Successfully"
                : "Application Added Successfully",
              type: "success",
              loading: false,
              selectAppBgImg: {},
              selectedAppLogo: {},
              appBgImgSelect: false,
              appLogoSelect: false,
            });
            setTimeout(() => {
              this.setState({
                loading: false,
              });
              window.location.href = "/choose-design";
            }, 2000);
            this.checkUser();
          } else {
            this.setState({
              loading: false,
              alert: true,
              message:
                typeof result.error == "string"
                  ? result.error
                  : result.error[0].msg,
              type: "danger",
            });
          }
        }
      }
      // console.log("logoUpload", logoUpload);
      // this.setState({ showDesigns: true });
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  firstFromUpdate = async () => {
    if (this.state.appName != "") {
      this.setState({ loading: true });
      let sendData = this.state.result;
      sendData.edit_id = this.state.edit_id;
      sendData.name = this.state.appName;
      sendData.owner_id = this.state.userId;
      sendData.facebook = this.state.facebook;
      sendData.google = this.state.google;
      sendData.pinterest = this.state.pinterest;
      sendData.twitter = this.state.twitter;
      sendData.linkedin = this.state.linkedin;
      sendData.webUrl = this.state.webUrl;
      // console.log("send Update", sendData);
      // return false;
      let result = null;
      if (this.state.appLogoSelect) {
        let logoUpload = await this.imageUpload(this.state.selectedAppLogo);
        if (logoUpload && logoUpload.status) {
          sendData.logo = logoUpload.url;
        }
      } else {
        sendData.logo = this.state.appLogo;
      }

      if (this.state.appBgImgSelect) {
        let bgUpload = await this.imageUpload(this.state.selectAppBgImg);
        if (bgUpload && bgUpload.status) {
          sendData.bgImage = bgUpload.url;
        }
      } else {
        sendData.bgImage = this.state.appBgImg;
      }

      console.log("sendData", sendData);
      result = await HttpClient.requestData(
        "app-owner/application/update",
        "POST",
        sendData
      );
      if (result && result.status) {
        this.setState({
          appBgImg: result.data.bgImage,
          appLogo: result.data.logo,
          alert: true,
          message: this.state.edit_enable
            ? "Application Updated Successfully"
            : "Application Added Succfully",
          type: "success",
          // loading: false,
          selectAppBgImg: {},
          selectedAppLogo: {},
          appBgImgSelect: false,
          appLogoSelect: false,
        });
        this.checkUser();
        setTimeout(() => {
          this.setState({
            loading: false,
          });
          window.location.href = "/choose-design";
        }, 2000);
      } else {
        this.setState({
          loading: false,
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }

      // console.log("logoUpload", logoUpload);
      // this.setState({ showDesigns: true });
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  updateFrom = async () => {
    if (this.state.edit_enable) {
      let sendData = this.state.result;

      sendData.edit_id = this.state.edit_id;
      if (this.state.title != "" && this.state.title != "Add Your Title") {
        if (this.state.appLogoSelect) {
          let logoUpload = await this.imageUpload(this.state.selectedAppLogo);
          if (logoUpload && logoUpload.status) {
            sendData.logo = logoUpload.url;
          }
        }
        if (this.state.appBgImgSelect) {
          console.log("this.state.selectAppBgImg", this.state.selectAppBgImg);
          let logoUploadNew = await this.imageUpload(this.state.selectAppBgImg);
          if (logoUploadNew && logoUploadNew.status) {
            sendData.bgImage = logoUploadNew.url;
          }
        }
        console.log("design", this.state.design_num);
        sendData.title = this.state.title;
        sendData.design_num = this.state.design_num;
        console.log("sendData", sendData);
        // return sendData;
        let result = await HttpClient.requestData(
          "app-owner/application/update",
          "POST",
          sendData
        );
        console.log("result", result);
        if (result && result.status) {
          this.setState({
            appBgImg: result.data.bgImage,
            appLogo: result.data.logo,
            title: result.data.title,
            alert: true,
            message: this.state.edit_enable
              ? "Application Updated Successfully"
              : "Application Added Succfully",
            type: "success",
            loading: false,
            selectAppBgImg: {},
            selectedAppLogo: {},
            appBgImgSelect: false,
            appLogoSelect: false,
          });
          this.checkUser();
          setTimeout(() => {
            window.location.href = "/choose-design";
          }, 400);
        } else {
          this.setState({
            loading: false,
            alert: true,
            message:
              typeof result.error == "string"
                ? result.error
                : result.error[0].msg,
            type: "danger",
          });
        }
      } else {
        // alert();
        this.setState({
          alert: true,
          message: "Please Enter Your Title.",
          type: "warning",
        });
      }
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };
  callBackCrop = (val, id, cropresult) => {
    this.setState(() => ({
      cropImg: val,
      cropresult: cropresult,
    }));
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {this.state.loading ? (
              <MyLoader loader_text="Ready to go!" />
            ) : null}

            <Row>
              <Col xl={12}>
                {/* <DMSteps /> */}
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
              </Col>
              {/* base information */}
              <BaseInfo />
              {/* base information */}
              <Col xl={12}>
                <div>
                  <div className="row">
                    <div className="col-md-6">
                      <h3
                        className="title-editor py-1 text-center"
                        style={{ background: "#729deb" }}
                      >
                        Step 1
                      </h3>
                    </div>
                    <div className="col-md-6">
                      <h3
                        className="title-editor py-1 text-center"
                        style={{ background: "#729deb" }}
                      >
                        Step 2
                      </h3>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="form-group mb-1">
                            <Label for="icon_url">Enter App Name</Label>
                            <input
                              type="text"
                              className={
                                this.state.appName !== ""
                                  ? "form-control mb-1 is_valid"
                                  : "form-control mb-1"
                              }
                              placeholder="Enter app name..."
                              onChange={(val) => {
                                this.setState({ appName: val.target.value });
                              }}
                              value={this.state.appName}
                            />
                          </div>
                          <div className="row">
                            <h4 className="card-title col-md-12 my-3">
                              Add your social links -{" "}
                            </h4>
                            <div className="col-md-6">
                              <Label for="icon_url">Enter Facebook Link</Label>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className={
                                    this.state.facebook !== ""
                                      ? "form-control mb-1 is_valid"
                                      : "form-control mb-1"
                                  }
                                  placeholder="Enter Facebook Link..."
                                  onChange={(val) => {
                                    this.setState({
                                      facebook: val.target.value,
                                    });
                                  }}
                                  value={this.state.facebook}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <Label for="icon_url">Enter Google+ Link</Label>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className={
                                    this.state.google !== ""
                                      ? "form-control mb-1 is_valid"
                                      : "form-control mb-1"
                                  }
                                  placeholder="Enter Google+ Link..."
                                  onChange={(val) => {
                                    this.setState({ google: val.target.value });
                                  }}
                                  value={this.state.google}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Label for="icon_url">Enter Pinterest Link</Label>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className={
                                    this.state.pinterest !== ""
                                      ? "form-control mb-1 is_valid"
                                      : "form-control mb-1"
                                  }
                                  placeholder="Enter Pinterest Link..."
                                  onChange={(val) => {
                                    this.setState({
                                      pinterest: val.target.value,
                                    });
                                  }}
                                  value={this.state.pinterest}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <Label for="icon_url">Enter Twitter Link</Label>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className={
                                    this.state.twitter !== ""
                                      ? "form-control mb-1 is_valid"
                                      : "form-control mb-1"
                                  }
                                  placeholder="Enter Twitter Link..."
                                  onChange={(val) => {
                                    this.setState({
                                      twitter: val.target.value,
                                    });
                                  }}
                                  value={this.state.twitter}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Label for="icon_url">Enter Linkedin Link</Label>
                              <div className="form-group mb-0">
                                <input
                                  type="text"
                                  className={
                                    this.state.linkedin !== ""
                                      ? "form-control mb-0 is_valid"
                                      : "form-control mb-0"
                                  }
                                  placeholder="Enter Linkedin Link..."
                                  onChange={(val) => {
                                    this.setState({
                                      linkedin: val.target.value,
                                    });
                                  }}
                                  value={this.state.linkedin}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <Label for="icon_url">Enter Website Url</Label>
                              <div className="form-group mb-0">
                                <input
                                  type="text"
                                  className={
                                    this.state.webUrl !== ""
                                      ? "form-control mb-0 is_valid"
                                      : "form-control mb-0"
                                  }
                                  placeholder="Enter Website Url..."
                                  onChange={(val) => {
                                    this.setState({
                                      webUrl: val.target.value,
                                    });
                                  }}
                                  value={this.state.webUrl}
                                />
                              </div>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn btn-info font-size-14 btn-sm px-3 mt-3"
                          >
                            Add more links
                            <i className="ri-add-line align-middle ml-1"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <Label for="icon_url">Upload App Logo</Label>
                                <div className="custom-file">
                                  <CustomInput
                                    type="file"
                                    className="custom-file-input"
                                    id="customLogo"
                                    onChange={(e) => {
                                      this.setState({
                                        appLogo: URL.createObjectURL(
                                          e.target.files[0]
                                        ),
                                        selectedAppLogo: e.target.files[0],
                                        appLogoSelect: true,
                                      });
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        cropImg: true,
                                      })
                                    }
                                  />
                                  <Label
                                    className="custom-file-label"
                                    htmlFor="customLogo"
                                  >
                                    App logo
                                  </Label>
                                </div>
                              </div>
                              {/* image crop modal */}
                              <div
                                className={
                                  this.state.cropImg
                                    ? "modal fade show"
                                    : "modal fade"
                                }
                                id={"imgCropBox"}
                                tabindex="-1"
                                style={{
                                  display: this.state.cropImg
                                    ? "block"
                                    : "none",
                                }}
                              >
                                <div
                                  className="modal-dialog mt-5 pt-5"
                                  role="document"
                                >
                                  <ImgCrop
                                    callBackCrop={this.callBackCrop}
                                    id={1}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <Label for="icon_url">
                                  Upload Welcome Page Image
                                </Label>
                                <div className="custom-file">
                                  <CustomInput
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                    onChange={(e) => {
                                      this.setState({
                                        appBgImg: URL.createObjectURL(
                                          e.target.files[0]
                                        ),
                                        selectAppBgImg: e.target.files[0],
                                        appBgImgSelect: true,
                                      });
                                    }}
                                  />
                                  <Label
                                    className="custom-file-label"
                                    htmlFor="customFile"
                                  >
                                    Welcome Page image
                                  </Label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <h4 className="card-title text-muted font-size-14 mb-3">
                                Selected Logo
                              </h4>
                              <div className="mt-4 mt-md-0">
                                <img
                                  className="rounded-circle avatar-xl"
                                  alt="Logo"
                                  src={this.state.appLogo}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <h4 className="card-title text-muted font-size-14 mb-3">
                                Selected Welcome Page Image
                              </h4>
                              <img
                                className="rounded mr-2"
                                alt="Welcome Page Image"
                                width={200}
                                src={this.state.appBgImg}
                              />
                            </div>
                          </div>
                          <div className="d-flex mt-5">
                            <Button
                              color="success"
                              type="button"
                              className="waves-effect waves-light my-auto"
                              onClick={() => {
                                if (this.state.edit_enable == false) {
                                  this.firstFrom();
                                } else {
                                  this.firstFromUpdate();
                                }
                              }}
                            >
                              {this.state.edit_enable == false
                                ? "Submit"
                                : "Update"}
                              <i className="ri-arrow-right-line align-middle ml-1"></i>
                            </Button>
                            <button
                              type="button"
                              className="waves-effect waves-light btn btn-primary my-auto ml-3"
                            >
                              Save as Draft{" "}
                              <i className="ri-file-text-line align-middle ml-1"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
