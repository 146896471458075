import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import d1 from "../../assets/images/d-1.jpg";
import d2 from "../../assets/images/d-2.jpg";
import d3 from "../../assets/images/d-3.jpg";
import d4 from "../../assets/images/d-4.jpg";
import "./ind432a.css";

export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chooseDesign: this.props.chooseDesign ? this.props.chooseDesign : false,
      active_design: this.props.design?this.props.design:1,
      d1: "https://oneanddone.fra1.digitaloceanspaces.com/design/d-1.jpg",
      d2: "https://oneanddone.fra1.digitaloceanspaces.com/design/d-2.jpg",
      d3: "https://oneanddone.fra1.digitaloceanspaces.com/design/d-3.jpg",
      d4: "https://oneanddone.fra1.digitaloceanspaces.com/design/d-4.jpg",
      design4: {
        backgroundImage:
          "https://png.pngtree.com/thumb_back/fw800/back_our/20190619/ourmid/pngtree-blue-stage-lighting-material-background-image_137923.jpg",
        user: [
          {
            avatar:
              "https://i.pinimg.com/originals/7c/c7/a6/7cc7a630624d20f7797cb4c8e93c09c1.png",
            title: "Lorem ipsum dolor sit amet.",
            name:"User Name",
            position:"CEO of Unknown"
          },
        ],
      },
    };
  }
  closeModal = () => {
    this.props.callBackDesign(false);
    this.props.callBackSelectedDesign(this.state.active_design);
    this.setState({ chooseDesign: false });
  };
  componentDidMount()
  {
    console.log('this.props.design',this.props.design);
  }
  render() {
    return (
      <div
        className={this.state.chooseDesign ? "modal fade show" : "modal fade"}
        id="addImageModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addImageModalLabel"
        style={{ display: this.state.chooseDesign ? "block" : "none" }}
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ marginTop: "1%", maxWidth: "90%" }}
        >
          <div className="modal-content" style={{ maxHeight: "700px" }}>
            <div className="modal-header py-1 bg-light">
              <h5 className="modal-title" id="addImageModalLabel">
                Choose Your Design
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.closeModal}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="design_area">
                <div>
                  <div className="d-flex overflow-x">
                    <div
                      className="flexDiv"
                      onClick={() => {
                        // alert(1);
                        this.setState({ active_design: 1 });
                        setTimeout(()=>{
                          this.closeModal();
                        },800)
                      }}
                    >
                      <div
                        className={
                          this.state.active_design == 1
                            ? "design_div bg-soft-secondary selected_cursor"
                            : "design_div bg-soft-secondary"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={this.state.d1}
                          alt="img"
                          className="img_des"
                        />
                      </div>
                    </div>
                    <div
                      className="flexDiv"
                      onClick={() => {
                        // alert(1);
                        this.setState({ active_design: 2 });
                        setTimeout(()=>{
                          this.closeModal();
                        },800)
                      }}
                    >
                      <div
                        className={
                          this.state.active_design == 2
                            ? "design_div bg-soft-secondary selected_cursor"
                            : "design_div bg-soft-secondary"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={this.state.d2}
                          alt="img"
                          className="img_des"
                        />
                      </div>
                    </div>
                    <div
                      className="flexDiv"
                      onClick={() => {
                        // alert(1);
                        this.setState({ active_design: 3 });
                        setTimeout(()=>{
                          this.closeModal();
                        },800)
                      }}
                    >
                      <div
                        className={
                          this.state.active_design == 3
                            ? "design_div bg-soft-secondary selected_cursor"
                            : "design_div bg-soft-secondary"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={this.state.d3}
                          alt="img"
                          className="img_des"
                        />
                      </div>
                    </div>
                    <div
                      className="flexDiv"
                      onClick={() => {
                        // alert(1);
                        this.setState({ active_design: 4 });
                        setTimeout(()=>{
                          this.closeModal();
                        },800)
                        
                      }}
                    >
                      <div
                        className={
                          this.state.active_design == 4
                            ? "design_div bg-soft-secondary selected_cursor"
                            : "design_div bg-soft-secondary"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={this.state.d4}
                          alt="img"
                          className="img_des"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
