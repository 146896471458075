import React, { Component } from "react";

import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

import {
  Container,
  Row,
  Card,
  Col,
  Tooltip,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Input,
  Form,
  Button,
  FormGroup,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledAlert,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import BaseInfo from "../ChooseDesign/baseInfo";

export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1,
      tooltipOpen: false,
      result: [],
      data: [],
      monthDate: new Date(),
      modal_static: false,
      cardLoop: [1, 2, 3, 4],

      question: [
          {
            question: "",
            option1: "",
            option2: "",
            option3: "",
            option4: "",
            answer: "",
          },
        ],


        option1: [
          {
            question: "",
            option1: "",
            option2: "",
            option3: "",
            option4: "",
            answer: "",
          },
        ],


        // question: [
        //   {
        //     question: "",
        //     option1: [
        //       {
        //         question:"",
        //         option1:"",
        //         option2:"",
        //       }
        //     ],
        //     option2: "",
        //     option3: "",
        //     option4: "",
        //     answer: "",
        //   },
        // ],


        questionObj : {
          question:"",
          option1:"",
          option2:"",
        },

        questionObj : {
          question: "",
          option1: "",
          option2: "",
          option3: "",
          option4: "",
          answer: "",
        },


      // question: [
      //   {
      //     question: "",
      //     option1: [
            
      //       {
      //         question:"",
      //         option1:"",
      //         option2: "",
      //         option3: "",
      //         option4: "",
      //         answer: "",
      //       }
      //     ],
      //     option2: [

      //       {
      //         question:"",
      //         option1:"",
      //         option2: "",
      //         option3: "",
      //         option4: "",
      //         answer: "",
      //       }
      //     ],
      //     option3: [

      //       {
      //         question:"",
      //         option1:"",
      //         option2: "",
      //         option3: "",
      //         option4: "",
      //         answer: "",
      //       }
      //     ],
      //     option4: [
      //       {
      //         question:"",
      //         option1:"",
      //         option2: "",
      //         option3: "",
      //         option4: "",
      //         answer: "",
      //       }
      //     ],
      //     answer: "",
      //   },
      // ],
      
      quizName: "",
      startDate: new Date(),
      endDate: new Date(),
      userId: "",
      userDetails: {},
      giftCard: [],
      giftCardSelected: [],
      img: {},
      imgUrl: "",
      refreshStatus: false,
      // know your customer
      previewKnow: false,
      edit_enable: false,
      edit_item_id: "",
      previewData: {},
      resultKnow: [],
      dataKnow: [],
      breadcrumbItems: [
        { title: "Quiz ", link: "#" },
        { title: "Add & Manage Quiz", link: "#" },
      ],
      alert: false,
      message: "",
      type: "",
    };
    // this.toggleTab = this.toggleTab.bind(this);
    // this.handleMonthChange = this.handleMonthChange.bind(this);
    this.tog_CardModal = this.tog_CardModal.bind(this);
  }

  
 
  
  tog_CardModal() {
    this.setState((prevState) => ({
      modal_static: !prevState.modal_static,
    }));
    // this.removeBodyCss();
  }

  componentDidMount() {
    // this.checkUser();
  }



  

  preview = () => { };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Image",
          field: "image",
          sort: "asc",
          width: 270,
        },
        {
          label: "Quiz Name",
          field: "quizName",
          sort: "asc",
          width: 270,
        },
        {
          label: "Type",
          field: "type",
          sort: "asc",
          width: 270,
        },
        {
          label: "Start Date",
          field: "startDate",
          sort: "asc",
          width: 150,
        },
        {
          label: "End Date",
          field: "endDate",
          sort: "asc",
          width: 150,
        },
        {
          label: "Participant",
          field: "quizparticipant",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };

    const dataKnow = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Question",
          field: "quizName",
          sort: "asc",
          width: 270,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.dataKnow,
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
             
              <Col xl={12}>
                <Card>
                  {this.state.alert ? (
                    <UncontrolledAlert
                      color={this.state.type}
                      className="alert-dismissible fade show"
                      role="alert"
                    >
                      {this.state.type == "warning" ? (
                        <i className="mdi mdi-alert-outline mr-2"></i>
                      ) : this.state.type == "success" ? (
                        <i className="mdi mdi-check-all mr-2"></i>
                      ) : this.state.type == "danger" ? (
                        <i className="mdi mdi-block-helper mr-2"></i>
                      ) : null}
                      {this.state.message}
                    </UncontrolledAlert>
                  ) : null}


                  <div>
                    
                    <div
                      id="basic-pills-wizard"
                      className="twitter-bs-wizard bg-white"
                    >
                     
                      <TabContent
                        activeTab={this.state.activeTab}
                        className="twitter-bs-wizard-tab-content pt-0"
                      >
                        {/* <TabPane tabId={1}> */}
                        <Form className="card p-3">
                          <h4>Add your question modules</h4>
                          <Row>
                            {this.state.question.map((item, index) => {
                              return (
                                <>
                                  <Col lg="8">
                                    <FormGroup>
                                      <Label for="question-input1">
                                        Describe Question
                                      </Label>
                                      <textarea
                                        id="question-input1"
                                        className="form-control"
                                        rows="2"
                                        placeholder="Enter your question here..."
                                        onChange={(val) => {
                                          this.state.question[index].question =
                                            val.target.value;
                                          this.setState({});
                                        }}
                                        value={
                                          this.state.question[index].question
                                        }
                                      ></textarea>
                                    </FormGroup>
                                  </Col>

                                 
                                  <Col lg="8">
                                    <FormGroup>
                                      <Label for="option-input1">
                                        Option 1
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={
                                          this.state.question[index].option1
                                        }
                                        id="option-input1"
                                        placeholder="Enter option 1..."
                                        onChange={(val) => {
                                          this.state.question[index].option1 =
                                            val.target.value;
                                          this.setState({});
                                          console.log(this.state.question)
                                        }}
                                      />
                                    </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                    <FormGroup className="mb-0">
                                      <div className="button-items pt-4">
                                        {   
                                          
                                            <Button
                                              color="success"
                                              type="button"
                                              className="waves-effect waves-light mr-1"
                                              onClick={() => {
                                                // this.setState({});
                                                  let questionObj = {
                                                    question: "",
                                                    option1: "",
                                                    option2: "",
                                                    option3: "",
                                                    option4: "",
                                                    answer: "",
                                                  };
                                                  
                                                    this.state.question.push(
                                                      questionObj
                                                    );
                                                    this.setState({});
                                                    console.log(
                                                      "optionindexx",
                                                      this.state.question
                                                    );
                                                   
                                              
                                              }}
                                            >
                                              Add More{" "}
                                              <i className="ri-add-fill align-middle ml-1"></i>
                                            </Button>
                                         
                                         
                                        }
                                      </div>
                                    </FormGroup>
                                    </Col>
                                    <Col lg="8">
                                    <FormGroup>
                                      <Label for="option-input2">
                                        Option 2
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={
                                          this.state.question[index].option2
                                        }
                                        id="option-input2"
                                        placeholder="Enter option 2..."
                                        onChange={(val) => {
                                          this.state.question[index].option2 =
                                            val.target.value;
                                          this.setState({});
                                        }}
                                      />
                                    </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                    <FormGroup className="mb-0">
                                      <div className="button-items pt-4">
                                        {  
                                            <Button
                                              color="success"
                                              type="button"
                                              className="waves-effect waves-light mr-1"
                                              onClick={() => {
                                               
                                                  let questionObj = {
                                                    question: "",
                                                    option1: "",
                                                    option2: "",
                                                    option3: "",
                                                    option4: "",
                                                    answer: "",
                                                  };
                                                  
                                                    this.state.question.push(
                                                      questionObj
                                                    );
                                                    this.setState({});
                                                    console.log(
                                                      "optionindexx",
                                                      this.state.question
                                                    );
                                                   
                                              
                                              }}
                                            >
                                              Add More{" "}
                                              <i className="ri-add-fill align-middle ml-1"></i>
                                            </Button>
                                         
                                         
                                        }
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  {/* <Col lg="8">
                                    <FormGroup>
                                      <Label for="option-input3">
                                        Option 3
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={
                                          this.state.question[index].option3
                                        }
                                        id="option-input3"
                                        placeholder="Enter option 3..."
                                        onChange={(val) => {
                                          this.state.question[index].option3 =
                                            val.target.value;
                                          this.setState({});
                                        }}
                                      />
                                    </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                    <FormGroup className="mb-0">
                                      <div className="button-items pt-4">
                                        {   
                              
                                            <Button
                                              color="success"
                                              type="button"
                                              className="waves-effect waves-light mr-1"
                                              onClick={() => {
                                                // this.setState({});
                                                
                                               
                                                    let questionObj = {
                                                    question: "",
                                                    option1: "",
                                                    option2: "",
                                                    option3: "",
                                                    option4: "",
                                                    answer: "",
                                                  };
                                                  
                                                    this.state.question.push(
                                                      questionObj
                                                    );
                                                    this.setState({});
                                                    console.log(
                                                      "optionindexx",
                                                      this.state.question
                                                    );
                                                   
                                              
                                              }}
                                            >
                                              Add More{" "}
                                              <i className="ri-add-fill align-middle ml-1"></i>
                                            </Button>
                                         
                                         
                                        }
                                      </div>
                                    </FormGroup>
                                    </Col>
                                    <Col lg="8">
                                    <FormGroup>
                                      <Label for="option-input4">
                                        Option 4
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={
                                          this.state.question[index].option4
                                        }
                                        id="option-input4"
                                        placeholder="Enter option 4..."
                                        onChange={(val) => {
                                          this.state.question[index].option4 =
                                            val.target.value;
                                          this.setState({});
                                        }}
                                      />
                                    </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                    <FormGroup className="mb-0">
                                      <div className="button-items pt-4">
                                        {   
                                          
                                    
                                       
                                            <Button
                                              color="success"
                                              type="button"
                                              className="waves-effect waves-light mr-1"
                                              onClick={() => {
                                                this.setState({});
                                                
                                               
                                                  
                                                  let questionObj = {
                                                    question: "",
                                                    option1: "",
                                                    option2: "",
                                                    option3: "",
                                                    option4: "",
                                                    answer: "",
                                                  };
                                                  
                                                    this.state.question.push(
                                                      questionObj
                                                    );
                                                    this.setState({});
                                                    console.log(
                                                      "optionindexx",
                                                      this.state.question
                                                    );
                                                   
                                              
                                              }}
                                            >
                                              Add More{" "}
                                              <i className="ri-add-fill align-middle ml-1"></i>
                                            </Button>
                                         
                                         
                                        }
                                      </div>
                                    </FormGroup>
                                  </Col> */}

                                  <Col lg="6">
                                    {/* <FormGroup>
                                      <Label for="answer-input1">
                                        Correct Answer
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        id="answer-input1"
                                        placeholder="Enter your answer..."
                                        onChange={(val) => {
                                          this.state.question[index].answer =
                                            val.target.value;
                                          this.setState({});
                                        }}
                                        value={this.state.question[index].answer}
                                      />
                                    </FormGroup> */}
                                  </Col>
                                 
                                </>
                              );
                            })}

                           
                          </Row>
                        </Form>
                        <div className="card px-3 pt-0">
                          {/* <h4>Manage your quizes</h4>
                          <MDBDataTable responsive bordered data={data} /> */}
                        </div>
                        {/* </TabPane>
                      <TabPane tabId={2}> */}
                        {/* <KnowCustomer
                          previewKnow={this.state.previewKnow}
                          refreshStatus={!this.state.refreshStatus}
                          previewBtn={(val) => {
                            console.log("val", val);
                            this.setState({
                              previewData: val,
                            });
                            setTimeout(() => {
                              this.setState({
                                previewKnow: true,
                                refreshStatus: !this.state.refreshStatus,
                              });
                            }, 200);
                          }}
                          calbackKnow={() => {
                            this.fetchKnow();
                          }}
                        /> */}
                        {/* </TabPane> */}
                      </TabContent>
                    </div>
                    <Modal
                      isOpen={this.state.modal_static}
                      toggle={this.tog_CardModal}
                      backdrop="static"
                    >
                      <ModalHeader
                        toggle={() => this.setState({ modal_static: false })}
                        className="bg-light"
                      >
                        Choose card
                    </ModalHeader>
                      <ModalBody>
                        <h5 className="mb-3">Select card for your quiz</h5>
                        {this.state.giftCard.map((item, index) => {
                          return (
                            <div
                              className="custom-control custom-checkbox mb-3"
                              key={index}
                            >
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id={"CustomCheck1" + index}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    let check = this.state.giftCardSelected.filter(
                                      (it) => it.id == item.id
                                    );
                                    if (check.length == 0) {
                                      this.state.giftCardSelected.push(item);
                                      this.setState({});
                                    } else {
                                    }
                                  } else {
                                    let ind = this.state.giftCardSelected.indexOf(
                                      item
                                    );
                                    if (ind > -1) {
                                      this.state.giftCardSelected.splice(ind, 1);
                                      this.setState({});
                                    }
                                  }
                                }}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor={"CustomCheck1" + index}
                              >
                                Card : {item.loyaltyNumber + " - " + item.type}
                              </Label>
                            </div>
                          );
                        })}
                        <ModalFooter>
                          <Button
                            type="button"
                            color="light"
                            onClick={() => this.setState({ modal_static: false })}
                          >
                            Close
                        </Button>
                          <Button
                            type="button"
                            color="primary"
                            onClick={() => {
                              this.submitFrom();
                            }}
                          >
                            Save
                        </Button>
                        </ModalFooter>
                      </ModalBody>
                    </Modal>
                  </div>
                </Card>
              </Col>
              {/* <Col xl={4}>
                <div>
                  <QuizPreview
                    quizData={this.state.result}
                    refreshStatus={this.state.refreshStatus}
                    userDetails={this.state.userDetails}
                  />
                </div>
              </Col> */}
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {this.state.activeTab == 1 ? (
                    <MDBDataTable responsive bordered data={data} />
                  ) : (
                    <MDBDataTable responsive bordered data={dataKnow} />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
