import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
import Select from "react-select";
import ProgressBar from "@ramonak/react-progress-bar";


// import EcomPreview from "./E-ComPreview/index";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Episodes - ", link: "#" },
        { title: "Add & Manage Episodes", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      //   this page states
      category: [],
      categoryAll: [],

      selectedFiles: [],
      images: [],
      uploaded_images: [],

      optionGroup: [],
      selectedMulti: [],
      director: "",
      desc: "",
      season: "",
      trailer: "",
      showSelect: true,
      seriesId: "",
      fetchSeries: {},
      episode_no: "",

      videoUrl: "",
      url: "",
      episode_trailer: false,
      progressBarParsent: 0,

      selectedFiles: [],
      images: [],
      uploaded_images: [],
    };
    this.handleMulti = this.handleMulti.bind(this);
  }
  handleMulti = (selectedMulti) => {
    this.setState({ selectedMulti });
  };
  componentDidMount = async () => {
    let seriesId = this.props.match.params.id;
    this.checkUser();
    this.fetchSeries(seriesId);
    this.fetchEpisodes(seriesId);
    this.setState({ seriesId });
  };

  fetchSeries = async (seriesId) => {
    let result = await HttpClient.requestData(
      "admin/movies/" + seriesId,
      "GET"
    );
    console.log("result fetchSeries", result);
    if (result && result.status) {
      let seriesName = result.data[0].name;
      let bb = this.state.breadcrumbItems;
      bb[0].title = "Episodes - " + seriesName;
      this.setState({ fetchSeries: result.data[0], breadcrumbItems: bb });
    }
  };

  fetchEpisodes = async (seriesId) => {
    let result = await HttpClient.requestData(
      "admin/episodes/" + seriesId,
      "GET"
    );
    // console.log("episodes result", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let category = "";

        if (element.category) {
          category = element.category.reduce(
            (acc, curr) => `${acc}${curr.label} | `,
            ""
          );
          category = category.substring(0, category.length - 2);
        }
        let episodeName = "";
        if (element.name.length > 35) {
          episodeName = element.name.substring(0, 35)+ "...";
        } else {
          episodeName = element.name;
        }

        let rows = {
          sl: i,
          name: episodeName ,
          img:
            typeof(element.img) != "undefined" && element.img.length > 0 ? (
              <div>
                <img
                  src={element.img[0].preview}
                  alt="images"
                  className="rounded avatar-md card-img"
                />
              </div>
            ) : null,
          season: element.season ? element.season : "",
          episode_no: element.episode_no ? element.episode_no : "",
          date: element.created_on
            ? moment(element.created_on).format("LL")
            : "",
          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.status ? "Active" : "Not Active"}
            </button>
          ),
          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  imageUpload = async (file, index) => {
    // console.log("e", file);

    let dataSend = { buckate: false };

    let result = await HttpClient.newFileUpload(
      "image-upload/movie",
      file,
      dataSend
    );
    console.log("result", result);
    if (result && result.status) {
      this.state.uploaded_images.push({ preview: result.url });
      if (this.state.images.length - 1 == index) {
        setTimeout(() => {
          this.setState({ loading: false });
          console.log("this.state.uploaded_images", this.state.uploaded_images);
        }, 200);
      }
      this.setState({});
    }
  };

  handleAcceptedFiles = (files) => {
    let data = [];
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
      // image:files,
    };
    let file2 = {
      image: files,
    };

    let file3 = files;
    console.log("data", file);
    this.state.selectedFiles.push(file);
    this.state.images.push(files[0]);
    this.imageUpload(files[0]);
    this.setState({});
  };

  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "admin/episodes/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchEpisodes(this.state.seriesId);
      }
    }
  };

  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      name: item.name,
      uploaded_images: item.img?item.img:[],
      image_select: true,
      edit_item_id: item._id,
      desc: item.desc,
      season: item.season,
      episode_no: item.episode_no,
      videoUrl:item.videoUrl,
      url:item.url,
    });
    setTimeout(() => {
      this.setState({ showSelect: true });
    }, 200);
    this.handleMulti(item.category);
  };

  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/episodes/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Succfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchEpisodes(this.state.seriesId);
    }
  };

  submit = async () => {
    if (this.state.name != "") {
      this.setState({ loading: true });
      let data = null;
      let result = null;
      if (this.state.edit_enable) {
        data = {
          name: this.state.name,
          desc: this.state.desc,
          duration: this.state.duration,
          season: this.state.season,
          edit_id: this.state.edit_item_id,
          episode_no: this.state.episode_no,
          videoUrl: this.state.videoUrl,
          url: this.state.url,
          img: this.state.uploaded_images,

        };
        result = await HttpClient.requestData(
          "admin/episodes/update",
          "POST",
          data
        );
      } else {
        data = {
          name: this.state.name,
          desc: this.state.desc,
          duration: this.state.duration,
          web_id: Number(this.state.seriesId),
          season: this.state.season,
          episode_no: this.state.episode_no,
          videoUrl: this.state.videoUrl,
          url: this.state.url,
          img: this.state.uploaded_images,

        };
        result = await HttpClient.requestData("admin/episodes", "POST", data);
      }

      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Episodes Updated Successfully"
            : "Episodes Added Succfully",
          type: "success",
          name: "",
          cat_id: "",
          sub_cat_id: "",
          desc: "",
          pruduct_type: "",
          sell_price: "",
          purchase_price: "",
          discount: "",
          uploaded_images: [],
          images: [],
          selectedFiles: [],
          edit_enable: false,
          edit_item_id: "",
          selectedMulti: [],
          episode_no: "",
          season: "",
          videoUrl:"",
          url:""
        });

        this.fetchEpisodes(this.state.seriesId);
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (file, index) => {
    // console.log("e", file);

    let dataSend = { buckate: false };

    let result = await HttpClient.newFileUpload(
      "image-upload/movie",
      file,
      dataSend
    );
    console.log("result", result);
    if (result && result.status) {
      this.state.uploaded_images.push({ preview: result.url });
      if (this.state.images.length - 1 == index) {
        setTimeout(() => {
          this.setState({ loading: false });
          console.log("this.state.uploaded_images", this.state.uploaded_images);
        }, 200);
      }
      this.setState({});
    }
  };

  handleAcceptedFiles = (files) => {
    let data = [];
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
      // image:files,
    };
    let file2 = {
      image: files,
    };

    let file3 = files;
    console.log("data", file);
    this.state.selectedFiles.push(file);
    this.state.images.push(files[0]);
    this.imageUpload(files[0]);
    // console.log("data", this.state.images);
    this.setState({});

    // console.log('files',files)
    // files.map(file =>
    //   Object.assign(file, {
    //     preview: URL.createObjectURL(file),
    //     formattedSize: this.formatBytes(file.size)
    //   })
    // );

    // this.setState({ selectedFiles: files });
  };

  async remove_image(item, index) {
    this.state.selectedFiles.pop(index);
    this.state.images.pop(index);
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    // this.fetchData();
  };

  uploadVideo = async (video) => {
    this.setState({ episode_trailer: true });

    let dataSend = { buckate: false };
    // let result = await HttpClient.videoUpload(
    //   "video-upload/video",
    //   video,
    //   dataSend
    // );
    let result = await HttpClient.uploadFileRequest("video-upload/video",video, (data, data1, data2)=>{
      console.log('data, data1, data2', data, data1, data2);
      this.setState({progressBarParsent:  data});
    });
    if (result && result.status) {
      this.setState({
        videoUrl: result.playlists,
        url: result.data.url,
        episode_trailer: false,
      });
    }
    console.log("result", result);
  };

  handleChange = async (event) => {
    let filterSub = this.state.subCategoryAll.filter(
      (item) => item.category_id == event.target.value
    );
    this.setState({ cat_id: event.target.value, subCategory: filterSub });
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Title",
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Image",
          field: "img",
          sort: "asc",
          width: 270,
        },
        {
          label: "Season",
          field: "season",
          sort: "asc",
          width: 270,
        },
        {
          label: "Episode",
          field: "episode_no",
          sort: "asc",
          width: 270,
        },
        {
          label: "Date",
          field: "date",
          sort: "asc",
          width: 270,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              {/* <BaseInfo /> */}
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Season
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={(event) => {
                            this.setState({
                              season: event.target.value,
                            });
                          }}
                          value={this.state.season}
                        >
                          <option value="">Select Season</option>
                          <option value="1">Season 1</option>
                          <option value="2">Season 2</option>
                          <option value="3">Season 3</option>
                          <option value="4">Season 4</option>
                          <option value="5">Season 5</option>
                        </select>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Episode Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Description
                      </Label>
                      <Col md={10}>
                        <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder=""
                          onChange={(val) => {
                            this.setState({ desc: val.target.value });
                          }}
                          value={this.state.desc}
                        ></textarea>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Episode Number
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="number"
                          value={this.state.episode_no}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ episode_no: val.target.value });
                          }}
                          min={1}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Upload Video
                      </Label>
                      <Col md={6}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {
                            this.uploadVideo(val.target.files[0]);
                          }}
                        />
                      </Col>
                      <Col md={4}>
                        {this.state.episode_trailer ? (
                          <>
                           {/* <img
                             src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                             style={{ height: 150, width: 150 }}
                          /> */}
                          <ProgressBar completed={this.state.progressBarParsent} />
                          {
                            this.state.progressBarParsent == 100? 
                              "please wait untill transcoding is done"
                              : null
                          }
                          </>
                        ) : (
                          <>
                            {this.state.url != "" ? (
                              <video
                                width="100%"
                                controls
                                className="rounded-lg"
                              >
                                <source src={this.state.url} type="video/mp4" />
                              </video>
                            ) : null}
                          </>
                        )}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                      Thumbnail
                      </Label>
                      <Col md={10}>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {this.state.uploaded_images.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        style={{ height: 120, width: 100 }}
                                        data-dz-thumbnail=""
                                        height="100"
                                        className="avatar-sm rounded bg-light"
                                        // alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Button
                                        className="btn-icon"
                                        color="danger"
                                        style={{ marginRight: 25 }}
                                        onClick={(e) => this.remove_image(f, i)}
                                      >
                                        {" "}
                                        <span className="btn-icon-label">
                                          <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                        </span>{" "}
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                {/* <div>
                    <EcomPreview />
                  </div> */}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
