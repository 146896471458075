import React, { Component } from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class SalesAnalytics extends Component {
    state = {
        series: [42, 26, 15],
        options: {
            labels: ["Product A", "Product B", "Product C"],
            plotOptions: {
                pie: {
                    donut: {
                        size: '75%'
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
            },
            colors: ['#5664d2', '#1cbb8c', '#eeb902'],

        }
    }
    render() {
        return (
            <React.Fragment>
                <Col lg={4}>
                    <Card className='bg-danger card'>
                        <Link to={'/accept-booking'}>
                            <CardBody >
                                <div>
                                    <div className="text-center">
                                        <p className="mb-2 text-white">Accepted Booking</p>
                                        <h4 className='text-white'>{this.props.accept}</h4>
                                    </div>
                                </div>
                            </CardBody>
                        </Link>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default SalesAnalytics;