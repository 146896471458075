import React, { Component } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Button,
    UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
// import Firebase from "./firebase1";
import SweetAlert from "react-bootstrap-sweetalert";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import { CSVLink } from "react-csv";
import HttpClient from "./../../utils/HttpClient";
import { Link } from "react-router-dom";
// import Modal from "./chatModal";
// import ChatWithSeller from "./chatwithseller";
// import img from "../../assets/images/pro.jpeg";
class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Claim Request", link: "#" },
                { title: "Manage Claim Request", link: "#" },
            ],
            modal: false,
            ismodalopen: false,
            ismodalopen1: false,
            customchk: true,
            toggleSwitch: true,
            order_id: 0,
            userimage: "",
            username: "",
            chatlist: [],
            data: [],
            sellerdetails: {},


        };
        this.toggle = this.toggle.bind(this);
    }

    CallBack = (val) => {
        console.log("object", val);
        this.setState({
            ismodalopen: val,
        });
    };
    CallBack1 = (val) => {
        console.log("object", val);
        this.setState({
            ismodalopen1: val,
        });
    };

    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    async componentDidMount() {
        this.fetchData();
    };

    claimInitiate = async (id) => {

        console.log("userid", id);
        let result = await HttpClient.requestData("payforservice/" + id, "PUT");
        console.log("result1", result);
        if (result && result.status) {
            this.setState({
                alert: true,
                message: "Claim Initiated Successfully",
                type: "success",
            });
            setTimeout(() => {
                this.setState({
                    alert: false,
                    message: "",
                    type: "",
                });
            }, 3000);
            this.fetchData()
        }
        else {
            this.setState({
                alert: true,
                message: "Something Went wrong",
                type: "error",
            });
        }
    }


    fetchData = async () => {
        let result = await HttpClient.requestData("viewpayservice ", "GET");
        console.log("viewpayservice", result);
        if (result && result.status) {
            let data = [];
            let i = 1;
            // if (result.data.length > 0) {
            //     let arr = result.data.filter(it => it.admin_status == "refund_initiated");
            //     console.log("arr",arr);
            //     this.setState({ result: arr });
            // } else {
            //     this.setState({ result: [] });
            // }
            this.setState({ result: result.data });
            // console.log(this.state.pointarr);
            this.state.result.length && this.state.result.forEach((element, index) => {
                let rows = {
                    sl: i,
                    orderId: element.order_id,
                    // claimAmount: "$" + element.seller_commission,
                    claimAmount: "$" + element.seller_commission.toFixed(2),
                    claimProcess: (<button
                        title={element.paystatus ? "Claim Initiated" : "Claim Initiate"}

                        className={element.paystatus ? "btn btn-success mr-2" : "btn btn-danger mr-2"}
                        onClick={() => {

                            this.claimInitiate(element._id);
                        }}
                    >
                        {element.paystatus ? "Claim Initiated" : "Claim Initiate"}
                    </button>),

                };
                i++;
                data.push(rows);
            });
            this.setState({
                data: data,
            });
        } else {
            this.setState({
                data: [],
            });
        }
    };

    closeCallBack = (val, status, msg) => {
        console.log("ModalRes", val, status)
        if (status == "success") {
            console.log("jkjk");
            this.setState({
                modal: val,
                type: "success",
                status: true,
                title: "your mobile no is  verified",
            })
            //window.alert("your mobile no is verified");
        }
        else if (status == "error") {
            this.setState({
                modal: val,
                type: "error",
                status: true,
                title: msg
            })
            //window.alert(msg);
        }
        else {

            {
                this.setState({
                    modal: val,

                })
            }
        }
    }
    //   fetchData = async () => {
    //     const dbRef = Firebase.database().ref();
    //     dbRef.child("chats").on("value",(snapshot) => {
    //      console.log("ResponseChat",snapshot.val());
    //      let sl=1;
    //      let arr=[];
    //      snapshot.forEach((ele)=>
    //      {


    //       let dom={
    //         sl:sl,
    //         username:ele.val().username,
    //         useremail:ele.val().useremail,
    //         userprofileimage:
    //         <img
    //         // src={img}
    //         className="img-fluid rounded-circle"
    //       style={{height:"30px"}}
    //         />,
    //         reply:
    //         <Button
    //         className="info"
    //         outline
    //         onClick={()=>
    //         {
    //           this.setState({
    //             modal:true,
    //             id:ele.val().id,
    //             userimage:ele.val().userprofileimage,
    //             username:ele.val().username,
    //           })
    //         }}
    //         >
    //           Reply
    //         </Button>
    //       }
    //       arr.push(dom);
    //        this.setState({
    //        chatlist:arr
    //        });
    //        sl++;
    //      });
    //      console.log(this.state.chatlist);
    //     }

    //     );

    //   }








    render() {
        const data = {
            columns: [
                {
                    label: "Sl.",
                    field: "sl",
                    sort: "asc",
                    width: 150,
                },

                {
                    label: "Order Id",
                    field: "orderId",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Claim amount",
                    field: "claimAmount",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Claim Process",
                    field: "claimProcess",
                    sort: "asc",
                    width: 100,
                },
                // {
                //   label: "Chat With Seller",
                //   field: "cws",
                //   sort: "asc",
                //   width: 100,
                // },
            ],
            rows: this.state.data,
        };




        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {this.state.alert ? (
                            <UncontrolledAlert
                                color={this.state.type}
                                className="alert-dismissible fade show"
                                role="alert"
                            >
                                {this.state.type == "warning" ? (
                                    <i className="mdi mdi-alert-outline mr-2"></i>
                                ) : this.state.type == "success" ? (
                                    <i className="mdi mdi-check-all mr-2"></i>
                                ) : this.state.type == "danger" ? (
                                    <i className="mdi mdi-block-helper mr-2"></i>
                                ) : null}
                                {this.state.message}
                            </UncontrolledAlert>
                        ) : null}
                        <Breadcrumbs
                            title={this.state.breadcrumbItems[0].title}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col xs={12}>
                                {this.state.confirm_both ? (
                                    <SweetAlert
                                        title="Are you sure?"
                                        warning
                                        showCancel
                                        confirmBtnBsStyle="success"
                                        cancelBtnBsStyle="danger"
                                        onConfirm={() =>
                                            this.delete(
                                                this.state.delete_item,
                                                this.state.delete_index
                                            )
                                        }
                                        onCancel={() =>
                                            this.setState({
                                                confirm_both: false,
                                            })
                                        }
                                    >
                                        You won't be able to revert this!
                                    </SweetAlert>
                                ) : null}
                                {this.state.alert ? (
                                    <UncontrolledAlert
                                        color={this.state.type}
                                        className="alert-dismissible fade show"
                                        role="alert"
                                    >
                                        {this.state.type == "warning" ? (
                                            <i className="mdi mdi-alert-outline mr-2"></i>
                                        ) : this.state.type == "success" ? (
                                            <i className="mdi mdi-check-all mr-2"></i>
                                        ) : this.state.type == "danger" ? (
                                            <i className="mdi mdi-block-helper mr-2"></i>
                                        ) : null}
                                        {this.state.message}
                                    </UncontrolledAlert>
                                ) : null}
                            </Col>
                        </Row>
                        {/* <Row>
              <Col xl={3}>
                {this.state.data.length > 0 ?
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"ApprovedServiceByPersonnel.csv"}
                    className="btn btn-success">
                    Export Report in CSV
                  </CSVLink> : null}
              </Col>
            </Row>&nbsp; */}
                        <Row>
                            <Col xl={12}>
                                <div className="card p-3">
                                    <MDBDataTable responsive bordered data={data} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;