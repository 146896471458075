import React, { Component } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledAlert,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  CardColumns,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from "reactstrap";

import { Link } from "react-router-dom";
import classnames from "classnames";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// import images
import img1 from "../../assets/images/small/img-1.jpg";
import img2 from "../../assets/images/small/img-2.jpg";
import img3 from "../../assets/images/small/img-3.jpg";
import img4 from "../../assets/images/small/img-4.jpg";
import img5 from "../../assets/images/small/img-5.jpg";
import img6 from "../../assets/images/small/img-6.jpg";
import img7 from "../../assets/images/small/img-7.jpg";

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Profile", link: "#" },
        { title: "Edit Profile", link: "#" },
      ],
      activeTab: "1",
      plans: [],
      activePlan: [],
      userId: "",
      password: "",
      userDetails: {},
      alert: false,
      message: "",
      type: "",

      name_old: "",
      email_old: "",
      mobile_old: "",

      image: "",
      name: "",
      email: "",
      mobile: "",
      address: "",
      edit_enable: true,
      _id: "",
      token: "",
      new_pass: "",
      con_new_pass: "",
      oldpassword: "",
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount = () => {
    this.checkUser();
  };

  fetchUser = async () => {
    let sendData = { id: this.state.userId };
    console.log("sendData", sendData);
    let result = await HttpClient.requestData(
      "viewAll",
      "GET",
      sendData
    );
    console.log("user", result);
    if (result && result.status) {
      this.setState({
        userDetails: result.data,
        image: result.data[0].image,
        name: result.data[0].fullname,
        email: result.data[0].email,
        mobile: result.data[0].mobile,
        _id: result.data._id,

        name_old: result.data[0].fullname,
        email_old: result.data[0].email,
        mobile_old: result.data[0].mobile,
        address: result.data[0].address ? result.data[0].address : "",
      });
    } else {
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    console.log("profile", user);
    if (user != null && Object.keys(user).length > 0) {
      this.setState({ userId: user._id });
      this.setState({ password: user.password });
      this.setState({ token: user.token});
      console.log(this.state.userId);
      this.fetchUser();
    } else {
    }
  };

  formValid = async (item) => {
    let sendData = {
      owner_id: Number(this.state.userId),
      sub_id: Number(item.id),
    };
    let result = await HttpClient.requestData(
      "app-owner/subscription/buy-now",
      "POST",
      sendData
    );
    if (result && result.status > 0) {
      this.setState({
        alert: true,
        message: "Plan Subscribed Successfully",
        type: "success",
      });
      this.fetchActivePlans();
      this.fetchPlans();
    } else {
      this.setState({ alert: true, message: result.error, type: "danger" });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (e) => {
    console.log("e", e.target.files);
    let file = e.target.files;
    //   if(file.lenght>0)
    //   {
    let dataSend = { buckate: true, buckate_name: "appIcon" };
    let data = new FormData();
    data.append("image", file[0]);
    let result = await HttpClient.fileUplode(
      "uploadImage",
      "POST",
      data
    );
    // this.fetchUser ();
    console.log("result", result);
    if (result && result.status) {
      this.setState({ image: result.data, image_select: true });
    }

    //   }
  };

  submit = async () => {
    if (
      this.state.name != "" &&
      this.state.email != "" &&
      this.state.mobile != "" &&
      this.state.image != ""
    ) {
      let data = null;
      let result = null;
      // return false;
      data = {
        admin_type: "Admin",
        fullname: this.state.name,
        email: this.state.email,
        status: true,
        image: this.state.image,
        mobile: this.state.mobile,
        address: this.state.address,
        password: this.state.password,
        token: this.state.token,
        // _id: this.state._id,
        // id: this.state.userId,
      };
      console.log("data", data);
      result = await HttpClient.requestData("update/" + this.state.userId, "PUT", data);
      
      if (result && result.status) {
        reactLocalStorage.setObject("userData", result.data);
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Profile Updated Successfully"
            : "Profile Added Successfully",
          type: "success",
        });
        window.location.reload(false);
        this.fetchUser();
     
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
   
  };

  submitPass = async () => {
    if (this.state.new_pass != "" && this.state.con_new_pass != "") {
      if (this.state.new_pass == this.state.con_new_pass) {
        let data = null;
        let result = null;
        // return false;
        data = {
          old_password: this.state.oldpassword,
          new_password: this.state.new_pass,
          cnf_password: this.state.con_new_pass,
          // id: this.state._id,
        };
        console.log("data", data);
        result = await HttpClient.requestData(
          "myaccount/update-password/" + this.state.userId,
          "PUT",
          data
        );

        if (result && result.status) {
          reactLocalStorage.setObject("userData", result.data);
          this.setState({
            alert: true,
            message: this.state.edit_enable
              ? "Password Updated Successfully"
              : "Password Added Successfully",
            type: "success",
          });

          this.fetchUser();
        } else {
          this.setState({
            alert: true,
            message: result.message,              
            type: "danger",
          });
        }
      } else {
        this.setState({
          alert: true,
          message: "Password Not Matched.",
          type: "warning",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Enter Password And Confirm Password",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col lg={12}>
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col xs={4} className="mb-6">
                <Card>
                  <CardImg
                    top
                    className="img-fluid"
                    src={HttpClient.IMG_URL + this.state.image}
                    alt="Skote"
                    style={{
                      alignSelf: "center",
                      width: 200,
                      height: 200,
                      borderRadius: 100,
                      marginTop: 20,
                    }}
                  />
                  <CardBody>
                    <CardTitle className="mt-0">Profile Details</CardTitle>
                    <div>
                      <small class="text-muted">Name: </small>
                      <p>{this.state.name_old}</p>
                      <hr />
                      <small class="text-muted">Mobile Number: </small>
                      <p>{this.state.mobile_old}</p>
                      <hr /> <small class="text-muted">Email address: </small>
                      <p>{this.state.email_old}</p>
                      <hr />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={8} className="mb-6">
                <Card>
                  <CardBody>
                    <h4 className="card-title">Edit Profile</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          defaultValue="Artisanal kale"
                          id="example-text-input"
                          value={this.state.name}
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                          placeholder={"Enter name"}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Email
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          defaultValue="Artisanal kale"
                          id="example-text-input"
                          value={this.state.email}
                          // onChange={(val) => {
                          //   this.setState({ email: val.target.value });
                          // }}
                          // placeholder={"Enter email"}
                          readonly
                        />
                      </Col>
                    </FormGroup>{" "}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Mobile
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          defaultValue="Artisanal kale"
                          id="example-text-input"
                          value={this.state.mobile}
                          onChange={(val) => {
                            this.setState({ mobile: val.target.value });
                          }}
                          placeholder={"Enter mobile"}
                        />
                      </Col>
                    </FormGroup>{" "}
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Address
                      </Label>
                      <Col md={10}>
                        <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder="Enter your question here..."
                          onChange={(val) => {
                            this.setState({ address: val.target.value });
                          }}
                          value={this.state.address}
                        ></textarea>
                      </Col>
                    </FormGroup>{" "}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Profile Image
                      </Label>
                      <Col md={10}>
                        <div className="custom-file">
                          <CustomInput
                            type="file"
                            className="custom-file-input"
                            id="customFile"
                            onChange={(e) => {
                              this.imageUpload(e);
                            }}
                          />
                          <Label
                            className="custom-file-label"
                            htmlFor="customFile"
                          >
                            Choose file
                          </Label>
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Update"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Edit Password</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Old Password
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="password"
                          id="example-text-input"
                          value={this.state.oldpassword}
                          onChange={(val) => {
                            this.setState({ oldpassword: val.target.value });
                          }}
                          placeholder={"Old Password"}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Enter New Password
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="password"
                          id="example-text-input"
                          value={this.state.new_pass}
                          onChange={(val) => {
                            this.setState({ new_pass: val.target.value });
                          }}
                          placeholder={"Enter New Password"}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Confirm New Password
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="password"
                          id="example-text-input"
                          value={this.state.con_new_pass}
                          onChange={(val) => {
                            this.setState({ con_new_pass: val.target.value });
                          }}
                          placeholder={"Confirm New Password"}
                        />
                      </Col>
                    </FormGroup>{" "}
                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submitPass();
                          }}
                        >
                          {this.state.edit_enable
                            ? "Update Password"
                            : "Update Password"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Pricing;
