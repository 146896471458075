import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import StartApp from "../pages/ChooseDesign/startApp";
import ChooseDesign from "../pages/ChooseDesign/index";
import CreateApp from "../pages/CreateApp/index";
import WelcomeScreen from "../pages/ChooseDesign/welcomeScreen";
import Quizlist from "../pages/QuizList/index";
import MoreQuestion from "../pages/AddMoreQuestions/index";
import Hobby from "../pages/Hobby/index";
import Updatehobby from "../pages/Updatehobby/index";

import KnowYourCustomer from "../pages/QuizList/knowCustomer";

import Monitize from "../pages/Monitize/index";
import Communicate from "../pages/Communicate/index";
import Forums from "../pages/Forums/index";
import Showroom from "../pages/Showroom/index";
import Directory from "../pages/Directory/index";

//approval
import ApprovalReq from "../pages/ApprovalRequest";

// Subscrption
import Subscrption from "../pages/Subscription/index";

// Application
import App from "../pages/App/index";

import Category from "./../pages/Category/index";
import SubCategory from "./../pages/Category/subCategory";
// Product

//jd3 category
import Jdcategory from "./../pages/Jdcategory/index";
import Genre from "./../pages/Jdcategory/genre";

import Product from "./../pages/Product/index";
import ProductAdd from "./../pages/Product/create";

//Add banner

import Addbanner from "./../pages/Addbanner";
import HomeBanner from "./../pages/HomeBanner";
import AboutBanner from "./../pages/AboutBanner";
import BlogType from "./../pages/Addblog/blogType";

//Add banner

import AddSubscription from "./../pages/subscriptionplan";

// Events

import Events from "./../pages/Event";

// Advertisement

import Advertisement from "./../pages/Advertise";

// Message

import UserChat from "./../pages/Message/user";
import ApponwerChat from "./../pages/Message/appowner";

// Profile

import Profile from "./../pages/Profile/index";

// Timeline

import Timeline from "./../pages/Timeline";

import sharePanel from "./../pages/Timeline/share";
import requestPanel from "./../pages/Timeline/request";
import Faq from "./../pages/Support&Faq/faq";

import Banner from "./../pages/Baner/index";

import Test from "../pages/Test/index";

import Video from "./../pages/Video/index";
import Series from "./../pages/Series/index";
import Episodes from "./../pages/Series/episodes";

import Tv from "./../pages/Tv/index";
import Training from "./../pages/jd3Event/index";

import AllVideos from "./../pages/Series/allTypes";

import ManageTypes from "./../pages/Jdcategory/type";

import Users from "./../pages/User";


import Price from "../pages/Price/index";


import UserSubscription from "./../pages/User/subscription";
import Subscribtionhistory from "./../pages/Subscriptionhistory";
import OneSubHistory from "./../pages/OneSubHistory";
import Userlist from "./../pages/Userlist";
import Sellerlist from "./../pages/Sellerlist";
import Contact from "./../pages/Contact";

import SellerServices from "./../pages/Sellerlist/sellerServices";
import PromoCode from "./../pages/PromoCode";
import Testimonial from "./../pages/Testimonial";
import ServiceCategory from "./../pages/ServiceCategory";
import SubserviceCategory from "./../pages/SubserviceCategory";
import booking from "./../pages/booking/pendingbooking";
import ClaimRequest from "./../pages/ClaimRequest";
import acceptbooking from "./../pages/booking/acceptbooking";
import rejectbooking from "./../pages/booking/rejectbooking";
import ApproveSeller from "./../pages/ApproveSeller";
import Enquiry from "./../pages/Enquiry";
import OrderHistory from "./../pages/OrderHistory"
import Allservice from "./../pages/allservice"
import AboutUs from "./../pages/AboutUs/AboutUs";
import SafetyGuide from "./../pages/SafetyGuide/index";
import ContactUs from "./../pages/ContactUs/ContactUs";

import TermsCondition from "../pages/TermsCondition/TermsCondition";
import Privacy from "../pages/PrivacyPolicy/Privacy";
import CreateShop from "../pages/CreateShop/CreateShop";
import Review from "../pages/Review/Review";
import SocialMedia from "../pages/socialmedia";
///cha
import AddBlog from "../pages/Addblog/func";
import FAQ from "../pages/FAQ";
import Article from "../pages/Article";
import AllComplain from "../pages/AllComplaints";
import Associate from "../pages/OurAssociate";
import Legal from "../pages/legalnotice";
import Career from "../pages/career/career";
import Feedback from "../pages/Allfeedback";
import CategorySuggestion from "../pages/CategorySuggestion";
import Applicants from "../pages/Allapplicants";

import RegisterPersonel from "../pages/RegisterPersonel";

import RefundServiceRequest from "../pages/RefundServiceRequest"
import ChatList from "../pages/ChatList"
import ChatListforAdmin from "../pages/ChatList/foradmin"





// Forms
import FormElements from "../pages/Forms/FormElements";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
// import FormXeditable from "../pages/Forms/FormXeditable";

//Ui
// import UiAlert from "../pages/Ui/UiAlert";
// import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
// import UiCarousel from "../pages/Ui/UiCarousel";
// import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import CreateMeeting from "../pages/CreateMeet";
// import UiGrid from "../pages/Ui/UiGrid";
// import UiImages from "../pages/Ui/UiImages";
// import UiLightbox from "../pages/Ui/UiLightbox";
// import UiModal from "../pages/Ui/UiModal";
// import UiProgressbar from "../pages/Ui/UiProgressbar";
// import UiSweetAlert from "../pages/Ui/UiSweetAlert";
// import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
// import UiTypography from "../pages/Ui/UiTypography";
// import UiVideo from "../pages/Ui/UiVideo";
// import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
// import UiRating from "../pages/Ui/UiRating";
// import UiRangeSlider from "../pages/Ui/UiRangeSlider";
// import UiNotifications from "../pages/Ui/ui-notifications";
// import UIRoundSlider from "../pages/Ui/UIRoundSlider";

//Tables
// import BasicTables from "../pages/Tables/BasicTables";
// import DatatableTables from "../pages/Tables/DatatableTables";
// import ResponsiveTables from "../pages/Tables/ResponsiveTables";
// import EditableTables from "../pages/Tables/EditableTables";

// Inner Authentication
// import Login1 from "../pages/AuthenticationInner/Login";
// import Register1 from "../pages/AuthenticationInner/Register";
// import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";

//Kanban Board
// import KanbanBoard from "../pages/KanbanBoard/index";

const authProtectedRoutes = [
  //Kanban Board
  // { path: "/apps-kanban-board", component : KanbanBoard },

  // Tables
  // { path: "/tables-basic", component: BasicTables },
  // { path: "/tables-datatable", component: DatatableTables },
  // { path: "/tables-responsive", component: ResponsiveTables },
  // { path: "/tables-editable", component: EditableTables },

  // Ui
  // { path: "/ui-alerts", component: UiAlert },
  // { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/contact", component: Contact },

  // { path: "/ui-carousel", component: UiCarousel },
  // { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  // { path: "/ui-grid", component: UiGrid },
  // { path: "/ui-images", component: UiImages },
  // { path: "/ui-lightbox", component: UiLightbox },
  // { path: "/ui-modals", component: UiModal },
  // { path: "/ui-progressbars", component: UiProgressbar },
  // { path: "/ui-sweet-alert", component: UiSweetAlert },
  // { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  // { path: "/ui-typography", component: UiTypography },
  // { path: "/ui-video", component: UiVideo },
  // { path: "/ui-session-timeout", component: UiSessionTimeout },
  // { path: "/ui-rating", component: UiRating },
  // { path: "/ui-rangeslider", component: UiRangeSlider },
  // { path: "/ui-notifications", component: UiNotifications },
  // { path : "/ui-roundslider", component : UIRoundSlider },

  // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  // { path: "/form-xeditable", component: FormXeditable },

  //Utility
  // { path: "/pages-starter", component: StarterPage },
  // { path: "/pages-timeline", component: Timeline },
  // { path: "/pages-faqs", component: FAQs },
  // { path: "/pages-pricing", component: Pricing },

  //Icons
  // { path: "/icons-remix", component: RemixIcons },
  // { path: "/icons-materialdesign", component: MaterialDesign },
  // { path: "/icons-dripicons", component: DripiIcons },
  // { path: "/icons-fontawesome", component: FontAwesome },

  // Maps
  // { path: "/maps-google", component: MapsGoogle },
  // { path: "/maps-vector", component: MapsVector },

  //Charts
  // { path: "/charts-apex", component: ChartApex },
  // { path: "/charts-chartjs", component: ChartjsChart },
  // { path: "/charts-sparkline", component: SparklineChart },
  // { path: "/charts-knob", component: ChartsKnob },

  //Email
  // { path: "/email-inbox", component: EmailInbox },
  // { path: "/email-read", component: EmailRead },

  //Ecommerce
  // { path: "/ecommerce-products", component: Products },
  // { path: "/ecommerce-product-detail", component: ProductDetail },
  // { path: "/ecommerce-orders", component: Orders },
  // { path: "/ecommerce-customers", component: Customers },
  // { path: "/ecommerce-cart", component: Cart },
  // { path: "/ecommerce-checkout", component: CheckOut },
  // { path: "/ecommerce-shops", component: Shops },
  // { path: "/ecommerce-add-product", component: AddProduct },

  //chat
  // { path: "/apps-chat", component: Chat },

  //calendar
  // { path: "/calendar", component: Calendar },

  { path: "/dashboard", component: Dashboard },
  { path: "/proposed-showroom", component: Showroom },
  { path: "/published-showroom", component: Showroom },
  { path: "/startApp", component: StartApp },
  { path: "/choose-design", component: ChooseDesign },
  { path: "/create-app", component: CreateApp },
  { path: "/welcome-screen", component: WelcomeScreen },
  { path: "/communicate", component: Communicate },
  { path: "/monitize", component: Monitize },
  { path: "/know-your-customer", component: KnowYourCustomer },
  { path: "/quizlist", component: Quizlist },
  { path: "/hobbies", component: Hobby },
  { path: "/updatehobby:id", component: Updatehobby },
  { path: "/forum", component: Forums },

  { path: "/directory", component: Directory },
  // subs
  { path: "/subscrption", component: Subscrption },
  // app
  { path: "/application", component: App },

  // product
  { path: "/category", component: Category },
  { path: "/sub-category", component: SubCategory },
  { path: "/jd3category", component: Jdcategory },
  { path: "/genre", component: Genre },
  { path: "/product", component: Product },
  { path: "/product-add", component: ProductAdd },
  { path: "/add-banner", component: Addbanner },
  { path: "/add-banner", component: Addbanner },

  //add subscription plan
  { path: "/subscription-plan", component: AddSubscription },
  { path: "/home-banner", component: HomeBanner },
  { path: "/about-banner", component: AboutBanner },
  { path: "/blog-type", component: BlogType },
  { path: "/seller-services/:id", component: SellerServices },
  { path: "/subscription-history", component: Subscribtionhistory },
  { path: "/onesubscription-history", component: OneSubHistory },
  { path: "/userlist", component: Userlist },
  { path: "/sellerlist", component: Sellerlist },
  { path: "/promo-code", component: PromoCode },
  { path: "/testimonial", component: Testimonial },
  { path: "/service-category", component: ServiceCategory },
  { path: "/subservice-category", component: SubserviceCategory },
  { path: "/claim-request", component: ClaimRequest },
  { path: "/pending-booking", component: booking },
  { path: "/accept-booking", component: acceptbooking },
  { path: "/cancel-booking", component: rejectbooking },
  { path: "/approve-seller", component: ApproveSeller },
  { path: "/enquiry", component: Enquiry },
  { path: "/subservice-category", component: SubserviceCategory },
  { path: "/order-history", component: OrderHistory },
  { path: "/all-service", component: Allservice },
  { path: "/about-us", component: AboutUs },
  { path: "/contact-us", component: ContactUs },
  { path: "/terms-condition", component: TermsCondition },
  { path: "/privacy-policy", component: Privacy },
  { path: "/create-shop", component: CreateShop },
  { path: "/review", component: Review },
  { path: "/social-media", component: SocialMedia },
  { path: "/safety-guide", component: SafetyGuide },
  { path: "/add-blog", component: AddBlog },
  { path: "/add-faq", component: FAQ },
  { path: "/add-article", component: Article },
  { path: "/complain", component: AllComplain },
  { path: "/associate", component: Associate },
  { path: "/Legal-Notice", component: Legal },
  { path: "/add-career", component: Career },
  { path: "/feedback", component: Feedback },
  { path: "/category-suggestion", component: CategorySuggestion },
  { path: "/applicants", component: Applicants },
  {path:"/meeting",component:CreateMeeting},
  { path: "/approval-request", component: ApprovalReq },

  { path: "/register-personel", component: RegisterPersonel },
  { path: "/refund-service-request", component: RefundServiceRequest },

  { path: "/chat-list", component: ChatList },
  { path: "/chat-list1", component: ChatListforAdmin },




  // Events
  { path: "/events", component: Events },

  { path: "/test", component: Test },


  // MoreQuestion
  { path: "/morequestion:id", component: MoreQuestion },

  // Advertise
  { path: "/advertisement", component: Advertisement },




  // share panel

  { path: "/share-panel", component: sharePanel },
  { path: "/request-panel", component: requestPanel },
  // Timeline
  { path: "/timeline", component: Timeline },

  // messahe
  { path: "/user-chat", component: UserChat },
  { path: "/appowner-chat", component: ApponwerChat },

  // Profile
  { path: "/faq", component: Faq },
  { path: "/profile", component: Profile },

  // Banner

  { path: "/banner", component: Banner },


  { path: "/manageprice", component: Price },

  // Videos

  { path: "/movies", component: Video },

  { path: "/series", component: Series },
  { path: "/episodes/:id", component: Episodes },

  { path: "/tv-shows", component: Tv },
  { path: "/training", component: Training },
  { path: "/videos", component: AllVideos },

  { path: "/types", component: ManageTypes },

  // Users

  { path: "/users", component: Users },
  { path: "/user-subscription", component: UserSubscription },


  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/auth-lock-screen", component: AuthLockScreen },

  // Authentication Inner
  // { path: "/auth-login", component: Login1 },
  // { path: "/auth-register", component: Register1 },
  // { path: "/auth-recoverpw", component: ForgetPwd1 },

  // { path: "/pages-maintenance", component: Maintenance },
  // { path: "/pages-comingsoon", component: CommingSoon },
  // { path: "/pages-404", component: Error404 },
  // { path: "/pages-500", component: Error500 },
];

export { authProtectedRoutes, publicRoutes };