import React, { Component } from "react";
import axios from "axios";

import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Button,
    UncontrolledAlert,
    InputGroup,
    InputGroupAddon,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
// import EcomPreview from "./E-ComPreview/index";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import index from "../ChooseDesign";
// import ElementMirror from "@fullcalendar/interaction/dnd/ElementMirror";
import { CSVLink } from "react-csv";

const header = [


    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },

    {
        label: "Cv", key: "new_cv"
    },
    {
        label: "Experience", key: "exp"
    },
    {
        label: "About Applicants", key: "about_yourself"
    },
    // {
    //     label: "Description", key: "desc"
    // },
    // {
    //     label: "Vacancy", key: "vac"
    // },
]


class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "All Applicants", link: "#" },
                { title: "Show All Applicants", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            // page states
            name: "",
            email: "",
            mobile: "",
            password: "",
            data: [],
            alert: false,
            message: "",
            type: "",
            result: [],

        };

    }

    componentDidMount = async () => {
        // this.checkUser();
        // this.fetchService();
        this.fetchData();

    };




    fetchData = async () => {
        let token = "";
        let user = reactLocalStorage.getObject("userData");
        if (user && user != null && Object.keys(user).length > 0) {
            token = user.token;
            console.log("token", token);
        }
        let result = await axios.get('https://api.myspiritualconnect.com/v1/admin/received-applications', {
            headers: {
                'authorization': token,
                "userType": "Admin",
            }
        }

        ).then((response) => {
            console.log("received-applications",response.data);
            if (response.data.data.length > 0) {
                let arr = [];
                let i = 1;
                response.data.data.forEach(element => {

                    let dom = {
                        sl: i,
                        name: element.firstName + " " + element.lastName,
                        email: element.email,
                        phone: element.contact,
                        cv: <a href={HttpClient.IMG_URL + element.cv}
                            target="_blank"
                        >
                            cv
                        </a>,
                        new_cv: HttpClient.IMG_URL + element.cv,
                        exp: element.experience ? element.experience : "",
                        about_yourself: element.about_yourself ? element.about_yourself : "",
                        // vac: element.vacancy ? element.vacancy : "",


                    }
                    i++;
                    console.log(dom);
                    arr.push(dom);

                });

                this.setState({
                    result: arr,
                })
                console.log("result", this.state.result);
            }

        })



    };





    render() {
        const data = {
            columns: [
                {
                    label: "Sl.",
                    field: "sl",
                    sort: "asc",
                    width: 150,
                },
                // {
                //     label: "Order Id",
                //     field: "oder_id",
                //     sort: "asc",
                //     width: 270,
                // },

                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Email",
                    field: "email",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Phone",
                    field: "phone",
                    sort: "asc",
                    width: 270,
                },

                {
                    label: "Cv",
                    field: "cv",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Experience",
                    field: "exp",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "About Applicants",
                    field: "about_yourself",
                    sort: "asc",
                    width: 270,
                },
                // {
                //     label: "Vacancy",
                //     field: "vac",
                //     sort: "asc",
                //     width: 270,
                // },

            ],
            rows: this.state.result,
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.state.breadcrumbItems[0].title}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col xl={12}>
                                {this.state.result.length > 0 ?
                                    <CSVLink
                                        data={this.state.result}
                                        headers={header}
                                        filename={"ApplicantsList.csv"}
                                        className="btn btn-success">
                                        Export Report in CSV
                                    </CSVLink> : null}
                                <div className="card p-3">



                                    <MDBDataTable responsive bordered data={data} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
