import React, { Component } from 'react';
import { Row, Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

//Import Vector Map Component
import Vector from "./Vectormap";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class RevenueByLocations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false
        }
    }

    render() {
        return (
            <React.Fragment>
                <Col lg={4}>
                    <Card className="bg-warning card">
                        <Link to={'/cancel-booking'}>
                            <CardBody >
                                <div>
                                    <div className="text-center">
                                        <p className="mb-2 text-white">Rejected Booking</p>
                                        <h4 className='text-white'>{this.props.reject}</h4>
                                    </div>
                                </div>
                            </CardBody>
                        </Link>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default RevenueByLocations;