import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { Editor } from "@tinymce/tinymce-react";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "About Banner", link: "#" },
        { title: "Add About Banner", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},

      image_select: false,
      img_url: "",
      title: "",
      allmovies: [],
      selectmovie: "",
      privacy: "",
      edit_id: "",
      heading: "",
      content: "",
      heading2: "",
      content2: "",
    };
  }

  componentDidMount = async () => {
    this.fetchData();
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("bannerAbout", "GET");
    if (result && result.status) {
      this.setState({
        content: result.data[0].content1,
        heading: result.data[0].heading1,
        content2: result.data[0].content2,
        heading2: result.data[0].heading2,
        edit_id: result.data[0]._id,
      });
    } else {
    }
  };

  submit = async () => {
    if (
      this.state.heading != "" &&
      this.state.content != "" &&
      this.state.heading2 != "" &&
      this.state.content2 != ""
    ) {
      let data = null;
      let result = null;

      data = {
        // info_id: this.state.edit_id,
        heading1: this.state.heading,
        content1: this.state.content,
        heading2: this.state.heading2,
        content2: this.state.content2,
      };
      console.log("data", data);
      result = await HttpClient.requestData("bannerAbout/" + this.state.edit_id, "PUT", data);

      console.log("result1", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message:
            this.state.edit_id == ""
              ? "About Banner Added Successfully"
              : "About Banner Updated Successfully",

          type: "success",
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message: result.message,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up the field.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Heading 1
                      </Label>
                      <Col md={10}>
                        <input
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder="Write here..."
                          onChange={(val) => {
                            this.setState({ heading: val.target.value });
                          }}
                          value={this.state.heading}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Content 1
                      </Label>
                      <Col md={10}>
                        {/* <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder="Write here..."
                          onChange={(val) => {
                            this.setState({ content: val.target.value });
                          }}
                          value={this.state.content}
                        ></textarea> */}
                        <Editor
                          apiKey="15r14a210jticff5jycrmnqijiset548njfyasvp7vzdk3hg"
                          value={this.state.content}
                          onEditorChange={(newValue, editor) => {
                            this.setState({ content: newValue });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Heading 2
                      </Label>
                      <Col md={10}>
                        <input
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder="Write here..."
                          onChange={(val) => {
                            this.setState({ heading2: val.target.value });
                          }}
                          value={this.state.heading2}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Content 2
                      </Label>
                      <Col md={10}>
                        {/* <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder="Write here..."
                          onChange={(val) => {
                            this.setState({ content2: val.target.value });
                          }}
                          value={this.state.content2}
                        ></textarea> */}
                        <Editor
                          apiKey="15r14a210jticff5jycrmnqijiset548njfyasvp7vzdk3hg"
                          value={this.state.content2}
                          onEditorChange={(newValue, editor) => {
                            this.setState({ content2: newValue });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_id != "" ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
