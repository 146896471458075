import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Subscription", link: "#" },
        { title: "Add & Manage Subscription", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      no_list: "",

      image_select: false,
      img_url: "",
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      type: "",
      desc: "",
      currencyType: [
        {
          name: "INR",
        },
        {
          name: "USD",
        },
        {
          name: "GBP",
        },
        {
          name: "EUR",
        },
        {
          name: "CAD",
        },
        {
          name: "cdd",
        },
      ],
      currency: "",
    };
    this.handleDefault = this.handleDefault.bind(this);
  }

  componentDidMount = async () => {
    this.checkUser();
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }

  fetchData = async () => {
    let result = await HttpClient.requestData("subscription", "GET");
    console.log("Subscription", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      // console.log(this.state.pointarr);
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,

          title: element.name,
          month: element.duration,
          price: element.price,
          currency: element.currency ? element.currency : "",
          type: element.type ? element.type == "month" ? "Monthly" : "Yearly" : null,
          seller_comission: element.seller_comission,
          no_list: element.no_of_listing,
          // points: Array.prototype.map.call(element.points, (s) => s).toString(),
          // status: (
          //   <button
          //     title="Delete"
          //     className={
          //       element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
          //     }
          //     onClick={() => {
          //       this.status(element, index);
          //     }}
          //   >
          //     {element.status ? "Active" : "Not Active"}
          //   </button>
          // ),
          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>{" "}
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    // let sendData = {
    //   id: item._id,
    // };
    // console.log(sendData);
    let result = await HttpClient.requestData(
      "subscription/" + item._id,
      "DELETE",

    );
    console.log(result);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      title: item.name,
      price: item.price,
      currency: item.currency,
      edit_item_id: item._id,
      seller_comission: item.seller_comission,
      duration: item.duration,
      id: item.id,
      type: item.type,
      desc: item.description,
      no_list: item.no_of_listing,
    });
  };
  // status = async (item, index) => {
  //   let sendData = {
  //     id: item._id,
  //     status: !item.status,
  //   };
  //   let result = await HttpClient.requestData(
  //     "admin/ecom_banner/status",
  //     "POST",
  //     sendData
  //   );
  //   if (result && result.status) {
  //     this.state.result.splice(index, 1);
  //     this.setState({
  //       alert: true,
  //       message: "Status Updated Succfully",
  //       type: "success",
  //     });
  //     setTimeout(() => {
  //       this.setState({
  //         alert: false,
  //         message: "",
  //         type: "",
  //       });
  //     }, 3000);
  //     this.fetchData();
  //   }
  // };
  submit = async () => {
    if (
      this.state.title != "" &&
      this.state.price != "" &&
      this.state.duration != "" &&
      this.state.seller_comission != "" &&
      this.state.type != "" &&
      this.state.no_list != ""
      // this.state.currency != ""
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
          no_of_listing: this.state.no_list,
          // currency: this.state.currency
        };
        console.log(data);
        result = await HttpClient.requestData("subscription", "POST", data);
      } else {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
          no_of_listing: this.state.no_list,
          // currency: this.state.currency
        };
        console.log(data);
        result = await HttpClient.requestData(
          "subscription/" + this.state.edit_item_id,
          "PUT",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Subscription Updated Successfully"
            : "Subscription Added Successfully",

          type: "success",

          image_select: false,
          img_url: "",
          title: "",
          currency: "",
          seller_comission: "",
          duration: "",
          price: "",
          desc: "",
          no_list: ""
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (e) => {
    let file = e.target.files[0].type;
    let fileType = file.split("/")[0];
    // fileType = fileType[0];
    console.log("e", file.split("/")[0]);

    if (fileType == "image" || fileType == "video") {
      // return false;
      this.setState({ fileType: fileType });
      let dataSend = { buckate: true, buckate_name: "appIcon" };

      let result = await HttpClient.newFileUpload(
        "image-upload/movie",
        e.target.files[0],
        dataSend
      );
      console.log("result", result);
      if (result && result.status) {
        this.setState({ img_url: result.url, image_select: true });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object?.keys(user)?.length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  handleCurrencyChange = (event) => {
    this.setState({ currency: event.target.value })
  }

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        // {
        //   label: "Image",
        //   field: "img",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "Title",
          field: "title",
          sort: "asc",
          width: 270,
        },
        {
          label: "Price",
          field: "price",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Currency",
        //   field: "currency",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "No. of List",
          field: "no_list",
          sort: "asc",
          width: 270,
        },
        {
          label: "Duration",
          field: "month",
          sort: "asc",
          width: 270,
        },
        {
          label: "Type",
          field: "type",
          sort: "asc",
          width: 270,
        },
        {
          label: "Seller Comission",
          field: "seller_comission",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Points",
        //   field: "points",
        //   sort: "asc",
        //   width: 270,
        // },

        // {
        //   label: "Status",
        //   field: "status",
        //   sort: "asc",
        //   width: 100,
        // },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Name
                      </Label>
                      <Col md={10}>
                        <input
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder="Enter Subscription Name"
                          onChange={(val) => {
                            this.setState({ title: val.target.value });
                          }}
                          value={this.state.title}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Price
                      </Label>
                      <Col md={10}>
                        <input
                          type="number"
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder="Enter Price"
                          onChange={(val) => {
                            if (val.target.value.match("^[0-9]*$") != null) {
                              this.setState({ price: val.target.value });
                            }
                          }}
                          value={this.state.price}
                        />
                      </Col>
                    </FormGroup>
                    {/* <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Currency
                      </Label>
                      <Col md={10}>
                      <select
                          className="form-control"
                          onChange={this.handleCurrencyChange}
                          value={this.state.currency}
                        >
                          <option value="">Select Currency</option>
                          {this.state.currencyType.map((item, index) => {
                            return (
                              <option value={item.name} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </FormGroup> */}

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Description
                      </Label>
                      <Col md={10}>
                        <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder="Enter Description"
                          onChange={(val) => {
                            this.setState({ desc: val.target.value });
                          }}
                          value={this.state.desc}
                        ></textarea>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Duration
                      </Label>
                      <Col md={10}>
                        <input
                          type="number"
                          id="question-input1"
                          className="form-control"
                          rows="1"
                          placeholder="Enter Duration"
                          onChange={(val) => {
                            if (val.target.value.match("^[0-9]*$") != null) {
                              this.setState({ duration: val.target.value });
                            }
                          }}
                          value={this.state.duration}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        No of List
                      </Label>
                      <Col md={10}>
                        <input
                          id="question-input1"
                          className="form-control"
                          rows="1"
                          placeholder="Enter Number of List"
                          onChange={(val) => {
                            this.setState({ no_list: val.target.value });
                          }}
                          value={this.state.no_list}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Seller Comission(%)
                      </Label>
                      <Col md={10}>
                        <input
                          type="number"
                          id="question-input1"
                          className="form-control"
                          rows="1"
                          placeholder="Enter Seller Commission"
                          onChange={(val) => {
                            if (val.target.value.match("^[0-9]*$") != null) {
                              this.setState({
                                seller_comission: val.target.value,
                              });
                            }
                          }}
                          value={this.state.seller_comission}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Type
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={(val) => {
                            this.setState({
                              type: val.target.value,
                            });
                          }}
                          value={this.state.type}
                        >
                          <option value="">Select Type</option>
                          <option value="year">Yearly</option>
                          <option value="month">Monthly</option>
                        </select>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
