import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import { CSVLink } from "react-csv";
import HttpClient from "./../../utils/HttpClient";
// import Details from "./details";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Enquiry", link: "#" },
        { title: "Manage Enquiry", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},

      image_select: false,
      img_url: "",
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      type: "",
      desc: "",
    };
    this.handleDefault = this.handleDefault.bind(this);
  }

  componentDidMount = async () => {
    // this.checkUser();
    this.fetchData();
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }
  status = async (item, id) => {
    let sendData = {};
    // alert(id);
    let result = await HttpClient.requestData(
      "user/set-status/" + id,
      "PUT",
      sendData
    );
    console.log("result", result);
    if (result && result.status) {
      this.fetchData();
    }
  };
  fetchData = async () => {
    let result = await HttpClient.requestData("contact-us", "GET");
    console.log("contact-us", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      // console.log(this.state.pointarr);
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,

          username: element.firstname + " " + element.lastname,
          email: element.user_email,
          address: element.reason,
          phone: element.message,
          image:
            <a href={HttpClient.IMG_URL + element.file} target="_blank"><img
              src={HttpClient.IMG_URL + element.file}

              alt="img"
              style={{ width: "100px", height: "100px", borderRadius: "6px" }}
            /></a>,
          approve: element.seller_status ? (
            <Button
              className="btn-icon"
              color="primary"
              onClick={(e) => this.disapprove(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-wink-outline mr-2"></i>

              </span>{" "}
              Approved
            </Button>
          ) : (
            <Button
              className="btn-icon"
              color="success"
              onClick={(e) => this.approve(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-wink-outline mr-2"></i>

              </span>{" "}
              Approve
            </Button>
          ),
          view: (
            <button
              title="Delete"
              className="btn btn-success mr-2"
              onClick={(e) => this.disapprove(index, element._id)}
            >
               <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-wink-outline mr-2"></i>

              </span>{" "}
              Disapproved
            </button>
          ),

        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  approve = async (item, id) => {
    let sendData = {};
    console.log(id);
    let result = await HttpClient.requestData(
      "approve-seller-requests/" + id,
      "PUT",
      sendData
    );
    console.log("result", result);
    if (result && result.status) {
      this.fetchData();
    }
  };

  disapprove = async (item, id) => {
    let sendData = {};
    // alert(id);
    let result = await HttpClient.requestData(
      "reject-seller-requests/" + id,
      "PUT",
      sendData
    );
    console.log("disapprove result", result);
    if (result && result.status) {
      this.fetchData();
    }
  };
  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    console.log(sendData);
    let result = await HttpClient.requestData(
      "admin/ecom_banner/delete",
      "POST",
      sendData
    );
    console.log(result);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Succfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  

  

  onCloseCallBack = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen: val,
    });
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",

          width: 150,
        },
        {
          label: "Name",
          field: "username",

          width: 270,
        },
        {
          label: "Email",
          field: "email",

          width: 270,
        },
        {
          label: "Reason",
          field: "phone",

          width: 270,
        },
        {
          label: "Message",
          field: "address",

          width: 270,
        },
        {
          label: "Image",
          field: "image",

          width: 270,
        },
      ],
      rows: this.state.data,
    };
    const header = [

      { label: "Sl.", key: "sl" },
      { label: "Name", key: "username" },
      { label: "Email", key: "email" },
      { label: "Reason", key: "phone" },
      { label: "Message", key: "address" },
    ]
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xl={3}>
                {this.state.data.length > 0 ?
                  <CSVLink
                    data={this.state.data.reverse()}
                    headers={header}
                    filename={"EnquiryList.csv"}
                    className="btn btn-success">
                    Export Report in CSV
                  </CSVLink> : null}
              </Col>
            </Row>&nbsp;



            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <MDBDataTable responsive bordered data={data} />
                </div>
                {/* {this.state.ismodalopen ? (
                  <Details
                    status={true}
                    onCloseCallBack={this.onCloseCallBack}
                    data={this.state.activeid}
                    id={this.state.idneed}
                  />
                ) : null} */}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;