import React, { Component } from "react";
import Details from "./details";
import SellerDetails from "./SellerDetails";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
// import EcomPreview from "./E-ComPreview/index";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import index from "../ChooseDesign";
// import ElementMirror from "@fullcalendar/interaction/dnd/ElementMirror";
import { CSVLink } from "react-csv";

const header = [

  { label: "Order Id", key: "oder_id" },
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Booking Date", key: "bookingdate"},
  { label: "Category Name", key: "category_name"},
  { label: "Service Name", key: "service_name"},
  { label: "Order Total", key: "total" },
  { label: "Admin Commission", key: "admin_commission" },
  // { label: "Mode of Payment", key: "payment_type" },
  // { label: "Address", key: "address"},
]


class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Oder History", link: "#" },
        { title: "Show Oder History", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      //   this page states
      category: [],
      categoryAll: [],
      subCategory: [],
      subCategoryAll: [],
      subServiceAll: [],

      cat_id: "",
      pruduct_type: "",
      sub_cat_id: "",
      sub_srvc_id: "",
      market_price: "",
      selling_price: "",

      // selectedFiles: [],
      images: "",
      uploaded_images: [],

      sell_price: "",
      purchase_price: "",
      discount: "",
      availableSize: [],
      availableColor: [],
      hashtag: "",
      personalisation: "",
      details: "",
      price: "",
      allService: [],
      shopService: [],
      allService_id: "",
      allShopService_id: "",
      to_time: "",
      from_time: "",
      available_duration: 0,
      slot_duration_id: "",

      optionGroupSize: [{ value: "Red", code: "" }],
      selectedMultiSize: [],

      showSelectColor: false,
      showSelect: false,
      colorRgb: "#4667CC",
      simple_color1: false,
      color: [{ code: "#4667CC", active: false }],
      description: "",
      selectcategory: "",
      selectshopserviceid: "",
      edit_id: "",
      oderId: [],
      ismodalopen: false,
      modalopen: false,
      sellerData:[],
      // key: Math.random(),
      weekDay: [
        { label: "Monday", value: "Monday" },
        { label: "Tuesday", value: "Tuesday" },
        { label: "Wednesday", value: "Wednesday" },
        { label: "Thursday", value: "Thursday" },
        { label: "Friday", value: "Friday" },
        { label: "Saturday", value: "Saturday" },
        { label: "Sunday", value: "Sunday" },
      ],
      slot_duration: [
        { label: "10", value: "10" },
        { label: "15", value: "15" },
        { label: "30", value: "30" },
      ],

      language: [{ label: "en", value: "en" }],
      lang: "",
      slot_time: "",
      selectedWeek: "",
      selectWeekName: "",
    };
    this.handleMulti = this.handleMulti.bind(this);
    this.onDragRgb = this.onDragRgb.bind(this);
  }


  onDragRgb(index, c1) {
    // alert(c1);
    this.state.color[index].code = c1;
    this.setState({ colorRgb: c1 });
  }

  handleMulti = (selectedMultiSize) => {
    this.setState({ selectedMultiSize });
    setTimeout(() => {
      console.log("selectedMulti", selectedMultiSize);
    });
  };

  componentDidMount = async () => {
    // this.checkUser();
    // this.fetchService();
    // window.scrollTop(0, 0);
    this.fetchData();
    // this.setState.Math.random();
    // this.fetchServicecategory();
    //   let sellerData = reactLocalStorage.getObject("userData");
    // console.log(sellerData);
    // this.fetchCat();
  };

  fetchService = async () => {
    let result = await HttpClient.requestData("service-category", "GET");
    if (result && result.status > 0) {
      this.setState({ allService: result.data });
    }
    console.log("allService", result);
    // let resultsub = await HttpClient.requestData("admin/sub-category", "GET");
    // console.log("resultsub", resultsub);
    // if (resultsub && resultsub.status > 0) {
    //   this.setState({ subCategoryAll: resultsub.data });
    // }
  };

  fetchServicecategory = async (id) => {
    console.log(id);
    let userData = reactLocalStorage.getObject("userData");
    console.log(userData._id);
    let result = await HttpClient.requestData(
      "shop/view-shopservice/" + userData._id + "/" + id,
      "GET"
    );
    if (result && result.status > 0) {
      this.setState({ shopService: result.data });
    }
    console.log("shopService", result);
  };

  fetchData = async (id) => {
    console.log("hii");
    console.log(this.state.selectshopserviceid);
    let userData = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData("service-order-history", "GET");
    console.log("getOrderHistory", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          oder_id: "#" + element.order_id,
          // name: element.user_data.length ? (element.user_data[0].firstName + " " + element.user_data[0].lastName) : "",
          // email: element.user_data.length ? element.user_data[0].email : "",
          total: "$" + element.price_cad?.toFixed(2),
          name: `${element.firstname} ${element.lastname}`,
          discount:(element.subtotal_cad-element.price_cad).toFixed(2),
          email:element.email,
          service_name: element.cart_data[0]?.service_name ? element.cart_data[0]?.service_name : "",
          category_name: element.cart_data[0]?.service_name ? element.cart_data[0]?.service_name : "",
          admin_commission: element.cart_data[0]?.admin_data?.length>0 ? ("$" + element.cart_data[0]?.admin_data[0]?.admin_commission?.toFixed(2))??"" : "0",
          bookingdate: element.booking_date ? moment(element.booking_date).format("DD-MM-YYYY") : "No Date Found",
          order_status: (
            <div>
              {element.cart_data[0]?.seller_confirmed == true ? (
                element.cart_data[0]?.completestatus == "complete" ? null :
                  (<button
                    title="Order Accepted"
                    // className="btn btn-primary"
                    className="btn btn-success mb-2"
                  >
                    Order Accepted
                  </button> ) 

              ) : element.cart_data[0]?.seller_reject == true ? (
                <button
                  title="Order Cancelled"
                  // className="btn btn-primary"
                  className="btn btn-danger mb-2"
                >
                  Order Cancelled
                </button>
              ) : element.cart_data[0]?.order_status == "cancelled" ? (
                <button
                  title="Cancelled by Buyer"
                  // className="btn btn-primary"
                  className="btn btn-danger mb-2"
                >
                  Cancelled by Buyer
                </button>
              ) : (
                <button
                  title="Order Pending"
                  // className="btn btn-primary"
                  className="btn btn-warning mb-2"
                >
                  Order Pending
                </button>
              )}&nbsp;

              {element.cart_data[0]?.completestatus == "complete" ? (
                <button
                  title="Order Completed"
                  // className="btn btn-primary"
                  className="btn btn-primary mb-2"
                >
                  Order Completed
                </button>
              ) : null}
              {element.cart_data[0]?.refund_request == "Yes" ? (
                <button
                  title="Refund Claim"
                  // className="btn btn-primary"
                  className="btn btn-primary mb-2"
                >
                  Refund Claim
                </button>
              ) : null}&nbsp;
            </div>
          ),
          details: (
            <button
              title="Details"
              className="btn btn-primary"
              onClick={() => {
                this.setState({ oderId: element });
                setTimeout(() => {
                  this.setState({
                    ismodalopen: true,
                  });
                }, 200);

              }}
            >
              View Details  
            </button>
          ),
          seller_details: (
            <button
              title=""
              className="btn btn-primary"
              onClick={() => {
                this.setState({ oderId: element });
                setTimeout(() => {
                  this.setState({
                    modalopen: true,
                  });
                }, 200);

              }}
            >
              View Details
            </button>
          ),
          // category_id: element.category_data ? element.category_data[0].name : "",
          //   img: element.image ? (
          //     <div>
          //       <img
          //         src={"http://128.199.25.86:5010/" + element.image}
          //         alt="images"
          //         className="rounded avatar-md card-img"
          //       />
          //     </div>
          //   ) : null,

          //   sell_price: element.price ? element.price : "",
          //   status: (
          //     <button
          //       title="Delete"
          //       className={
          //         element.adminStatus
          //           ? "btn btn-success mr-2"
          //           : "btn btn-danger mr-2"
          //       }
          //       onClick={() => {
          //         this.status(element, index);
          //       }}
          //     >
          //       {element.adminStatus ? "Active" : "Not Active"}
          //     </button>
          //   ),
          //   action: (
          //     <>
          //       <button
          //         title="Delete"
          //         className="btn btn-danger mr-2"
          //         onClick={() => {
          //           this.delete(element, index);
          //         }}
          //       >
          //         <i className="fa fa-trash" />
          //       </button>
          //       <button
          //         title="Edit"
          //         className="btn btn-primary"
          //         onClick={() => {
          //           this.edit(element, index);
          //         }}
          //       >
          //         <i className="fa fa-edit" />
          //       </button>
          //     </>
          //   ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  onCloseCallBack = async (val) => {

    this.setState({ ismodalopen: val });
  }
  onCloseBack = async (val) => {

    this.setState({ modalopen: val });
  }
  delete = async (item, index) => {
    let result = await HttpClient.requestData(
      "shop-service/timing/" + item._id,
      "DELETE"
    );
    console.log(result);
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Deleted Successfully",
        type: "success",
      });

      this.fetchData(this.state.selectshopserviceid);
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
    }
  };
  edit = async (item, index) => {
    console.log("edititems", item);
    this.setState({
      slot_time: item.slot_duration,
      selectWeekName: item.day_name,
      from_time: item.from,
      to_time: item.to,
      available_duration: item.available_duration,
      allShopService_id: item.shop_service_id,
      allService_id: item.category_id,
      edit_enable: true,
      edit_id: item._id,
      lang: item.language,
      // slot_time:
    });
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/product/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Succfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      console.log("chunk", this.state.selectshopserviceid);
      this.fetchData(this.state.selectshopserviceid);
    }
  };

  submit = async () => {
    let sellerData = reactLocalStorage.getObject("userData");
    console.log(sellerData);
    if (
      this.state.selectWeekName != "" &&
      // this.state.available_duration != "" &&
      this.state.from_time != "" &&
      this.state.to_time != "" &&
      this.state.allService_id != "" &&
      this.state.allShopService_id != "" &&
      this.state.slot_time != ""
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          slot_duration: parseInt(this.state.slot_time),
          day_name: this.state.selectWeekName,
          from: this.state.from_time,
          to: this.state.to_time,
          language: "en",
          //available_duration: this.state.available_duration,
          shop_service_id: this.state.allShopService_id,
          category_id: this.state.allService_id,
          seller_id: sellerData._id,
          // language: this.state.lang
        };
        console.log(data);
        result = await HttpClient.requestData(
          "shop-service/timing",
          "POST",
          data
        );
      } else {
        data = {
          slot_duration: parseInt(this.state.slot_time),
          day_name: this.state.selectWeekName,
          from: this.state.from_time,
          to: this.state.to_time,
          language: "en",
          //available_duration: this.state.available_duration,
          shop_service_id: this.state.allShopService_id,
          category_id: this.state.allService_id,
          seller_id: sellerData._id,
          // language: this.state.lang
        };
        console.log(data);
        result = await HttpClient.requestData(
          "shop-service/timing/" + this.state.edit_id,
          "PUT",
          data
        );
      }
      console.log("result1", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Slot Updated Successfully"
            : "Slot Added Successfully",

          type: "success",
          selectWeekName: "",
          from_time: "",
          to_time: "",
          available_duration: "",
          allShopService_id: "",
          allService_id: "",
          slot_time: "",
          lang: "",
        });

        this.fetchData(this.state.selectshopserviceid);
      } else {
        this.setState({
          alert: true,
          message: result.message,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };
  handleAcceptedFiles = (files) => {
    // console.log("object", event.target.files[0]);
    // setprofile(event.target.files[0]);
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
    };
    console.log("image", files[0]);
    let arr = [];
    arr.push(file);
    this.setState({
      images: files[0],
      uploaded_images: arr,
    });
    console.log("uploaded_images", this.state.uploaded_images);
  };

  async remove_image(item, index) {
    // this.state.selectedFiles.pop(index);
    // this.state.images.pop(index);
    this.setState({
      images: {},
    });
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
      console.log(user.id);
    } else {
    }
    // this.fetchData();

  };

  handleChange = async (event) => {
    this.setState({ cat_id: event.target.value });
  };
  handleServiceChange = async (event) => {
    let selectedservice = event.target.value;
    console.log(selectedservice);
    this.setState({ allService_id: selectedservice, allShopService_id: "" });
    console.log(this.state.allService_id);
    this.fetchServicecategory(selectedservice);
  };

  handleCat = async (event) => {
    this.state.selectcategory = event.target.value;
    this.setState({});
    this.servicefetch(event.target.value);
  };

  handleShopServiceChange = async (event) => {
    let selectedShopservice = event.target.value;
    this.setState({ allShopService_id: selectedShopservice });
  };

  handelservice = async (event) => {
    this.state.selectshopserviceid = event.target.value;
    this.setState({});
    this.fetchData(event.target.value);
  };
  handleWeekChange = async (event) => {
    this.state.selectWeekName = event.target.value;
    console.log("select Week", this.state.selectWeekName);
    this.setState({});
  };
  handleSlotChange = async (event) => {
    this.state.slot_time = event.target.value;
    this.setState({});
    console.log("slot Time", this.state.slot_time);
  };
  handleLangChange = async (event) => {
    this.state.lang = event.target.value;
    this.setState({});
    console.log("slot Time", this.state.lang);
  };

  servicefetch = async (id) => {
    console.log(id);
    let userData = reactLocalStorage.getObject("userData");
    console.log(userData._id);
    let result = await HttpClient.requestData(
      "shop/view-shopservice/" + userData._id + "/" + id,
      "GET"
    );
    if (result && result.status > 0) {
      this.setState({ shopService: result.data });
    }
    console.log("shopService", result);
  };

  getDuration = async (endtime) => {
    let start_time = moment(this.state.from_time, "HH:mm");
    console.log(start_time);
    let end_time = moment(endtime, "HH:mm");
    console.log(end_time);
    if (end_time.isAfter(start_time)) {
      let duration = moment.duration(end_time.diff(start_time));
      var hours = parseInt(duration.asHours());
      console.log(hours);
      this.state.available_duration = hours;
      this.setState({});
    } else {
      this.setState({
        alert: true,
        message: "Start time can't be greater than endtime.",
        type: "warning",
        available_duration: 0,
      });
    }
  };
  render() {
    const data = {

      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Order Id",
          field: "oder_id",
          sort: "asc",
          width: 270,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 270,
        },
        {
          label: "Booking Date",
          field: "bookingdate",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Category Name",
        //   field: "category_name",
        //   sort: "asc",
        //   width: 270,
        // },
        // {
        //   label: "Service Name",
        //   field: "service_name",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "Total",
          field: "total",
          sort: "asc",
          width: 270,
        },
        {
          label: "Discount",
          field: "discount",
          sort: "asc",
          width: 270,
        },
        {
          label: "Admin Commission",
          field: "admin_commission",
          sort: "asc",
          width: 270,
        },
        {
          label: "Order Status",
          field: "order_status",
          sort: "asc",
          width: 280,
        },
        {
          label: "View Details",
          field: "details",
          sort: "asc",
          width: 270,
        },
        {
          label: "Seller Details",
          field: "seller_details",
          sort: "asc",
          width: 270,
        },

        //
        // {
        //   label: "Status",
        //   field: "status",
        //   sort: "asc",
        //   width: 100,
        // },
        // {
        //   label: "Action",
        //   field: "action",
        //   sort: "asc",
        //   width: 100,
        // },
      ],

      rows: this.state.data,
    };
    

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xl={12}>
                {this.state.data.length > 0 ?
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"OrderHistoryList.csv"}
                    className="btn btn-success">
                    Export Report in CSV
                  </CSVLink> : null}
                <div className="card p-3">
                  {this.state.ismodalopen ? (
                    <Details
                      status={true}
                      onCloseCallBack={this.onCloseCallBack}
                      data={this.state.oderId}
                    />
                  ) : 
                  this.state.modalopen ? (
                    <SellerDetails
                      status={true}
                      onCloseBack={this.onCloseBack}
                      data={this.state.oderId}
                    />
                  ) : null}
                  <MDBDataTable responsive bordered data={data} disableRetreatAfterSorting={true}
                  // key={this.state.key}

                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
