import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import HttpClient from "./../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import EarningReports from "./EarningReports";
import Sources from "./Sources";
import RecentlyActivity from "./RecentlyActivity";
import RevenueByLocations from "./RevenueByLocations";
import UserCount from "./usercount";
import SellerCount from "./sellercount";
import AdminWallet from "./AdminWallet";
import TotaAcceptServiceOrder from "./TotalAcceptServiceOrder";
import TotalSellerCommission from "./TotalSellerCommission";
import TotalServiceCategory from "./TotalServiceCategory";

import ChatBox from "./ChatBox";
import LatestTransactions from "./LatestTransactions";
import TotalServiceOrderRevenew from "./TotalServiceOrderRevenew";
import TotalService from "./TotalService";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "My Spiritual", link: "#" },
        { title: "Dashboard", link: "#" },
      ],
      reports: [
        {
          icon: "ri-stack-line",
          title: "Number of Sales",
          value: "1452",
          rate: "2.4%",
          desc: "From previous period",
        },
        {
          icon: "ri-store-2-line",
          title: "Sales Revenue",
          value: "$ 38452",
          rate: "2.4%",
          desc: "From previous period",
        },
        {
          icon: "ri-briefcase-4-line",
          title: "Average Price",
          value: "$ 15.4",
          rate: "2.4%",
          desc: "From previous period",
        },
      ],
      total: 0,
      pending: 0,
      accept: 0,
      reject: 0,
      user: 0,
      seller: 0,
      adminWallet: 0,
      total_accept_service_order: 0,
      total_seller_commission: 0,
      total_service_catagory: 0,
      total_service_order_revenew: 0,
      total_service: 0,
    };
  }

  componentDidMount() {
    this.dasboardData();
  }

  dasboardData = async () => {
    console.log("hiiu");
    let result = await HttpClient.requestData("booking-and-user-stat", "GET");

    console.log("Booking_Accept", result);
    if (result && result.status) {
      this.setState({
        result: result,
        total: result.total_bookings,
        pending: result.pending_bookings,
        accept: result.accepted_bookings,
        reject: result.rejected_bookings,
        user: result.user_count,
        seller: result.seller_count,
        adminWallet: result.admin_walet.toFixed(2),
        total_accept_service_order: result.totalAcceptServiceOrder,
        total_seller_commission: result.totalSallerCommission?.toFixed(2),
        total_service_catagory: result.totalServiceCategory,
        total_service_order_revenew: result.totalServiceOrderRevenew?.toFixed(2),
        total_service: result.totalServices,
      });
      console.log("total", this.state.total_service);
    } else {
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Dashboard"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              {/* <Col xl={8}>
                                <Row>
                                    <MiniWidgets reports={this.state.reports} />
                                </Row>
                                
                                <RevenueAnalytics/>
                            </Col> */}
              {/* 
                            <Col xl={4}>

                                <SalesAnalytics name={"Sales"}/>

                                <EarningReports/>

                            </Col> */}
            </Row>

            <Row>
              {/* sources */}
              <Sources total={this.state.total ? this.state.total : 0} />

              {/* recent activity */}
              <RecentlyActivity
                pending={this.state.pending ? this.state.pending : 0}
              />

              {/* revenue by locations */}
              <RevenueByLocations
                reject={this.state.reject ? this.state.reject : 0}
              />
            </Row>

            <Row>
              <SalesAnalytics
                accept={this.state.accept ? this.state.accept : 0}
              />

              <UserCount user={this.state.user ? this.state.user : 0} />

              <SellerCount seller={this.state.seller ? this.state.seller : 0} />
            </Row>

            <Row>
              <AdminWallet
                adminwallet={
                  this.state.adminWallet ? this.state.adminWallet : 0
                }
              />

              <TotaAcceptServiceOrder
                total_accept_service_order={
                  this.state.total_accept_service_order
                    ? this.state.total_accept_service_order
                    : 0
                }
              />

              <TotalSellerCommission
                total_seller_commission={
                  this.state.total_seller_commission
                    ? this.state.total_seller_commission
                    : 0
                }
              />
            </Row>

            <Row>
              <TotalServiceCategory
                total_service_catagory={
                  this.state.total_service_catagory
                    ? this.state.total_service_catagory
                    : 0
                }
              />

              <TotalServiceOrderRevenew
                total_service_order_revenew={
                  this.state.total_service_order_revenew
                    ? this.state.total_service_order_revenew
                    : 0
                }
              />

              <TotalService
                total_service={
                  this.state.total_service ? this.state.total_service : 0
                }
              />
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
