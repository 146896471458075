import React, { Component } from "react";
import axios from "axios";

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
// import EcomPreview from "./E-ComPreview/index";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import index from "../ChooseDesign";
// import ElementMirror from "@fullcalendar/interaction/dnd/ElementMirror";
import { CSVLink } from "react-csv";
import { Editor } from "@tinymce/tinymce-react";

const header = [
  { label: "Order Id", key: "oder_id" },
  { label: "Name", key: "name" },
  { label: "Order Total", key: "total" },
  { label: "Mode of Payment", key: "payment_type" },

  {
    label: "Booking Date",
    key: "bookingdate",
  },
  {
    label: "Address",
    key: "address",
  },
];

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Post Job", link: "#" },
        { title: "Add and Manage job post", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      title: "",
      desc: "",
      exp: "",
      vac: "",
      alert: false,
      message: "",
      type: "",
      data: [],
      result: [],
      edit_enable: false,
      edit_item_id: "",
      urgent: false,
      jobExperiance: [
        {label: "0 - 1", value: "0 - 1"},
        {label: "1 - 2", value: "1 - 2"},
        {label: "3 - 5", value: "3 - 5"},
        {label: "5 - 7", value: "5 - 7"},
        {label: "7 - 10", value: "7 - 10"},
      ]
    };
  }

  componentDidMount = async () => {
    this.fetchData();
  };

  fetchData = async () => {
    let token = "";
    let user = reactLocalStorage.getObject("userData");
    if (user && user != null && Object.keys(user).length > 0) {
      token = user.token;
      //   console.log("token", token);
    }
    let result = await axios
      .get("https://api.myspiritualconnect.com/v1/admin/careers", {
        headers: {
          authorization: token,
          userType: "Admin",
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.data.length > 0) {
          let arr = [];
          let i = 1;
          response.data.data.forEach((element) => {
            var domm = new DOMParser().parseFromString(element.description, "text/html");
            var txt = domm.body.textContent;
            let dom = {
              sl: i,
              name: element.title,
              desc: txt,
              vac: element.vacancy,
              exp: element.experience,

              action: (
                <div className="d-flex">
                  <button
                    title="Delete"
                    className="btn btn-danger mr-2"
                    onClick={() => {
                      this.delete(element, index);
                    }}
                  >
                    <i className="fa fa-trash" />
                  </button>
                  {"  "}
                  <button
                    title="Edit"
                    className="btn btn-primary"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      this.edit(element, index);
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </div>
              ),
            };
            i++;
            // console.log(dom);
            arr.push(dom);
          });

          this.setState({
            result: arr,
          });
          //   console.log("result", this.state.result);
        }
      });
  };

  delete = async (item, index) => {
    let sendData = {};
    let result = await HttpClient.requestData(
      "careers/" + item._id,
      "DELETE",
      sendData
    );
    if (result && result.status) {
      // let index = this.state.result.indexOf(item);
      this.setState({
        alert: true,
        message: "Deleted Successfully",
        type: "success",
      });
      this.fetchData();
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
    }
  };

  edit = async (item, index) => {
    console.log("edititems", item);

    this.setState({
      edit_enable: true,
      edit_item_id: item._id,

      title: item.title,
      desc: item.description,

      vac: item.vacancy,
      exp: item.experience,
      urgent: item.urgency,
    });
  };

  submit = async () => {
    if (
      this.state.title != "" &&
      this.state.desc != "" &&
      this.state.exp != "" &&
      this.state.vac != "" 
      // this.state.urgent != ""
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        let data = {
          title: this.state.title,
          description: this.state.desc,
          experience: this.state.exp,
          vacancy: this.state.vac,
          urgency: this.state.urgent,
        };

        console.log(data);
        result = await HttpClient.requestData("careers", "POST", data);
      } else {
        let data = {
          title: this.state.title,
          description: this.state.desc,
          experience: this.state.exp,
          vacancy: this.state.vac,
          urgency: this.state.urgent,
        };

        console.log(data);
        result = await HttpClient.requestData(
          "careers/" + this.state.edit_item_id,
          "PUT",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Job post updated Successfully"
            : "Job posted Successfully",
          type: "success",
          title: "",
          desc: "",
          exp: "",
          vac: "",
          urgent: ""
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  handleAcceptedFiles = async (files) => {
    let data = new FormData();
    data.append("image", files[0]);

    let result = await HttpClient.fileUplode(
      "associate/image-upload",
      "POST",
      data
    );
    console.log("result", result);
    this.setState({
      images: result.data,
      uploaded_images: [
        {
          preview: result.data,
        },
      ],
    });
  };

  async remove_image(item, index) {
    // this.state.selectedFiles.pop(index);
    // this.state.images.pop(index);
    this.setState({
      images: {},
    });
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },

        {
          label: "Job Title",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Description",
          field: "desc",
          sort: "asc",
          width: 270,
        },

        {
          label: "Vacancy",
          field: "vac",
          sort: "asc",
          width: 270,
        },
        {
          label: "Experience",
          field: "exp",
          sort: "asc",
          width: 270,
        },

        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.result,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              {/* <BaseInfo /> */}
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Job Title
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.title}
                          id="example-text-input"
                          placeholder="Enter Company Name"
                          onChange={(val) => {
                            this.setState({ title: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Job Description
                      </Label>
                      <Col md={10}>
                        {/* <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder="Enter description here..."
                          onChange={(val) => {
                            this.setState({ desc: val.target.value });
                          }}
                          value={this.state.desc}
                        ></textarea> */}
                        <Editor
                          apiKey="15r14a210jticff5jycrmnqijiset548njfyasvp7vzdk3hg"
                          value={this.state.desc}
                          onEditorChange={(newValue, editor) => {
                            this.setState({ desc: newValue });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Job Experience
                      </Label>
                      <Col md={10}>
                      <select
                        className="form-control"
                        onChange={(val) => {
                          console.log(val.target.value);
                          this.setState({ exp: val.target.value });
                        }}
                        value={this.state.exp}
                      >
                        <option value="">Select Job Experience</option>
                        {this.state.jobExperiance.map((item, index) => {
                          return (
                            <option value={item.value} key={index}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                        {/* <Input
                          className="form-control"
                          type="number"
                          value={this.state.exp}
                          id="example-text-input"
                          placeholder="Enter Job Experience"
                          onChange={(val) => {
                            this.setState({ exp: val.target.value });
                          }}
                        /> */}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Job Vacancies
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="number"
                          value={this.state.vac}
                          id="example-text-input"
                          placeholder="Enter Job Vacancies"
                          onChange={(val) => {
                            this.setState({ vac: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <Label
                      htmlFor="example-text-input"
                      className="col-md-2 col-form-label"
                    >
                      Ugent
                    </Label>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Yes
                      </Label>
                      <Col md={10}>
                        <Input
                          id="image"
                          name="imageMethod"
                          type="radio"
                          //checked={this.state.urgent == "yes" ? true : false}
                          value={this.state.urgent}
                          onChange={(val) => {
                            console.log(val.target.value);

                            this.state.urgent = "yes";
                            this.setState({});

                            console.log(this.state.urgent);
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        No
                      </Label>
                      <Col md={10}>
                        <Input
                          id="image"
                          name="imageMethod"
                          type="radio"
                          value="no"
                          onChange={(val) => {
                            console.log(val.target.value);
                            if (val.target.value == "no") {
                              this.state.urgent = false;
                              this.setState({});
                            }
                            console.log(this.state.urgent);
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                {/* <div>
                    <EcomPreview />
                  </div> */}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
