import React, { Component } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Button,
    UncontrolledAlert,
    InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Social Media", link: "#" },
                { title: "Add Social Media Link", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            // page states
            name: "",
            email: "",
            mobile: "",
            password: "",
            data: [],
            alert: false,
            message: "",
            type: "",
            result: [],
            edit_enable: false,
            edit_item_id: "",
            image_select: false,
            img_url: "",
            userId: "",
            userDetails: {},

            image_select: false,
            img_url: "",
            fb: "",
            tw: "",
            youtube: "",
            linkedIn: "",
            edit_id: "",
        };

    }

    componentDidMount = async () => {
        this.fetchData();
    };


    fetchData = async () => {
        let result = await HttpClient.requestData("social-media-info", "GET");
        if (result && result.status) {
            this.setState({

                fb: result.data[0].facebook,
                tw: result.data[0].twitter,
                linkedIn: result.data[0].linkedin,
                youtube: result.data[0].youtube,
                edit_id: result.data[0]._id
            })
        }
        else {

        }
    }

    submit = async () => {


        if (
            this.state.fb != "" &&
            this.state.tw != "" &&
            this.state.youtube != "" &&
            this.state.linkedIn != ""

        ) {
            let data = null;
            let result = null;


            data = {
                info_id: this.state.edit_id,
                facebook: this.state.fb,
                twitter: this.state.tw,
                youtube: this.state.youtube,
                linkedin: this.state.linkedIn

            };
            console.log("data", data);
            result = await HttpClient.requestData(
                "social-media-info",
                "POST",
                data
            );

            console.log("result1", result);
            if (result && result.status) {
                this.setState({
                    alert: true,
                    message: this.state.edit_id == ""
                        ? "Social Link Added Successfully"
                        : "Social Link Updated Successfully",

                    type: "success",

                });

                this.fetchData();
            } else {
                this.setState({
                    alert: true,
                    message: result.message,
                    type: "danger",
                });
            }
        } else {
            this.setState({
                alert: true,
                message: "Please Fill Up the field.",
                type: "warning",
            });
        }
        setTimeout(() => {
            this.setState({
                alert: false,
                message: "",
                type: "",
            });
        }, 3000);
    };



    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.state.breadcrumbItems[0].title}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        {this.state.alert ? (
                                            <UncontrolledAlert
                                                color={this.state.type}
                                                className="alert-dismissible fade show"
                                                role="alert"
                                            >
                                                {this.state.type == "warning" ? (
                                                    <i className="mdi mdi-alert-outline mr-2"></i>
                                                ) : this.state.type == "success" ? (
                                                    <i className="mdi mdi-check-all mr-2"></i>
                                                ) : this.state.type == "danger" ? (
                                                    <i className="mdi mdi-block-helper mr-2"></i>
                                                ) : null}
                                                {this.state.message}
                                            </UncontrolledAlert>
                                        ) : null}

                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Add facebook link
                                            </Label>
                                            <Col md={10}>
                                                <Input
                                                    className="form-control"
                                                    type="url"
                                                    value={this.state.fb}
                                                    placeholder="Add Link"
                                                    pattern="https://.*"
                                                    size="30"
                                                    id="example-text-input"
                                                    onChange={(val) => {
                                                        this.setState({ fb: val.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Add Twitter link
                                            </Label>
                                            <Col md={10}>
                                                <Input
                                                    className="form-control"
                                                    type="url"
                                                    value={this.state.tw}
                                                    placeholder="Add Link"
                                                    pattern="https://.*"
                                                    size="30"
                                                    id="example-text-input"
                                                    onChange={(val) => {
                                                        this.setState({ tw: val.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Add Youtube link
                                            </Label>
                                            <Col md={10}>
                                                <Input
                                                    className="form-control"
                                                    type="url"
                                                    value={this.state.youtube}
                                                    placeholder="Add Link"
                                                    id="example-text-input"
                                                    pattern="https://.*"
                                                    size="30"
                                                    onChange={(val) => {
                                                        this.setState({ youtube: val.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Add Linked link
                                            </Label>
                                            <Col md={10}>
                                                <Input
                                                    className="form-control"
                                                    type="url"
                                                    value={this.state.linkedIn}
                                                    placeholder="Add Link"
                                                    id="example-text-input"
                                                    pattern="https://.*"
                                                    size="30"
                                                    onChange={(val) => {
                                                        this.setState({ linkedIn: val.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>


                                        <FormGroup className="mb-0">
                                            <div className="button-items pt-4">
                                                <Button
                                                    color="primary"
                                                    type="button"
                                                    className="waves-effect waves-light mr-1"
                                                    onClick={() => {
                                                        this.submit();
                                                    }}
                                                >
                                                    {this.state.edit_id != "" ? "Update" : "Submit"}{" "}
                                                    <i className="ri-arrow-right-line align-middle ml-1"></i>
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
