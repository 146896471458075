import React, { useEffect, useState } from "react";
//import ReactTags from "react-tag-autocomplete";

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
// import EcomPreview from "./E-ComPreview/index";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import index from "../ChooseDesign";
import { Editor } from "@tinymce/tinymce-react";

function Banner() {
  const breadcrumbItem = [
    { title: "Blog Type", link: "#" },
    { title: "Add & Manage Blog Type", link: "#" },
  ];
  const allblog = [
    {
      id: 1,
      name: "Horoscope News",
    },
    {
      id: 2,
      name: "Astrology News",
    },
    {
      id: 3,
      name: "Events",
    },
    {
      id: 4,
      name: "News",
    },
    {
      id: 5,
      name: "Blog News",
    },
    {
      id: 6,
      name: "Puja Accessories",
    },
    {
      id: 7,
      name: "Religion Spiritual",
    },
  ];
  const [alert, setalert] = useState(false);
  const [message, setmessage] = useState("");
  const [type, settype] = useState("");
  const [title, settitle] = useState("");
  const [details, setdetails] = useState("");
  const [image, setimage] = useState("");
  const [blogvalue, setblogvalue] = useState("");
  const [uploaded_images, setuploaded_images] = useState([]);
  const [result, setresult] = useState([]);
  const [data, setdata] = useState([]);
  const [edit_enable, setedit_enable] = useState(false);
  const [edit_item_id, setedit_item_id] = useState("");

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let userData = reactLocalStorage.getObject("userData");
    console.log("UserDataId", userData._id);
    let pro = await HttpClient.requestData("viewBlogData", "GET");
    console.log("blog type", pro);
    if (pro && pro.status) {
      let data = [];
      let i = 1;
      setresult(pro.data);
      pro.data.forEach((element, index) => {
        console.log("ele", element);
        var dom = new DOMParser().parseFromString(element.content, "text/html");
        var txt = dom.body.textContent;
        let image = element.image ? element.image : "";
        let rows = {
          sl: i,
          type: element.blog_type,
          title: element.title,
          details: txt,

          img: element.image ? (
            <div>
              <img
                src={HttpClient.IMG_URL + image}
                alt="images"
                className="rounded avatar-md card-img"
              />
            </div>
          ) : null,

          action: (
            <div className="d-flex">
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  Delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              {"  "}
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  Edit(element, index);
                  window.scrollTo(0, 0);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </div>
          ),
        };
        i++;
        console.log("hh", rows);
        data.push(rows);
      });

      setdata(data);
    } else {
      setdata([]);
    }
  };

  const Delete = async (item, index) => {
    let sendData = {};
    let result = await HttpClient.requestData(
      "deleteBlogData/" + item._id,
      "DELETE",
      sendData
    );
    fetchData();
    if (result && result.status) {
      // let index = this.state.result.indexOf(item);

      setalert(true);
      setmessage("Deleted Successfully");
      settype("success");

      // fetchData();

      setTimeout(() => {
        setalert(false);
        setmessage("");
        settype("");
      }, 3000);
    }
  };
  const Edit = async (item, index) => {
    console.log("edititems", item);
    setblogvalue(item.blog_type);
    setedit_enable(true);
    setedit_item_id(item._id);
  };

  const submit = async () => {
    if (blogvalue != "") {
      let data = null;
      let result = null;

      if (edit_enable == false) {
        data = {
          blog_type: blogvalue,
        };
        console.log(data);
        result = await HttpClient.requestData("addBlogData", "POST", data);
        fetchData();
      } else {
        data = {
          blog_type: blogvalue,
        };
        console.log(data);
        result = await HttpClient.requestData(
          "editBlogData/" + edit_item_id,
          "PUT",
          data
        );
        fetchData();
      }
      console.log("result", result);
      if (result && result.status) {
       
        settype("");
        setedit_enable(false);
        setblogvalue("");
        setalert(true);
        setmessage(
          edit_enable ? "Blog Type Updated Successfully" : "Blog Type Added Successfully"
        );
        settype("success");

        // fetchData();
      } else {
        setalert(true);
        setmessage(result.error[0].msg);
        settype("danger");
      }
    } else {
      setalert(true);
      setmessage("Please Fill Up All Details.");
      settype("warning");
    }
    setTimeout(() => {
      setalert(false);
      setmessage("");
      settype("");
    }, 3000);
    
  };


  const dataa = {
    columns: [
      {
        label: "Sl.",
        field: "sl",
        sort: "asc",
        width: 150,
      },
      {
        label: "Blog Type",
        field: "type",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: data,
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs
                        title={breadcrumbItems[0].title}
                        breadcrumbItems={breadcrumbItems}
                    /> */}

          <Row>
            {/* <BaseInfo /> */}
            <Col xs={12}>
              <Card>
                <CardBody>
                  {alert ? (
                    <UncontrolledAlert
                      color={type}
                      className="alert-dismissible fade show"
                      role="alert"
                    >
                      {type == "warning" ? (
                        <i className="mdi mdi-alert-outline mr-2"></i>
                      ) : type == "success" ? (
                        <i className="mdi mdi-check-all mr-2"></i>
                      ) : type == "danger" ? (
                        <i className="mdi mdi-block-helper mr-2"></i>
                      ) : null}
                      {message}
                    </UncontrolledAlert>
                  ) : null}

                  <FormGroup row>
                    <Label
                      htmlFor="example-text-input"
                      className="col-md-2 col-form-label"
                    >
                     Add Blog Type
                    </Label>
                    <Col md={10}>
                      <Input
                        id="question-input1"
                        className="form-control"
                        placeholder="Enter Blog Type"
                        onChange={(val) => {
                          setblogvalue(val.target.value);
                        }}
                        value={blogvalue}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <div className="button-items pt-4">
                      <Button
                        color="primary"
                        type="button"
                        className="waves-effect waves-light mr-1"
                        onClick={() => {
                          submit();
                        }}
                      >
                        {edit_enable ? "Update" : "Submit"}{" "}
                        <i className="ri-arrow-right-line align-middle ml-1"></i>
                      </Button>
                    </div>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              {/* <div>
                    <EcomPreview />
                  </div> */}
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <div className="card p-3">
                <MDBDataTable responsive bordered data={dataa} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Banner;
