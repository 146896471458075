// Import the functions you need from the SDKs you need
import firebase from 'firebase/app'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCaLPxEsafQP5bZGfuCmBJigZXPkcMI14w",
  authDomain: "myspiritual-2f910.firebaseapp.com",
  databaseURL: "https://myspiritual-2f910-default-rtdb.firebaseio.com",
  projectId: "myspiritual-2f910",
  storageBucket: "myspiritual-2f910.appspot.com",
  messagingSenderId: "928631521452",
  appId: "1:928631521452:web:c77b620fbfc46450311f09",
  measurementId: "G-ZEYPZ55LLL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

if (app.length === 0) {
  console.log("no firebas app")
}else{
  console.log("initialized")
}
// return false

const db = getDatabase();
console.log(db,"dddddddddd");
export default db;